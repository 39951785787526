.blog-page.first-time-locum {
  display: flex;
  flex-direction: column;
  width: 100%;

  .back-arrow-container {
    margin: 30px 0 0 30px;

    .back-text {
      margin-top: 4px;
      font-size: 24px;

      @media (max-width: 480px) {
        font-size: 18px;
      }
    }
  }
  
  .image-banner {
    width: 100%;
    margin: 0 0 30px 0;
  }

  .article {
    width: 820px;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: 30px auto;

    @media (max-width: 980px) {
      width: 690px;
    }

    @media (max-width: 720px) {
      width: 600px;
    }

    @media (max-width: 620px) {
      width: 470px;
    }

    @media (max-width: 480px) {
      width: 380px;
    }

    @media (max-width: 420px) {
      width: 320px;
    }

    @media (max-width: 360px) {
      width: 280px;
    }

    .title {
      font-size: 24px;
      text-align: center;
      font-family: sans-serif;

      @media (max-width: 480px) {
        font-size: 20px;
      }
    }

    .date {
      text-align: center;
      font-size: 16px;
      margin: 10px 0;
      font-family: sans-serif;

      @media (max-width: 480px) {
        font-size: 14px;
      }
    }

    .link {
      text-decoration: underline;
      color: #56A08D;

      &:hover {
        filter: brightness(60%);
      }
    }

    .content {
      margin: 0 20px;
    }

    .paragraph {
      margin: 10px 0;
      font-size: 16px;
      font-family: sans-serif;
      font-weight: 100;
      width: 100%;

      @media (max-width: 480px) {
        font-size: 16px;
      }

      &.bold {
        font-weight: 900;
      }

      &.italics {
        font-style: italic;
      }
    }

    .tutorial-list {
      margin: 15px 0;
      display: flex;
      flex-direction: column;

      .tutorial {
        display: flex;
        flex-direction: column;
        margin: 20px 0;

        .tutorial-title {
          font-size: 18px;
          font-weight: 900;
          margin: 10px 0;
        }

        .paragraph {
          text-align: left;
          font-size: 16px;
        }

        img {
          width: 100%;
          margin: 0 0 10px 0;

          @media (max-width: 480px) {
            margin: 10px 0;
          }
        }
      }
    }
  }
}