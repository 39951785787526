.navbar-container {
  position: fixed;
  width: 100%;
  z-index: 9000;
  max-width: 1920px;
  background-color: #CAE3DD;

  &~article {
    margin-top: 70px;

    @media (max-width: 980px) {
      margin-top: 60px;
    }

    @media (max-width: 480px) {
      margin-top: 60px;
    }
  }
}

#navbar-footer {
  display: flex;
  align-items: center;
  background-color: #FFFFFF;
  position: fixed;
  width: 100%;
  border-top: 1px solid #EBEBEB;
  height: 60px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  transition: -ms-transform 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955),
   -webkit-transform 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955),
   transform 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955), 
   visibility 0.2s !important;
  z-index: 999 !important;
  box-sizing: border-box;

  .mobile-footer-container {
    display: flex;
    justify-content: space-around;
    width: 100%;
    max-width: 560px;
    flex: 1 0 auto;
  }

  .search-icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    .notif-number {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 16px;
      width: 16px;
      border-radius: 5px;
      background-color: #F16B58; 
      font-size: 10px;
      color: #FFFFFF;
      font-weight: 900;
      margin-bottom: 25px;
      margin-left: 25px;
      position: absolute;

      &.many-requests {
        font-size: 8px;
      }
    }
  }

  .footer-text {
    margin-top: 2px;
    font-size: 12px;
    color: #B0B0B0;

    &.active-footer-text {
      filter: invert(53%) sepia(61%) saturate(266%) hue-rotate(114deg) brightness(95%) contrast(91%);
    }
  }

  .footer-icon {
    width: 20px;
    height: 20px;

    &.active-footer-icon {
      filter: invert(53%) sepia(61%) saturate(266%) hue-rotate(114deg) brightness(95%) contrast(91%);
    }

    &.post-icon {
      filter: brightness(0);
    }
  }

  .nav-option {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
}

.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #CAE3DD;
  padding: 10px 30px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;

  @media (max-width: 980px) {
    padding: 10px;
  }

  .locumly-logo {
    float: left;
    height: 50px;
    width: auto;

    @media (max-width: 980px) {
      height: 40px;
    }

    @media (max-width: 480px) {
      height: 35px;
    }
  }

  .navbar-list {
    display: flex;
    align-items: center;
    justify-content: center;

    .nav-option {
      display: flex;
      margin: auto 0px;
      padding: 5px 20px;
      justify-content: center;
      color: #313131;
      font: 100 16px avenir;
      cursor: pointer;
      background-color: transparent;
      position: relative;

      @media (max-width: 800px) {
        font-size: 14px;
        padding: 0 10px;
      }

      .notif-number {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 16px;
        width: 16px;
        border-radius: 5px;
        background-color: #F16B58; 
        font-size: 10px;
        color: #FFFFFF;
        font-weight: 900;
        margin-bottom: 25px;
        margin-left: 25px;
        position: absolute;
        bottom: -5px;
        right: 5px;

        @media (max-width: 800px) {
          bottom: -10px;
          right: -5px;
        }
  
        &.many-requests {
          font-size: 8px;
        }
      }

      &.active {
        color: #56a08d;
      }

      &:hover {
        cursor: pointer;
        background-color: rgba(231, 231, 227, 0.26);
        opacity: 0.6;
        filter: brightness(90%);
      }
    }
  }
}

.navbar.no-auth {
  .navbar-list {
    .login-button {
      background-color: #55A08D;
      color: #FFFFFF;
      border-radius: 20px;
      padding: 11px 30px;
      margin-left: 20px;
      font-size: 16px;

      @media (max-width: 700px) {
        padding: 9px 20px;
        font-size: 14px;
        margin-left: 0;
      }
    }
  }
}

.navbar.auth {
  .navbar-list {
    .nav-option-container {
      width: fit-content;
      @media (max-width: 480px) {
        display: none;
      }
    }
  }
}

.mobile-navbar.no-auth {
  display: flex;
  flex-direction: column;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;

  .navbar-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 11px 10px 10px;

    .locumly-logo {
      float: left;
      max-height: 39px;
      width: auto;
      margin-left: 10px;
    }

    .business-add-container {
      .business-add-button {
        padding: 10px 40px;
        box-sizing: border-box;
        border-radius: 20px;
        background-color: #55A08D;
        color: #FFFFFF;
        font-family: inter-bold;
        font-size: 16px;
      }
    }
  }

  .navbar-dropdown-menu {
    max-height: 200px;
    overflow: hidden;
    transition: max-height 0.4s ease-in;

    &.hidden {
      max-height: 0;
    }

    .navbar-list {
      text-align: center;

      .nav-option-container {
        display: flex;
        justify-content: center;
        align-items: center;

        .nav-option, .login-button {
          padding: 15px 0;
          color: #FFFFFF;
          background-color: #313131;
          width: 100%;

          &:hover {
            cursor: pointer;
            filter: brightness(160%);
          }
        }
      }
    }
  }
}

.in-find-page {
  box-shadow: none !important;
}

.hamburger-container {
  background-color: transparent;
  
  .hamburger {
    background-color: transparent;

    .line {
      width: 28px;
      border-radius: 4px;
      height: 4px;
      background-color: #808080;
      display: block;
      margin: 5px auto;
      transition: all 0.3s ease-in-out;
    }
  }

  &.open {
    .hamburger {
      .line:nth-child(1) {
        transform: translateY(9px) rotate(45deg);
        width: 32px;
      }

      .line:nth-child(2) {
        opacity: 0;
      }

      .line:nth-child(3) {
        transform: translateY(-9px)rotate(-45deg);
        width: 32px;
      }
    }
  }

  &:hover {
    cursor: pointer;
    filter: brightness(120%);
    opacity: 0.8;
  }
}

.mobile-navbar-search {
  display: flex;
  align-items: center;
  border: 1px solid lightgray;
  border-radius: 999px;
  // height: 50px;
  padding: 2px 0;
  max-height: 50px;
  height: 30px;
  width: fit-content;
  flex: 1;
  margin-left: 60px;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 5px 10px -3px, rgba(0, 0, 0, 0.05) 0px 3px 6px -2px;

  @media (max-width: 440px) {
    margin-left: 20px;
  }

  .search-input {
    display: flex;
    margin-top: 2px;
    border: none;
    outline-width: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 13px;
    justify-content: center;
    width: 100%;

    @media (max-width: 375px) {
      font-size: 11px;
    }
  }

  .search-icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 10px 0 15px;
    position: absolute;

    @media (max-width: 375px) {
      margin: 0 5px 0 10px;
    }
    
    .search-icon {
      filter: invert(53%) sepia(61%) saturate(266%) hue-rotate(114deg) brightness(95%) contrast(91%);
      width: 20px;

      @media (max-width: 375px) {
        width: 15px;
      }
    }
  }

  .filter-icon-container {
    margin: 0 10px 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;

    .filter-icon {
      width: 20px;
      border: 1px solid lightgrey;
      border-radius: 50%;
      padding: 4px;
      transform: scaleX(-1);

      @media (max-width: 375px) {
        width: 15px;
        padding: 6px;
      }
    }
  }
}

.nav-tranform {
  transform: translateY(100%);
  -webkit-transform: translateY(100%);
  -ms-transform: translateY(100%);
}

// .push-body {
//   padding-bottom: 60px !important;
// }