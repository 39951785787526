$cloudfront-cdn-url: 'https://dq55dzprwc7f6.cloudfront.net';

.shift-page-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #cae3dd;
  z-index: 1000;

  .back-arrow-container {
    background-color: transparent;
    margin: 0;
    padding: 17px;
    position: absolute;
  }

  .shift-header {
    width: 100%;
    position: fixed;
    max-height: 50px;
    align-items: center;
    z-index: 1001;
    background-color: #CAE3DD;
    top: 0;
    display: flex;

    .logo-container {
      display: flex;
      justify-content: center;
      width: 100%;
      height: 100%;

      .locumly-logo {
        max-height: 40px;
        padding: 5px 0;
        width: auto;
      }
    }
  }

  .shift-page {
    width: 100%;
    background-color: #FFFFFF;

    @media (min-width: 821px) {
      display: flex;
      flex-wrap: wrap;
      border-top-left-radius: 70px;
      border-top-right-radius: 70px;
      padding: 30px;
      box-sizing: border-box;
    }

    .request-shift-container {
      display: flex;
      align-items: center;
      justify-content: space-around;
      background-color: #FFFFFF;
      position: fixed;
      width: 100%;
      border-top: 1px solid #EBEBEB;
      height: 60px;
      bottom: 0px;
      left: 0px;
      right: 0px;
      box-sizing: border-box;
      transition: bottom 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955), visibility 0.2s !important;

      @media (max-width: 480px) {
        height: 125px;
        padding-bottom: 60px;
      }
  
      .shift-button {
        padding: 10px 20px;
        box-sizing: border-box;
        border-radius: 20px;
        background-color: #55A08D;
        color: #FFFFFF;

        &.message {
          background-color: #EFCB68;
        }
      }
  
      .add-to-cart-button {
        padding: 10px 20px;
        box-sizing: border-box;
        border-radius: 20px;
        background-color: #EFCB68;
        color: #FFFFFF;
      }
    }
  
    .info-card-back {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      text-align: center;
      height: 300px;
      position: relative;
      width: 100%;

      @media (min-width: 1059px) {
        margin-bottom: 20px;
      }

      @media (min-width: 821px) {
        height: auto;
        width: 25%;
        min-width: 250px;
        flex: 1;
      }
  
      @media (max-width: 480px) {
        margin-top: 50px;
      }
  
      .info-card-top {
        grid-area: 1/1/4/5;
        height: 50%;
        min-height: 50%;
        min-width: 100%;
        content: "";
        background-color: #CAE3DD;
      }
  
      .info-card-bot {
        position: absolute;
        height: 50%;
        min-height: 50%;
        min-width: 100%;
        content: "";
        background-color: #FFFFFF;
        bottom: 0;
        left: 0;
      }
  
      .info-card-container {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 10px auto 25px;
        flex-direction: column;
        z-index: 1;
        grid-area: 1/1/2/5;
        background-color: #FFFFFF;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.07);
        border-radius: 30px;
        width: 80%;
        padding: 25px;
        box-sizing: border-box;
        position: relative;

        @media (min-width: 821px) {
          background-color: #EFF7F5;
          width: 100%;
          margin: 0;
        }

        .shift-post-date {
          position: absolute;
          top: 10px;
          right: 15px;
          font-size: 12px;
      
          @media (max-width: 480px) {
            font-size: 8px;
          }
      
          &.new-shift {
            font-weight: 900;
          }
      
          &.old-shift {
            font-weight: 200;
          }
        }
        
        .info-title-container {
          margin-bottom: 10px;
          cursor: pointer;
  
          @media (max-width: 420px) {
            margin-bottom: 5px;
          }
    
          .info-title {
            color: #55A08D;
            font-size: 16px;
            font-weight: 900;
          }
        }
    
        .info-address-container {
          margin-bottom: 15px;
  
          @media (max-width: 420px) {
            margin-bottom: 10px;
          }
  
          @media (max-width: 360px) {
            margin-bottom: 5px;
          }
    
          .info-address {
            font-size: 14px;
          }
        }
    
        .info-poster-container {
          margin-bottom: 20px;
  
          @media (max-width: 420px) {
            margin-bottom: 15px;
          }
  
          @media (max-width: 360px) {
            margin-bottom: 10px;
          }
    
          .info-prefix {
            margin-right: 30px;
            font-weight: 900;
            font-size: 14px;
          }
      
          .info-poster {
            font-size: 14px;
          }
        }
    
        .info-datetime-container {
          text-align: center;

          @media (min-width: 821px) {
            display: flex;
            flex-direction: column;
            height: 100px;
            justify-content: space-around;
            background-color: #FFFFFF;
            border-radius: 20px;
            padding: 10px;
            box-sizing: border-box;
          }
    
          @media (max-width: 440px) {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
          }
    
          .info-datetime {
            font-size: 14px;
          }
        }
    
        .info-blocks-container {
          display: flex;
          justify-content: space-around;
          width: 100%;
          margin-top: 50px;

          @media(max-width: 1480px) {
            flex-wrap: wrap;
          }

          @media(max-width: 1059px) {
            flex-wrap: unset;
          }
    
          @media(max-width: 420px) {
            flex-wrap: wrap;
            flex-basis: 50%;
            margin-top: 20px;
          }
  
          @media (max-width: 360px) {
            margin-top: 10px;
          }
    
          .info-block {
            display: flex;
            flex-direction: column;
            align-items: center;
            flex-basis: 50%;

            @media(max-width: 1480px) {
              margin: 10px 0;
            }

            @media(max-width: 1059px) {
              margin: unset;
            }
    
            @media(max-width: 420px) {
              margin-top: 10px;
            }
    
            .block-title {
              font-size: 10px;
              color: #55A08D;
            }
    
            .block-text {
              font-size: 15px;
              font-weight: 900;
            }
          }
        }

        .info-req-container {
          display: flex;
          justify-content: space-around;
          width: 100%;
          margin-top: 20px;

          @media (max-width: 1285px) {
            flex-direction: column;
          }

          @media (max-width: 1059px) {
            flex-direction: row;
          }
        
          .shift-button {
            padding: 10px 20px;
            box-sizing: border-box;
            border-radius: 20px;
            background-color: #55A08D;
            color: #FFFFFF;

            &.message {
              background-color: #EFCB68;

              @media (max-width: 1285px) {
                margin-top: 10px;
              }
            }
          }
      
          .add-to-cart-button {
            padding: 10px 20px;
            box-sizing: border-box;
            border-radius: 20px;
            background-color: #EFCB68;
            color: #FFFFFF;
            margin-left: 20px;

            @media (max-width: 1285px) {
              margin-left: 0;
              margin-top: 10px;
            }
          }
        }
      }
    }
  
    .extra-info-container {
      width: 100%;
      height: 100%;
      background-color: #FFFFFF;

      @media (min-width: 821px) {
        display: flex;
        flex-wrap: wrap;
        width: 75%;
        flex: 1 1 auto;
        margin-top: 40px;
      }

      @media (min-width: 1059px) {
        display: flex;
        flex-wrap: wrap;
        width: 75%;
        flex: 0 1 auto;
      }

      // STYLES FOR WEB VIEW START //
      .shift-info-map {
        display: flex;
        flex-direction: column;
        width: 50%;
      }

      .shift-rec-faq {
        width: 50%;
        
      }
      // STYLES FOR WEB VIEW END //
    
      .map-position {
        display: flex;
        justify-content: center;
        margin: 30px 0;

        @media (min-width: 821px) {
          margin-bottom: 0;
        }
  
        .shift-map-container {
          height: 300px;
          width: 90%;

          @media (min-width: 821px) {
            height: 340px;
            border-radius: 10px;
          }
        }
  
        .shift-map-marker {
          background-color: transparent;
          padding: 4px;
          border-radius: 20px;
          background-image: url($cloudfront-cdn-url + '/map/default-icon.png');
          width: 30px;
          height: 30px;
          background-size: 100%;
        }
      }
  
      .info-content-container {
        display: flex;
        justify-content: center;

        @media (min-width: 821px) {

        }
  
        .accordian {
          display: flex;
          flex-direction: column;
          width: 80%;

          @media (min-width: 821px) {
            width: 90%;
          }
  
          .item {
            display: flex;
            background-color: #FFFFFF;
            border-radius: 15px;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.07);
            padding: 10px 20px;
            margin-top: 5px;
            margin-bottom: 5px;
            cursor: pointer;
  
            .question-container {
              width: 100%;
              display: flex;
              flex-direction: column;
              align-items: flex-start;
  
              .question-title {
                padding: 10px;
                box-sizing: border-box;
                font-size: 14px;
                font-weight: 900 !important;
  
                @media (max-width: 370px) {
                  font-size: 12px;
                }
              }
  
              .answer-content {
                display: block;
                text-align: left;
                padding: 10px;
                max-height: 30px;
                transition: all 0.25s ease;
                font-size: 12px;
  
                @media (max-width: 370px) {
                  font-size: 10px;
                }
  
                .info-answers {
                  line-height: 16px;
                  .bold {
                    font-weight: 600;
                  }
  
                  .info-list {
                    padding-left: 20px;
  
                    .requirement {
                      list-style: disc;
  
                      &.no-style {
                        list-style: none;
                      }
                    }
  
                    .information {
                      list-style: disc;
  
                      &.no-style {
                        list-style: none;
                      }
                    }
                  }
                }
              }
  
              .hide {
                opacity: 0.6;
              }
  
              .answer-content > span {
                display: inline-block;
              }
            }
  
            .expandable-bar {
              border-radius: 10px;
              width: 5px;
              margin-right: 20px;
            }
  
            .green-sidebar {
              background: linear-gradient(180deg, #55A08D 30.96%, #ABD2C8 79.93%, #CAE3DD 100%);
            }
  
            .yellow-sidebar {
              background: linear-gradient(180deg, #EFCB68 30.96%, rgba(246, 222, 157, 0.2) 100%);
            }
          }
        }
      }
  
      .shift-rec-position {
        display: flex;
        justify-content: center;
        margin: 20px 0;
        color: #FFFFFF;

        @media (min-width: 821px) {

        }
  
        .shift-rec-container {
          display: flex;
          flex-direction: column;
          border-radius: 5px;
          box-sizing: border-box;
          width: 80%;

          @media (min-width: 821px) {
            width: 90%;
          }
      
          .rec-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            font-size: 14px;
            text-align: left;
            box-sizing: border-box;
            cursor: pointer;
            background-color: #55A08D;
            padding: 20px;
            border-radius: 5px;
            transition: all 0.25s ease;
  
            @media (max-width: 360px) {
              padding: 15px;
            }
          }
  
          .remove-radius {
            border-bottom-left-radius: 0px !important;
            border-bottom-right-radius: 0px !important;
          }
    
          .rec-header-icon {
            transition: all 0.2s;
          }
    
          .open {
            transform: rotate(-180deg);
            transition: all 0.2s;
          }
    
          .rec-content {
            display: block;
            text-align: left;
            max-height: 0px;
            transition: all 0.25s ease;
            overflow: hidden;
            font-size: 12px;
            opacity: 1;
    
            @media (max-width: 370px) {
              font-size: 10px;
            }
  
            .rec-answer {
              display: flex;
              flex-direction: column;
  
              .rec-shift-card {
                display: flex;
                flex-direction: column;
                width: 100%;
                box-sizing: border-box;
                padding: 15px 20px;
  
                @media (max-width: 360px) {
                  padding: 10px 15px;
                }
  
                &:first-child {
                  background-color: #ABD2C8;;
                }
  
                &:nth-child(2) {
                  background-color: #CAE3DD;
                }
  
                &:nth-child(3) {
                  background-color: #F6DE9D;
                }
  
                &:last-child {
                  background-color: #EFCB68;
                  border-bottom-left-radius: 5px;
                  border-bottom-right-radius: 5px;
                }
  
                .rec-title-container {
                  display: grid;
                  grid-gap: 2px;
                  gap: 2px;
                  grid-template-columns: minmax(0, 1fr) max-content;
                  grid-template-rows: unset;
                  font-size: 14px;
                  font-weight: 900;
                  margin-bottom: 6px;
  
                  @media (max-width: 360px) {
                    font-size: 12px;
                  }
  
                  .rec-name {
                    grid-row: 1;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                  }
  
                  .rec-rate {
                    display: flex;
                    grid-row: 1;
                    align-items: flex-end;
                  }
                }
  
                .rec-detail-container {
                  display: grid;
                  gap: 2px;
                  grid-gap: 2px;
                  font-size: 14px;
                  grid-template-columns: minmax(0, 1fr) max-content;
                  grid-template-rows: unset;
  
                  .rec-date-container {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-around;
  
                    @media (max-width: 360px) {
                      font-size: 12px;
                    }
  
                    .rec-date {
                      font-weight: light;
                    }
  
                    .rec-time {
                      font-weight: 900;
                    }
                  }
  
                  .rec-button-container {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-around;
                    align-items: flex-end;
                    color: white;
  
                    .rec-button {
                      padding: 2px 0;
                      box-sizing: border-box;
  
                      .rec-button-arr {
                        height: 5px;
                        width: 10px;
                        transform: rotate(-90deg);
                      }
                      
                      .rec-view {
                        padding: 2px 6px 2px 8px;
                        font-size: 12px;
                        border-radius: 50px;
                        box-sizing: border-box;
                        background-color: #55A08D;
                        color: #FFFFFF;
                        display: flex;
                        align-items: center;
  
                        @media (max-width: 360px) {
                          font-size: 10px;
                        }
                      }
    
                      .rec-add {
                        padding: 2px 6px 2px 8px;
                        font-size: 12px;
                        border-radius: 50px;
                        box-sizing: border-box;
                        background-color: #EFCB68;
                        color: #FFFFFF;
                        display: flex;
                        align-items: center;
  
                        @media (max-width: 360px) {
                          font-size: 10px;
                        }
                      }
  
                      .last-shift-rec {
                        border: 1px solid #FFFFFF;
                      }
                    }
                  }
                }
              }
            }
          }
    
          .show-dropdown {
            padding: 10px 0;
          }
    
          .rec-content > span {
            display: inline-block;
          }
        }
      }
  
      .faq-container {
        display: flex;
        justify-content: center; 
        align-items: center;
        flex-direction: column;
        margin-top: 20px;
        margin-bottom: 130px;

        @media (min-width: 821px) {
          margin-top: 40px;
          margin-bottom: 0;
        }

        .faq-info {
          display: flex;
          justify-content: center; 
          align-items: center;
          flex-direction: column;
          width: 80%;

          @media (min-width: 821px) {
            width: 90%;
          }

          .faq-title {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
    
            .faq-faq {
              font-weight: 900;
            }
    
            .faq-sub {
              margin: 10px 0;
              font-size: 12px;
    
              @media (max-width: 390px) {
                font-size: 10px;
              }
            }
          }
    
          .faq-accordion {
            display: flex;
            flex-direction: column;

            @media (min-width: 821px) {
              width: 95%;
            }
    
            .faq-item {
              display: flex;
              flex-direction: column;
              padding: 1rem;
              margin-bottom: 10px;
              background-color: #EFF7F5;
              border-radius: 5px;
              box-sizing: border-box;

              @media (min-width: 821px) {
                border-radius: 15px;
                box-sizing: border-box;
                width: 100%;
              }
      
              .faq-header {
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;
                font-size: 14px;
                text-align: left;
                box-sizing: border-box;
                cursor: pointer;
                font-weight: 900;
              }
      
              .faq-header-icon {
                transition: all 0.2s;
              }
      
              .open {
                transform: rotate(-180deg);
                transition: all 0.2s;
              }
    
              .faq-content {
                display: block;
                text-align: left;
                max-height: 0px;
                transition: all 0.25s ease;
                overflow: hidden;
                font-size: 12px;
                opacity: 1;
    
                @media (max-width: 370px) {
                  font-size: 10px;
                }
    
                .faq-answer {
                  white-space: pre-wrap;
                }
              }
    
              .show-dropdown {
                padding: 10px 0;
              }
    
              .faq-content > span {
                display: inline-block;
              }
            }
          }
        }
      }
    }
  }

  .loading {
    color: #55A08D;
    font-weight: 900;
    text-align: center;
    font-size: 10px 15px;
    height: 50vh;
  }

  .something-wrong {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 50vh;

    .no-result-svg {
      width: 200px;
      margin-bottom: 20px;
      filter: invert(59%) sepia(55%) saturate(291%) hue-rotate(114deg) brightness(86%) contrast(91%);
    }

    .something-wrong-text {
      color: #55A08D;
      font-weight: 900;
      text-align: center;
      font-size: 20px;
    }
  }
}

.req-transform {
  bottom: -60px !important;
}