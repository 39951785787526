.shifts-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 360px;

  @media (max-width: 1800px) {
    margin-top: 300px;
  }

  @media (max-width: 1440px) {
    margin-top: 200px;
  }

  @media (max-width: 1260px) {
    margin-top: 140px;
  }

  @media (max-width: 1160px) {
    margin-top: 100px;
  }

  .title {
    font: 900 35px avenir;
    margin: 20px 0 10px 0;

    @media (max-width: 480px) {
      font: 900 28px avenir;
      margin: 20px 0 10px 0;
    }
  }

  .subtitle {
    color: #55A08D;
    font: 900 20px avenir;
    margin: 10px 0;

    @media (max-width: 480px) {
      font: 900 16px avenir;
    }
  }

  .green-button {
    font: 900 24px avenir;
    width: 320px;
    padding: 12px 30px;
    margin: 10px 0;

    @media (max-width: 480px) {
      width: 260px;
      font-size: 20px;
    }
  }

  .shift-list-container {
    margin: 50px 0;
    overflow-x: scroll;
    width: 100%;
  }

  .shift-list {
    padding: 40px 0;
    display: flex;

    &.no-shifts {
      align-items: center;
      justify-content: center;
    }

    .no-shifts-text {
      margin: auto;
    }

    .home-shift-card {
      margin: 0 20px;
    }
  }
}