.progress-bar {
  width: 300px;
  background: #F6F5F5;
  height: 5px;
  margin: 0 auto;
  display: flex;
  border-radius: 15px;

  @media (max-width: 480px) {
    width: 270px;
  }

  @media (max-width: 420px) {
    width: 240px;
  }

  .bar-progress {
    background: #55A08D;
    border-radius: 15px;
    height: 5px;

    &.one {
      width: 100px;

      @media (max-width: 480px) {
        width: 90px;
      }

      @media (max-width: 420px) {
        width: 80px;
      }
    }

    &.two {
      width: 200px;

      @media (max-width: 480px) {
        width: 180px;
      }

      @media (max-width: 420px) {
        width: 160px;
      }
    }

    &.three {
      width: 300px;

      @media (max-width: 480px) {
        width: 270px;
      }

      @media (max-width: 420px) {
        width: 240px;
      }
    }
  }
}