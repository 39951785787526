.about-page {
  .square-card {
    display: flex;
    flex-direction: column;
    background-color: #FFFFFF;
    color: #363638;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    box-shadow: 3px 4px 10px rgba(0, 0, 0, 0.06);
    border-radius: 20px;
    opacity: 0.9;
    
    .text-content {
      margin-top: 10px;
  
      .card-title {
        font-size: 24px;
  
        @media (max-width: 420px) {
          font-size: 20px;
        }
      }
  
      .descriptor {
        margin: 10px 0;
        font-size: 15px;
  
        @media (max-width: 1100px) {
          font-size: 14px;
        }
  
        @media (max-width: 880px) {
          font-size: 16px;
        }
  
        @media (max-width: 420px) {
          font-size: 14px;
        }
      }
    }
  }

  .square-card.small {
    height: 260px;
    max-width: 320px;
    flex: 1;
    padding: 20px;
  
    &.middle {
      margin: 0 80px;
  
      @media (max-width: 1440px) {
        margin: 0 40px;
      }
      
      @media (max-width: 880px) {
        margin: 40px 0;
      }
    }
  
    .image-container .image {
      height: 120px;
      float: right;
    }
  
    @media (max-width: 1250px) {
      height: 260px;
    }
  
    @media (max-width: 480px) {
      max-width: 280px;
    }
  
    @media (max-width: 420px) {
      max-width: 240px;
      height: 250px;
    }
  
    @media (max-width: 360px) {
      max-width: 220px;
    }
  }

  .squares.medium {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 20px;
    margin: 20px 0 0 0;
  
    &.two {
      margin: 0;
    }
  
    @media (max-width: 880px) {
      flex-direction: column;
    }
  }

  .title-banner {
    display: flex;
    flex-direction: column;
    background-color: #55A08D;
    color: #FFFFFF;
    padding: 200px 0;

    @media (max-width: 1920px) {
      padding: 150px 0;
    }

    @media (max-width: 1440px) {
      padding: 100px 0;
    }

    @media (max-width: 800px) {
      padding: 80px 0;
    }

    @media (max-width: 480px) {
      padding: 70px 0;
    }

    .title-container {
      margin: auto;

      @media (max-width: 480px) {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }

      .title {
        margin: 0 10px;
        font: 900 72px Inter, mundo-bold;

        @media (max-width: 1920px) {
          font: 900 64px Inter, mundo-bold;
        }

        @media (max-width: 1440px) {
          font: 900 54px Inter, mundo-bold;
        }

        @media (max-width: 1250px) {
          font: 900 48px Inter, mundo-bold;
        }

        @media (max-width: 800px) {
          font-size: 42px;
        }

        @media (max-width: 650px) {
          font-size: 32px;
          margin: 0 10px;
        }

        @media (max-width: 480px) {
          font-size: 28px;
        }

        @media (max-width: 420px) {
          font-size: 24px;
        }

        @media (max-width: 360px) {
          font-size: 22px;
        }
      }
    }
  }

  .about-container {
    width: 100%;
    background-color: #E3E1D6;
  }

  .about-content {
    padding: 100px 80px 150px 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 1440px;
    margin: 0 auto;

    @media (max-width: 620px) {
      padding: 60px 20px 100px 20px;
    }

    @media (max-width: 480px) {
      padding: 50px 10px 80px 10px;
    }

    .about-title-container {
      @media (max-width: 980px) {
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      .about-title {
        font-size: 54px; 
        margin-bottom: 10px;

        .title-emphasized-text {
          color: rgb(85, 160, 141);
          letter-spacing: 0.03em;
        }

        @media (max-width: 1920px) {
          font-size: 48px;
        }

        @media (max-width: 1440px) {
          font-size: 42px;
        }

        @media (max-width: 980px) {
          text-align: center;
          font-size: 36px;
        }

        @media (max-width: 480px) {
          font-size: 32px;
        }

        @media (max-width: 360px) {
          font-size: 28px;
        }
      }

      .about-subtitle {
        font-size: 26px;
        margin-bottom: 40px;

        @media (max-width: 1920px) {
          font-size: 24px;
        }

        @media (max-width: 1440px) {
          font-size: 22px;
        }

        @media (max-width: 980px) {
          text-align: center;
          margin-bottom: 0;
          font-size: 18px;
        }

        @media (max-width: 480px) {
          font-size: 16px;
        }

        @media (max-width: 360px) {
          font-size: 15px;
        }
      }
    }

    @media (max-width: 980px) {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .squares.medium {
      justify-content: center;

      .square-card {
        max-width: 480px;
        margin: 0 50px;
        height: 300px;

        @media (max-width: 1920px) {
          height: 280px; 
          margin: 0 40px;
        }

        @media (max-width: 1440px) {
          height: 260px;
          margin: 0 30px;
        }

        .text-content {
          .card-title {
            font-size: 32px;

            @media (max-width: 1920px) {
              font-size: 28px;
            }

            @media (max-width: 1440px) {
              font-size: 24px;
            }
          }

          .descriptor {
            font-size: 20px;
            max-width: 480px;

            @media (max-width: 1920px) {
              font-size: 18px;
            }

            @media (max-width: 1440px) {
              font-size: 14px;
            }
          }
        }
      }

      @media (max-width: 980px) {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: calc(100% - 40px);

        .square-card {
          max-width: 360px;
          margin: 20px 0;
        }
      }

      @media (max-width: 480px) {
        width: calc(100% - 20px);
      }
    }
  }
}