.store-location-map-container {
  margin-top: 15px;
  
  .store-location-map {
    height: 420px;
    width: 600px;
    border-radius: 10px;

    @media (max-width: 720px) {
      height: 420px;
      width: 540px;
    }

    @media (max-width: 600px) {
      height: 350px;
      width: 420px;
    }

    @media (max-width: 480px) {
      height: 320px;
      width: 380px;
    }

    @media (max-width: 420px) {
      height: 270px;
      width: 320px;
    }

    @media (max-width: 360px) {
      height: 220px;
      width: 280px;
    }
  }
}