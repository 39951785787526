.storecard-container {
  list-style: none;
  display: flex;
  flex-direction: column;
  background-color: #FFFFFF;
  width: 100%;
  box-shadow: inset 0px 1px 10px rgba(0, 0, 0, 0.12);
  border-radius: 15px;
  padding: 20px;
  box-sizing: border-box;
  cursor: pointer;

  @media (max-width: 480px) {
    margin: 8px 0;
    padding: 10px;
  }

  .store-container {
    box-sizing: border-box;
    padding: 4px;

    @media (max-width: 465px) {
      font-size: 12px;
    }

    @media (max-width: 365px) {
      font-size: 10px;
    }

    .title-container {
      display: grid;
      grid-template-columns: minmax(0, 1fr) max-content;
      grid-template-rows: unset;
      gap: 2px;

      .store-title {
        font-size: 18px;
        font-weight: 800;
        grid-row: 1;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;

        @media (max-width: 460px) {
          font-size: 16px;
        }

        @media (max-width: 360px) {
          font-size: 14px;
        }
      }
  
      .store-distance {
        grid-row: 1;
        color: #55A08D;
        font-size: 14px;
        display: flex;
        align-items: flex-end;

        @media (max-width: 460px) {
          font-size: 12px;
        }
      }
    }

    .store-details {
      display: grid;
      grid-template-columns: minmax(0, 1fr) max-content;
      grid-template-rows: unset;
      gap: 2px;
      font-size: 14px;

      @media (max-width: 460px) {
        font-size: 12px;
      }

      @media (max-width: 360px) {
        font-size: 10px;
      }

      .store-address {
        grid-row: 2 / 4;
        display: flex;
        color: #55A08D;
      }

      .store-reviews {
        display: none;
        flex-direction: column;
        justify-content: flex-end;
        grid-row: 4 / 6;
        font-size: 12px;

        @media (max-width: 720px) {
          visibility: hidden;
        }

        @media (max-width: 460px) {
          font-size: 10px;
        }

        .tick-icon {
          width: 11px;
          height: 11px;
          margin-right: 5px;

          @media (max-width: 460px) {
            width: 8px;
            height: 8px;
            margin-right: 3px;
          }
        }
      }

      .right-section {
        grid-column: -2 / -1;
        justify-self: end;
      }

      .store-button-container {
        grid-row: 4 / 6;
        display: flex;
        justify-content: space-around;
        align-items: flex-end;
        flex-direction: column;

        &.store-subscribe {
          align-items: flex-start;
        }

        .store-button {
          background-color: #56a08d;
          padding: 5px 15px;
          justify-self: center;
          box-sizing: border-box;
          border-radius: 15px;
          color: #FFFFFF;

          @media (max-width: 460px) {
            padding: 4px 12px;
            font-size: 12px;
          }

          @media (max-width: 360px) {
            // padding: 3px 10px;
            font-size: 10px;
          }
        }

        .notify-shifts {
          background-color: #EFCB68;
          margin-top: 5px;
        }

        .notify-shifts-small {
          background-color: #EFCB68;
          margin-top: 5px;
          display: none;
        }

        .view-shifts {
          margin-top: 6px;
        }

        @media (max-width: 460px) {
          .notify-shifts {
            margin-top: 6px;
          }

          .notify-shifts-small {
            margin-top: 6px;
          }
        }

        @media (max-width: 380px) {
          .notify-shifts {
            display: none;
          }

          .notify-shifts-small {
            display: flex;
          }
        }

        @media (max-width: 360px) {
          .notify-shifts-small {
            margin-top: 8px;
          }

          .view-shifts {
            margin-top: 8px;
          }
        }
      }
    }
  }

  .web-store-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    height: 100%;

    .title-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 10px 0;

      @media (max-width: 720px) {
        margin: 5px 0;
      }

      .store-title {
        font-size: 16px;
        text-align: center;

        @media (max-width: 720px) {
          font-size: 14px;
        }
      }

      .store-address {
        color: #55A08D;
        font-size: 12px;

        @media (max-width: 720px) {
          font-size: 10px;
        }
      }
    }

    .store-reviews {
      display: none;
      flex-direction: column;
      justify-content: flex-end;
      grid-row: 4 / 6;
      font-size: 12px;
      margin: 5px 0;
      
      @media (max-width: 720px) {
        visibility: hidden;
      }

      @media (max-width: 460px) {
        font-size: 10px;
      }

      .tick-icon {
        width: 11px;
        height: 11px;
        margin-right: 5px;

        @media (max-width: 460px) {
          width: 8px;
          height: 8px;
          margin-right: 3px;
        }
      }
    }

    .store-distance {
      width: 80%;
      color: #55A08D;
      margin: 5px 0;
      font-size: 14px;

      @media (max-width: 720px) {
        font-size: 12px;
        margin: 2px 0;
      }
    }

    .store-button-container {
      grid-row: 4 / 6;
      display: flex;
      justify-content: space-around;
      align-items: flex-end;
      flex-direction: column;
      width: 80%;
      margin: 15px 0;

      .store-button {
        background-color: #56a08d;
        padding: 5px 15px;
        justify-self: center;
        box-sizing: border-box;
        border-radius: 15px;
        color: #FFFFFF;
        width: 100%;

        @media (max-width: 1440px) {
          font-size: 13px;
        }
  
        @media (max-width: 1020px) {
          padding: 5px 10px;
          font-size: 12px;
        }
  
        @media (max-width: 520px) {
          width: 100%;
        }
      }

      .notify-shifts {
        background-color: #EFCB68;
        margin-top: 5px;
      }

      .view-shifts {
        margin-top: 6px;
      }

      @media (max-width: 460px) {
        .notify-shifts {
          margin-top: 6px;
        }
      }

      @media (max-width: 380px) {
        .notify-shifts {
          display: none;
        }
      }

      @media (max-width: 360px) {
        .view-shifts {
          margin-top: 8px;
        }
      }
    }
  }
}

.map-shift {
  background-color: #FFFFFF;
  border-radius: 12px;
  box-shadow: 0 4px 10px rgba(0,0,0,0.2);
  position: relative;
  display: flex;
  max-width: 400px;
  width: calc(100vw - 60px);
  color: #222222;

  @media (max-width: 460px) {
    width: calc(100vw - 60px);
  }
}

.store-notif {
  display: flex;
  position: fixed;
  left: 0;
  top: 0;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.25);
  z-index: 2;

  .notif-text {
    background-color: #55A08D;
    border-radius: 30px;
    padding: 20px;
    width: 250px;
    text-align: center;
    color: #FFFFFF;
    box-shadow: 0 4px 10px rgba(0,0,0,0.2);
  }
}