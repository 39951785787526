.store-profile-section.offering {
  display: flex;
  flex-flow: column;
  align-items: center;
  max-width: 600px;
  align-self: center;

  @media (max-width: 600px) {
    margin: 15px 10px 0 10px;
  }

  @media (max-width: 480px) {
    margin: 15px 5px 0 5px;
  }

  .offerings-list {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    margin-top: 10px;

    .offering-item {
      .store-profile-button {
        height: 35px;
        font-size: 15px;
        width: 180px;
        margin: 8px 10px;

        @media (max-width: 720px) {
          font-size: 14px;
          width: 150px;
          margin: 8px;
        }

        @media (max-width: 600px) {
          font-size: 13px;
          width: 130px;
        }

        @media (max-width: 480px) {
          font-size: 12px;
          width: 125px;
          margin: 8px 5px;
        }

        @media (max-width: 460px) {
          font-size: 11px;
          width: 115px;
          margin: 8px 4px;
        }

        @media (max-width: 420px) {
          font-size: 12px;
          width: 150px;
        }

        @media (max-width: 360px) {
          width: 200px;
          font-size: 13px;
        }
      }
    }
  }
}