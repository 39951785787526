.partner-card {
  margin: 0px 20px;
  
  .partner-card-link {
    display: flex;
    flex-direction: column;
    background-color: #FFFFFF;
    padding: 15px;
    border-radius: 4px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

    .partner-image {
      width: 140px;
      height: 100px;
      padding: 10px 40px;
      object-fit: contain;
    }
    
    .partner-name {
      text-align: center;
      color: #55A08D;
      font-family: Inter, mundo;
      font-size: 17px;
    }
    
    .partner-title {
      font-size: 16px;
      color: #AFAFAF;
      text-align: center;
      margin: 10px 0px;
    }
  }

  @media (max-width: 980px) {
    .partner-card-link {
      height: 190px;
      padding: 25px;

      .partner-image {
        width: 100px;
        height: 100px;
      }  
      
      .partner-name {
        font-size: 16px;
      }
    
      .partner-title {
        font-size: 14px;
      }
    }
  }

  @media (max-width: 790px) {
    margin: 0px 10px;

    .partner-card-link {
      height: 180px;
      padding: 0px 15px 15px 15px;

      .partner-image {
        width: 100px;
        height: 100px;
      }
    }
  }

  @media (max-width: 600px) {
    .partner-card-link {

      .partner-image {
        width: 100px;
        height: 100px;
        margin: 0px auto;
      }
    
      .partner-name {
        font-size: 13px;
      }
    }
  }
}