.messages-page {
  width: 100%;
  background-color: #CAE3DD;
  display: flex;
  flex-direction: column;
  padding: 60px;
  box-sizing: border-box;

  @media (max-width: 980px) {
    height: calc(100vh - 60px);
    height: calc(var(--vh, 1vh) * 100 - 60px);
    padding: unset;
  }

  .shift-header {
    display: flex;
    width: 100%;
    top: 0;
    left: 0;
    max-height: 60px;
    height: 60px;
    align-items: center;
    z-index: 1001;
    background-color: #CAE3DD;
    justify-content: space-between;

    @media (max-width: 480px) {
      position: fixed;
      z-index: 9001;
    }

    .chat-room-title-container {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      font-size: 14px;
      height: 40px;
      text-align: center;
      width: 100%;
      background-color: #CAE3DD;
      cursor: pointer;

      .chat-title-back {
        height: 10px;
        transform: rotate(90deg);
        margin: 0 20px;
        filter: brightness(0) saturate(100%) invert(56%) sepia(33%) saturate(446%) hue-rotate(114deg) brightness(94%) contrast(93%);
        
        @media (max-width: 380px) {
          height: 8px;
          margin: 0 10px;
        }
      }

      .chat-room-title {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        .chat-person {
          font-weight: 900;
          color: #55A08D;

          @media (max-width: 380px) {
            font-size: 12px;
          }
        }
  
        .chat-store {
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          max-width: 60vw;
          color: #55A08D;

          @media (max-width: 380px) {
            font-size: 12px;
          }
        }
      }
    }

    .logo-container {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 20px;
      width: 100%;
      height: 100%;

      .locumly-logo {
        max-height: 40px;
        padding: 5px 0;
        width: auto;
      }

      .locumly-logo-view {
        max-height: 35px;

        @media (max-width: 380px) {
          max-height: 25px;
        }
      }
    }

    .logo-viewing-message {
      justify-content: flex-end;
    }
  }

  .back-arrow-container {
    display: none;
    width: 200px;
    margin-left: 0px;

    @media (max-width: 480px) {
      margin-left: -5px;
    }
  }

  @media (max-width: 980px) {
    .back-arrow-container {
      display: flex;
    }

    .sidebar-container {
      width: 100%;
      height: 100%;

      &.viewing-message {
        height: calc(100vh - 60px);
        height: calc((var(--vh, 1vh) * 100) - 60px);
      }

      .notifications-and-messages-sidebar {
        transition: all 0.6s;
        visibility: visible;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
      }

      &.hidden {
        .notifications-and-messages-sidebar {
          -webkit-transform: translate3d(-100%, 0, 0);
          transform: translate3d(-100%, 0, 0);
        }
      }
    }
  }

  @media (max-width: 480px) {
    &.viewing-message {
      height: calc(100vh - 110px);
      height: calc((var(--vh, 1vh) * 100) - 110px);
    }
  }

  .messages-container {
    width: 100%;
    height: calc(100vh - 190px);
    height: calc((var(--vh, 1vh) * 100) - 190px);
    border-radius: 10px;
    background-color: #FFFFFF;
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;

    @media (max-width: 980px) {
      margin: 0 auto;
      height: 100%;
      border-radius: 30px 30px 0 0;
      box-shadow: unset;
    }

    .sidebar-container {
      width: 100%;
      max-width: 400px;

      @media (max-width: 980px) {
        max-width: unset;
      }
    }

    .chat-room-container {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      height: 100%;
      width: 100%;
      padding: 50px;
      box-sizing: border-box;

      @media (max-width: 1240px) {
        padding: 30px;
      }

      @media (max-width: 980px) {
        justify-content: center;
        padding: unset;
      }

      &.viewing-message {
        height: calc(100vh - 180px);
        height: calc((var(--vh, 1vh) * 100) - 180px);
      }

      .chat-room-title {
        width: 100%;
        color: #55A08D;
        box-sizing: border-box;
        // padding: 10px 10px 15px;

        .chat-person {
          font-weight: 800;
        }

        .divider {
          margin: 0 15px;
          border-radius: 15px;
          border-left: 3px solid #55A08D;;
        }

        .chat-store {

        }
      }

      // @media (max-width: 1300px) {
      //   width: calc(100% - 350px);
      // }
    
      // @media (max-width: 1200px) {
      //   width: calc(100% - 300px);
      // }
    
      @media (max-width: 980px) {
        // width: calc(100% - 250px);
        width: 100%;
        font-size: 16px;
      }
    
      @media (max-width: 600px) {
        display: flex;
      }
      
      .sidebar-container {
        position: absolute;
        width: 0;
      }

      .chat-room {
        width: 100%;
        height: 100%;
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;

        @media (max-width: 980px) {
          display: block;
        }

        .chat-history {
          display: flex;
          flex-direction: column;
          height: 70%;
          margin-bottom: 20px;
          overflow-y: scroll;
          background-color: #F2F2F2;
          border-radius: 10px;
          padding: 30px;

          @media (max-width: 1240px) {
            padding: 20px;
          }

          @media(max-width: 980px) {
            padding: unset;
            background-color: #FFFFFF;
            border-radius: unset;
            height: 100%;
          }

          .chat-receiver-name {
            display: flex;
            margin: 10px 25px;
            font-size: 12px;
            font-weight: 300;
          }

          .chat-datestamp {
            margin: 10px auto;
            text-align: center;
            color: #616161;
            font-size: 14px;
            display: flex;
            align-self: center;
            font-weight: 900;
          }
          
          .chat-msg-container {
            display: flex;
            white-space: pre-wrap;

            @media (max-width: 420px) {
              flex-direction: column;
            }

            .chat-timestamp {
              margin: 10px auto;
              text-align: center;
              color: #616161;
              font-size: 12px;
              display: flex;
              align-self: center;

              &.receiver {
                margin: 10px auto 10px 0;

                @media (max-width: 420px) {
                  margin: 0 auto 0 20px; 
                  font-size: 10px;
                }
              }
  
              &.sender {
                margin: 10px 0 10px auto;

                @media (max-width: 420px) {
                  margin: 0 20px 0 auto;
                  font-size: 10px;
                }
              }
            }
  
            .chat-attachment {
              max-width: 400px;
              width: 400px;
              padding: 10px 20px;
              width: fit-content;
              border-radius: 6px;
              line-height: 15px;
  
              .size {
                margin-left: 10px;
              }
  
              @media (max-width: 980px) {
                max-width: 320px;
                width: 320px;
              }
  
              @media (max-width: 800px) {
                max-width: 280px;
                width: 280px;
              }
  
              @media (max-width: 700px) {
                max-width: 240px;
                width: 240px;
              }
  
              @media (max-width: 600px) {
                max-width: 280px;
                width: 280px;
              }

              @media (max-width: 420px) {
                max-width: 240px;
                width: 240px;
                font-size: 14px;
              }

              @media (max-width: 360px) {
                max-width: 220px;
                width: 220px;
              }
  
              .download-link {
                text-decoration: underline;
                font-weight: 900;
              }
  
              &.receiver {
                background-color: #CAE3DD; 
                margin: 7px 10px 7px 20px;
                border-radius: 20px 20px 20px 0px;

                @media (max-width: 420px) {
                  margin: 7px 10px 0 20px;
                }
              }
  
              &.sender {
                background-color: #FFFFFF;
                margin: 7px 20px 7px 10px;
                border-radius: 20px 20px 0px 20px;
                order: 1;
                display: flex;
                justify-content: flex-end;

                @media (max-width: 980px) {
                  background-color: #F5F5F5;
                }

                @media (max-width: 420px) {
                  order: 0;
                  align-self: flex-end;
                }
              }
            }
  
            .chat-message {
              max-width: 400px;
              width: 400px;
              padding: 10px 20px;
              width: fit-content;
              border-radius: 6px;
              line-height: 15px;
  
              @media (max-width: 980px) {
                max-width: 320px;
                width: 320px
              }
  
              @media (max-width: 800px) {
                max-width: 280px;
                width: 280px;
              }
  
              @media (max-width: 700px) {
                max-width: 240px;
                width: 240px;
              }
  
              @media (max-width: 600px) {
                max-width: 280px;
                width: 280px;
              }

              @media (max-width: 420px) {
                max-width: 240px;
                width: 240px;
                font-size: 14px;
              }

              @media (max-width: 360px) {
                max-width: 220px;
                width: 220px;
              }
  
              &.receiver {
                background-color: #CAE3DD; 
                margin: 7px 10px 7px 20px;
                border-radius: 20px 20px 20px 0px;

                @media (max-width: 420px) {
                  margin: 7px 10px 0 20px;
                }
              }
  
              &.sender {
                background-color: #FFFFFF;
                margin: 7px 20px 7px 10px;
                border-radius: 20px 20px 0px 20px;
                order: 1;
                display: flex;
                justify-content: flex-end;

                @media (max-width: 980px) {
                  background-color: #F5F5F5;
                }

                @media (max-width: 420px) {
                  order: 0;
                  align-self: flex-end;
                  margin: 7px 20px 0 10px;
                }
              }
            }
          }
        }
      }
    }
  }
}

.chat-input-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  bottom: 20px;
  padding: 5px 0;
  background-color: #FFFFFF;

  @media (max-width: 980px) {
    position: fixed;
  }

  @media (max-width: 480px) {
    bottom: 60px;
    &.hidden {
      display: none;
    }
  }
  
  .chat-bar {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: rgba(249, 249, 249, 0.94);
    border-radius: 30px;
    height: 50px;
    width: 100%;
    padding: 3px 6px;

    @media (max-width: 980px) {
      width: 90%;
      padding: unset;
    }

    .attachment-button {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50px;
      background-color: #55a08d;
      color: #FFFFFF;
      font-size: 34px;
      height: 35px;
      width: 35px;
      margin: 0px 10px;
      padding: 0px 10px 3px 9px;

      @media (max-width: 980px) {
        height: 32px;
        width: 32px;
        font-size: 30px;
        padding: 0 10px;
      }

      @media (max-width: 800px) {
        height: 30px;
        width: 30px;
        font-size: 28px;
        margin: 0 5px 0px 10px;
      }

      .add-attachment {
        margin-bottom: 2px;
        margin-left: 1px;
      }

      input {
        display: none;
      }

      &:hover {
        cursor: pointer;
        filter: brightness(110%);
      }
    }

    .emoji-button {
      height: 40px;
      width: 40px;
      border-radius: 50px;
      background-color: transparent;
      margin: 0px 0px;
      display: flex;
      justify-content: center;
      align-items: center;

      @media (max-width: 980px) {
        height: 35px;
        width: 35px;
      }

      @media (max-width: 800px) {
        height: 25px;
        width: 25px;
      }

      .emoji-icon {
        height: 35px;
        width: 35px;

        @media (max-width: 980px) {
          height: 30px;
          width: 30px;
        }

        @media (max-width: 800px) {
          height: 25px;
          width: 25px;
        }
      }

      &:hover {
        cursor: pointer;
        filter: brightness(90%);
        background-color: #F3FFFD;
      }
    }

    .emoji-picker-container {
      width: 0;
      height: 0;

      .emoji-picker-react {
        width: 250px;
        display: block;
        position: relative;
        top: -330px;
        left: -209px;
        height: 280px;
        overflow-y: scroll;
      }

      .triangle {
        width: 0;
        height: 0;
        position: relative;
        top: -321px;
        left: 20px;
        border: 10px solid transparent;
        border-bottom: 0;
        border-top: 15px solid #FFFFFF;
        transform: scaleY(-1);
      }
    }

    .inputs {
      width: 100%;
    }

    .chat-input {
      width: 100%;
      height: 20px;
      border: none;
      outline: none;
      font-size: 16px;
      padding: 2.5px;
      background-color: transparent;
      resize: none;
    }

    .chat-input-content {
      height: 100%;
    }

    .send-button {
      margin: 0 5px;
      background-color: #55A08D;
      border-radius: 30px;
      padding: 6px;
      display: flex;
      justify-content: center;
      align-items: center;

      &:hover {
        cursor: pointer;
        filter: brightness(110%);
      }

      .send-message-icon {
        width: 25px;
        height: 25px;

        @media (max-width: 480px) {
          height: 18px;
          width: 18px;
        }
      }
    }
  }
}

.attachments-container {
  height: 0;
  position: absolute;

  .attachments {
    display: flex;
    flex-direction: column-reverse;
    height: auto;
    position: relative;
    bottom: 25px;
  }
}

.attachment {
  font-size: 12px;
  background-color: #D7D9DD;
  padding: 5px;
  align-self: flex-start;

  .delete-container {
    height: 0;
    width: 0;
    position: absolute;

    .delete {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      width: 20px;
      height: 20px;
      border-radius: 200px;
      text-align: center;
      position: relative;
      top: -15px;
      left: -5px;
      background-color: #FFFFFF;

      &:hover {
        cursor: pointer;
        filter: brightness(90%);
      }
    }
  }
}