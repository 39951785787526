.footer-background {
  background-color: #56A08D;
  display: flex;
  flex-direction: column;
  color: #FFFFFF;
  overflow-x: hidden;
  padding: 30px 80px 0 80px;

  @media (max-width: 980px) {
    align-items: center;
    padding: 25px 40px;
  }

  .footer-logo {
    width: 160px;
    margin: 15px 30px 10px 30px;
  }

  .footer-head {
    display: flex;
    justify-content: space-between;
    margin: 20px 30px 0px 30px;

    @media (max-width: 980px) {
      flex-direction: column-reverse;
      justify-content: center;
      align-items: center;
    }

    @media (max-width: 480px) {
      margin-top: 0;
    }

    .footer-navigation {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 460px;

      @media (max-width: 480px) {
        flex-direction: column;
        width: unset;
        align-items: flex-start;

        .footer-group {
          margin: 20px 0;
        }
      }

      .footer-navigation-list-title {
        font-size: 22px;
        font-weight: 900;
      }

      .footer-navigation-list {
        .footer-nav {
          margin: 15px 0px;
        
          .footer-nav-link {
            color: #FFFFFF;

            &:hover {
              cursor: pointer;
              filter: brightness(90%);
            }
          }
        }
      } 
    }
  }

  .divider {
    border-color: #8DB6AB;
    border-width: 1px;
    box-shadow: none;
    border-style: solid;
    width: 95%;
    margin: 20px;

    @media (max-width: 480px) {
      margin: 10px 10px 20px 10px;
      width: 100%;
    }
  }

  .social-navigation {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    .social-link {
      background-color: #8AD7BF;
      display: flex;
      width: 28px;
      height: 28px;
      padding: 10px;
      border-radius: 50px;
      margin: 0px 5px;
    
      .social-icon {
        width: 28px;
        height: 28px;
    
        &.linkedin-icon {
          width: 26px;
          height: 26px;
          position: relative;
          left: 1px;
        }
      }
    }
  }
  
  .trademark {
    font-size: 18px;
    text-align: center;
    margin: 20px 0px 15px 0px;
  }
}

.hide-footer {
  display: none;
}