.mobile-search-component {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  position: fixed;
  width: 100%;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  top: 100%;
  left: 0;
  bottom: 0;
  z-index: 1;
  transition: all 0.3s ease-out;
  background-color: #f7f7f7;
  opacity: 0;

  ::-webkit-scrollbar { 
    display: none; 
  }

  @keyframes fadeInDown-header-body {
    0% {
      opacity: 0;
      transform: translateY(-100px);
    }

    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @keyframes fadeInDown-footer {
    0% {
      opacity: 0;
      transform: translateY(100px);
    }

    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }

  // ********* HEADER ***********
  .mobile-search-header {
    display: grid;
    padding-top: 18px;
    padding-bottom: 9px;
    flex-shrink: 0;
    place-items: center;
    position: relative;
    animation: fadeInDown-header-body ease 0.5s;
    animation-delay: var(--staggered_animation_delay, 1);

    .mobile-search-exit-container {
      position: absolute;
      top: 22px;
      left: 20px;
      box-sizing: border-box;

      .mobile-search-exit-button {
        position: absolute;
        appearance: none;
        border: 1px solid rgb(176, 176, 176);
        border-radius: 50%;
        cursor: pointer;
        height: 32px;
        width: 32px;
        display: inline-flex;
        align-items: center;
        justify-content: center;

        .exit-icon {
          height: 12px;
          width: 12px;
        }
      }
    }

    .work-type {
      position: relative;
      font-size: 16px;
      line-height: 20px;
      padding: 5px 10px 0px;
      margin: 4px 0;
      background-color: #f7f7f7;
      color: rgb(113, 113, 113);
      cursor: pointer;
    }

    .selected {
      color: black;
      border-bottom: 2px solid black;
      transition: left 0.2s, width 0.2s;
      font-weight: 600;
      cursor: default;
    }

    .coming-soon {
      cursor: default;
    }

    .coming-soon-item {
      opacity: 0.5;
    }

    .coming-soon-text {
      color: #5fa4f9;
      opacity: 1;
    }
  }

  // ********* BODY ***********
  .mobile-search-body {
    overflow-y: auto;
    overflow-x: hidden;
    -webkit-box-flex: 2;
    flex-grow: 2;
    box-sizing: border-box;

    .filter-margins {
      margin: 15px 12px 0 12px;

      .panel-size {
        background-color: #ffffff;
        border-radius: 24px;
        box-shadow: 0 1px 2px rgb(0 0 0 / 5%), 0 4px 6px rgb(0 0 0 / 3%);
        border: none;

        .panel-button {
          cursor: pointer;
          padding: 24px 20px;
          border-radius: 16px;
          border: none;
          margin: 0;
          background: transparent;
          background-color: #ffffff;
          display: flex;
          flex-direction: row;
          height: 56px;
          width: 100%;
          justify-content: space-between;
          align-items: center;
          outline: none;
          overflow: visible;

          .panel-title {
            font-size: 14px;
            line-height: 18px;
            font-weight: 600;
            color: #717171;
          }

          .panel-search-term {
            padding-left: 16px;
            font-size: 14px;
            line-height: 18px;
            font-weight: 600;
            color: #222222;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }
        }
      }

      .when-panel-open {
        left: 0;
        right: 0;
        margin: 0 12px 7px 12px;
        box-shadow: 0 0 0 1px rgb(0 0 0 / 4%), 0 6px 20px rgb(0 0 0 / 20%);
        border-radius: 24px;
        background-color: #FFFFFF;
        border: none;
        position: absolute;
        top: 144px;
        bottom: 0;
        overflow: hidden;
        z-index: 1;
        box-sizing: border-box;
        font-size: 14px;
        line-height: 1.43;
      }

      .panel-open {
        padding: 24px;
        height: 100%;
        box-shadow: 0 0 0 1px rgb(0 0 0 / 4%), 0 6px 20px rgb(0 0 0 / 20%);
        border: none;
        border-radius: 24px;
        background-color: #ffffff;
      }

      .panel-open-title {
        font-size: 22px;
        line-height: 26px;
        font-weight: 800;

        @media (max-width: 337px) {
          font-size: 20px;
        }
      }

      .panel-closed {
        display: none;
      }
    }
  }

  // ********* FOOTER ***********
  .mobile-search-footer {
    display: flex;
    justify-content: space-between;
    flex-shrink: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.05);
    padding: 12px 24px;
    background: rgba(255, 255, 255, 0.5);

    animation: fadeInDown-footer ease 0.5s;
    animation-fill-mode: forwards;
    animation-delay: var(--staggered_animation_delay, 1);

    .clear-all {
      cursor: pointer;
      display: inline-block;
      margin: 0px -10px;
      position: relative;
      text-align: center;
      width: auto;
      touch-action: manipulation;
      padding: 10px;
      border: none;
      background: transparent;
      text-decoration: underline;
      font-size: 16px;
      font-weight: 600;
      line-height: 20px;

      .footer-text {
        line-height: 18px;
      }
    }

    .footer-search-button {
      cursor: pointer;
      display: inline-block;
      margin: 0px;
      position: relative;
      text-align: center;
      text-decoration: none;
      width: auto;
      touch-action: manipulation;
      font-size: 16px;
      font-weight: 600;
      line-height: 20px;
      outline: none;
      padding: 14px 24px;
      border: none;

      .button-container {
        left: 0;
        right: 0;
        border-radius: 8px;
        position: absolute;
        top: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;
        cursor: pointer;
        background-color: #56a08d;
      }

      .text-container {
        display: block;
        position: relative;
        pointer-events: none;
        cursor: pointer;

        .aligner {
          display: flex;
          align-items: center;
          pointer-events: none;
          cursor: pointer;
          text-align: center;
          line-height: 18px;

          .search-icon {
            display: block;
            height: 16px;
            width: 16px;
            fill: currentColor;
          }

          .search-text {
            margin-left: 10px;
            line-height: 18px;
            font-size: 16px;
            line-break: strict;
          }
        }
      }
    }
  }
}

.filter-carousel {
  margin-top: 12px;
  padding-bottom: 0px;
  width: 100%;

  &:not(:first-child) {
    border-top: 1px solid rgb(235, 235, 235);
  }

  .filter-title {
    padding-top: 12px;
    padding-bottom: 4px;
    padding-right: 8px;
    font-size: 16px;
    line-height: 20px;
    font-weight: 600;
    color: rgb(34, 34, 34);
    margin: 0px;
  }

  .filter-container {
    box-sizing: border-box;

    .filter-scroll {
      display: flex;
      flex-wrap: wrap;

      @media (max-width: 480px) {
        flex-wrap: unset;
        overflow-x: scroll;
        margin-left: -24px;
        margin-right: -24px;
      }

      .item-container {
        &:first-child {
          @media (max-width: 480px) {
            margin-left: 24px;
            box-sizing: border-box;
          }
        }

        &:last-child {
          margin-right: 16px;
          box-sizing: border-box;
        }

        .item-padding {
          display: inline-block;
          white-space: nowrap;
          padding-right: 8px;
          padding-top: 4px;
          padding-bottom: 4px;

          .active-button {
            cursor: pointer;
            text-align: center;
            border: 1px solid rgb(34, 34, 34);
            outline: none;
            margin: 0px;
            position: relative;
            transition-property: -ms-transform, -webkit-transform, transform, background-color, border-color;
            transition-duration: 0.15s;
            transition-timing-function: ease-in-out;
            padding: 4px 12px;
            min-height: 32px;
            border-radius: 16px;
            font-size: 14px;
            line-height: 18px;
            background-color: rgb(247, 247, 247);
            color: rgb(34, 34, 34);

            &:active {
              color: rgb(0, 0, 0);
              border-color: rgb(0, 0, 0);
              transform: scale(0.925);
            }
          }

          .inactive-button {
            cursor: pointer;
            text-align: center;
            border: 1px solid rgb(221, 221, 221);
            background-color: rgb(255, 255, 255);
            outline: none;
            margin: 0px;
            color: rgb(34, 34, 34);
            position: relative;
            transition-property: -ms-transform, -webkit-transform, transform, background-color, border-color;
            transition-duration: 0.15s;
            transition-timing-function: ease-in-out;
            padding: 4px 12px;
            min-height: 32px;
            border-radius: 16px;
            font-size: 14px;
            line-height: 18px;

            &:hover {
              color: rgb(0, 0, 0);
              border-color: rgb(0, 0, 0);
            }

            &:active {
              color: rgb(34, 34, 34);
              border-color: rgb(34, 34, 34);
              transform: scale(0.925);
            }
          }

          .coming-soon {
            cursor: default;
            background-color: rgb(255, 255, 255);
            border-color: rgba(221, 221, 221, 0.5);
            color: rgb(34, 34, 34);

            &:hover {
              border-color: rgba(221, 221, 221, 0.5);
              color: rgb(34, 34, 34);
            }

            &:active {
              color: rgb(34, 34, 34);
              border-color: rgba(221, 221, 221, 0.5);
              transform: scale(1);
            }
          }

          .coming-soon-item {
            opacity: 0.5;
          }

          .coming-soon-text {
            color: #5fa4f9;
            opacity: 1;
          }

          .item-span {
            font-weight: 400;
            font-size: 14px;
            line-height: 18px;
          }
        }
      }
    }
  }
}

.open-panel {
  top: 0;
  opacity: 1;
}

.prevent-scroll {
  overflow: hidden;
  touch-action: none;
  -ms-touch-action: none;
}

.search-bar {
  margin-bottom: 10px;
  position: relative;
  padding: 0;

  .filter-dropdown {
    z-index: 1;
    position: absolute;
    width: 100%;
    left: 0;
    top: 100px;
    background-color: #FFFFFF;
    box-sizing: border-box;
    overflow: hidden;
    border-radius: 30px;
    box-shadow: 0 10px 15px -3px rgb(0 0 0 / 10%), 0 4px 6px -2px rgb(0 0 0 / 5%);
    padding: 40px;

    @media (max-width: 1440px) {
      top: 80px;
    }
  }

  .filters {
    justify-content: space-around;
    border-radius: 60px;
    cursor: pointer;

    &.darken-search {
      background-color: #DDDDDD;
    }

    .highlight-container {
      width: 100%;
      border-radius: 60px;
      padding: 20px 0;

      @media (max-width: 1980px) {
        padding: 10px 0;
      }

      @media (max-width: 1440px) {
        padding: 0;
      }

      @media (max-width: 860px) {
        font-size: 12px;
      }

      &:hover {
        background-color: #EFEFEF;
      }

      &.highlight-filter {
        background-color: #FFFFFF;
      }

      .location-filter {
        display: flex;
        flex-direction: column;
        flex: 1 1 auto;
        padding: 20px 30px;
      }
  
      .when-filter {
        display: flex;
        flex-direction: column;
        flex: 1 1 auto;
        padding: 20px 30px;
      }
  
      .other-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex: 1 1 auto;
        padding: 20px 30px;
  
        .other-filter {
          display: flex;
          flex-direction: column;
          width: 100%;
        }
    
        .search-button {
          display: flex;
          align-items: center;
          justify-content: space-evenly;
          background-color: #56a08d;
          border-radius: 50px;
          padding: 12px 10px;
          cursor: pointer;
          min-width: 100px;

          @media (max-width: 980px) {
            min-width: 80px;
          }

          @media (max-width: 800px) {
            min-width: 60px;
          }

          @media (max-width: 720px) {
            min-width: 25px;
          }

          @media (max-width: 600px) {
            margin-left: 15px;
          }
  
          &.home-page {
            width: 100%;
          }
  
          .search-icon {
            filter: brightness(100);
            height: 17px;
            width: 17px;
            align-self: flex-start;
          }
  
          .search-text {
            color: #FFFFFF;
            font-weight: 800;
            font-size: 14px;
          }
        }
      }
    }

    .subtitle-span {
      font-weight: 300;
      font-size: 14px;

      @media (max-width: 860px) {
        font-size: 10px;
      }
    }
  }
}