.shift-card.business {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 380px;
  min-width: 230px;
  max-width: 230px;
  margin: 0px 15px;
  border-radius: 0px;
  border: solid 2px #808080;
  padding: 10px 20px 10px 20px;
  line-height: 18px;
  font-size: 11px;
  background-color: #FFFFFF;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  
  .shift-details {
    display: flex;
    flex-direction: column;

    .status {
      padding: 0 10px;
      font-size: 11px;
      border-radius: 8px;
      width: 100px;
      height: 30px;
      line-height: 12px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin: 0 0 3px auto;
      font-weight: 900;
      font-family: Helvetica, Arial, sans-serif;

      .subtitle {
        font-style: italic;
        font-size: 10px;
        font-weight: 400;
      }

      &.filled {
        background-color: #BDCC99;
        color: #28446A;
      }

      &.unfilled {
        background-color: #FCC938;
        color: #000000;
      }

      &.confirmed {
        background-color: #56A08D;
        color: #FFFFFF;
      }

      &.disputed {
        background-color: #d40700;
        color: #FFFFFF;
      }
    }

    .store-name {
      font-size: 13px;
      font-weight: 600;
      color: #000000;
      margin-bottom: 0px;
      white-space: pre-wrap;
      height: 60px;
    }

    .shift-date {
      .add-to-calendar {
        color: #28446a;
        text-decoration: underline;
        font-size: 11px;
        margin-left: 5px;
        position: relative;
        top: -1px;
      }
    }

    .shift-icon {
      width: 12px;
      height: 16px;
      margin-right: 6px;
      position: relative;
      top: 3px;
    }


    .shift-rate,
    .shift-negotiable.negotiable {
      font-weight: 900;
      color: #000000;
    }
  }

  .shift-buttons {
    display: flex;
    flex-direction: column;

    .shift-locum,
    .shift-applicants {
      font-size: 12px;
      font-weight: 900;
      color: #000000;
    }

    .shift-button {
      padding: 5px 0px;
      margin: 4px 0;
      font-size: 12px;
      font-weight: 600;
      border-radius: 6px;

      &.view-locum-button {
        background-color: #AACFC6;
        color: #212529;
      }

      &.view-shift-button {
        background-color: #808080;
        color: #FFFFFF;
      }

      &.message-locum-button,
      &.view-applicants-button {
        background-color: #28446A;
        color: #FFFFFF;
      }
    }
  }
}