.share-store-button-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #F6F5F5;
  padding: 20px 15px;

  @media (max-width: 600px) {
    flex-flow: column;
    height: 60px
  }

  .logo-and-link {
    display: flex;
    align-items: center;

    .locumly-logo-icon {
      height: 28px;
      width: 50px;
      margin-right: 4px;

      @media (max-width: 720px) {
        width: 40px;
        height: 25px;
      }

      @media (max-width: 480px) {
        width: 35px;
        height: 25px;
      }
    }

    .link {
      font: 400 16px Inter, Helvetica, Arial, sans-serif;

      @media (max-width: 720px) {
        font-size: 14px;
      }

      @media (max-width: 600px) {
        font-size: 12px;
      }
    }
  }

  .copy-link-button {
    background: transparent;
    color: #55A08D;
    font: 400 16px Inter, Helvetica, Arial, sans-serif;
    width: 100px;

    &.disabled:hover {
      cursor: auto;
    }
  }
}