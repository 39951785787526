.search-bar {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0 auto;
  background-color: #FFFFFF;
  font-size: 16px;
  box-shadow: -3px 7px 10px rgba(0, 0, 0, 0.1);
  border-radius: 300px;
  max-width: 1440px;
  width: 1260px;

  @media (max-width: 1920px) {
    width: 65vw;
  }

  @media (max-width: 1250px) {
    width: 75vw;
  }

  @media (max-width: 980px) {
    width: 80vw;
  }

  @media (max-width: 620px) {
    width: 85vw;
  }


  @media (max-width: 480px) {
    border-radius: 80px;
    width: 70vw;
  }

  .filters {
    display: flex;
    flex: 1;
    align-items: center;

    @media (max-width: 480px) {
      display: block;
      margin-bottom: -50px;
    }

    .location-and-type {
      display: flex;
      flex: 12;
      margin-left: 20px;

      @media (max-width: 800px) {
        flex-direction: column;
      }

      @media (max-width: 480px) {
        margin: 0px 0px 0px 30px;
        height: 50%;
      }
      
      .shift-location-picker {
        display: flex;
        flex-direction: column;
        flex: 3;
        
        .location-picker-label {
          margin-right: 10px;
        }
      }

      .shift-type-dropdown {
        display: flex;
        flex-wrap: nowrap;
        flex: 1;

        @media (max-width: 720px) {
          flex: 2;
        }
      }
    }

    .date-pickers {
      display: flex;
      flex: 4;
      justify-content: space-between;

      @media (max-width: 800px) {
        flex-direction: column;
      }

      @media (max-width: 480px) {
        height: 50%;
      }

      .shift-date-picker-container {
        margin: 0 25px 0 25px;

        @media (max-width: 1250px) {
          margin: 0 10px 0 10px;
        }

        @media (max-width: 480px) {
          margin: 0px 0px 0px 30px;
          width: 84px;
        }
      }
    }
  
    .search-button-container {
      flex: 2;
      height: 100%;

      .search-button {
        background-color: #55a08d;
        border-radius: 100px;
        height: 75px;
        width: 75px;
        padding: 5px;
        float: right;
        margin-right: 20px;

        &.disabled {
          opacity: 0.6;

          &:hover.very-dark {
            cursor: auto;
            filter: brightness(100%);
          }
        }

        @media (max-width: 1440px) {
          height: 60px;
          width: 60px;
        }

        @media (max-width: 880px) {
          height: 50px;
          width: 50px;
        }

        @media (max-width: 480px) {
          position: relative;
          top: -130px;
        }

        .search-icon {
          width: 40px;
          height: 40px;

          @media (max-width: 1440px) {
            height: 35px;
            width: 35px;
          }

          @media (max-width: 980px) {
            height: 30px;
            width: 30px;
          }
        }
      }
    }
  }
}