.store-profile-section {
  margin: 15px 50px;

  @media (max-width: 480px) {
    margin: 15px 20px;
  }

  @media (max-width: 420px) {
    margin: 15px 10px;
  }

  .section-title {
    font: 600 24px Inter, Helvetica, Arial, sans-serif;
    display: flex;
    line-height: 28px;
    margin: 15px 0;

    @media (max-width: 480px) {
      font-size: 20px;
      margin: 10px 0;
      line-height: 23px;
    }

    &.left {
      margin-left: 20px;
    }

    &.center {
      text-align: center;
      align-items: center;
      justify-content: center;
    }
  }
}