.store-shift-item.casual {
  display: flex;
  padding: 10px 15px;

  .shift-details {
    display: flex;
    flex-flow: column;
    justify-content: space-evenly;
    align-items: flex-start;
    width: 320px;
    margin-right: 20px;

    @media (max-width: 480px) {
      width: 240px;
    }

    @media (max-width: 420px) {
      width: 200px;
      margin-right: 5px;
    }
    
    @media (max-width: 360px) {
      width: 180px;
    }

    .store-name {
      font: 600 15px Inter, Helvetica, Arial, sans-serif;
      margin-bottom: 20px;
      

      @media (max-width: 420px) {
        font-size: 14px;
      }
    }

    .date {
      font: 400 13px Inter, Helvetica, Arial, sans-serif;

      @media (max-width: 420px) {
        font-size: 12px;
      }
    }

    .time {
      font: 400 13px Inter, Helvetica, Arial, sans-serif;
      margin-bottom: 10px;

      @media (max-width: 420px) {
        font-size: 12px;
      }
    }
  }

  .shift-actions {
    display: flex;
    flex-flow: column;
    justify-content: space-evenly;
    align-items: flex-end;
    width: 100px;

    .rate {
      font: 400 12px Inter, Helvetica, Arial, sans-serif;
    }

    .total {
      font: 600 13px Inter, Helvetica, Arial, sans-serif;
    }

    .view-shift-button {
      background: #55a08d;
      color: #FFFF;
      padding: 6px 10px;
      border-radius: 20px;
      font: 400 12px Inter,Helvetica,Arial,sans-serif;
      padding: 6px 12px;

      @media (max-width: 420px) {
        font-size: 11px;
      }
    }

    .quick-add-button {
      background: #efcb68;
      color: #FFFF;
      padding: 6px 10px;
      border-radius: 20px;
      font: 400 12px Inter,Helvetica,Arial,sans-serif;
      padding: 6px 12px;

      @media (max-width: 420px) {
        font-size: 11px;
      }
    }
  }
}