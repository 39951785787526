.rating-input-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 12px 0;

  .rating-input-label {
    font: 400 14px Inter, Helvetica, Arial, sans-serif;
    color: #1A1A1A;
    margin: 4px 0;

    @media (max-width: 480px) {
      width: 100px;
      font-size: 13px;
      margin: 2px 0;
    }

    @media (max-width: 420px) {
      width: 80px;
      font-size: 12px;
      margin: 4px 0;
    }
  }
}