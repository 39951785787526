.locum-profile {
  background-color: #FFFFFF;
  border: solid 3px #E6E6E6;
  display: flex;
  height: auto;
  min-width: 600px;

  @media (max-width: 720px) {
    flex-direction: column;
    min-width: 440px;
  }

  @media (max-width: 480px) {
    min-width: 270px;
  }

  .locum-info-sidebar {
    display: flex;
    flex-direction: column;
    min-width: 220px;
    align-items: center;
    margin: 20px 0;

    @media (max-width: 720px) {
      flex-direction: row;
      justify-content: space-evenly;
    }

    .red-text {
      color: #FF0000;
    }

    .locum-avatar-and-name {
      display: flex;
      flex-direction: column;

      @media (max-width: 720px) {
        align-items: center;
        max-width: 300px;
      }

      @media (max-width: 480px) {
        max-width: 180px;
      }

      @media (max-width: 420px) {
        max-width: 160px;
      }

      @media (max-width: 360px) {
        max-width: 140px;
      }

      .locum-avatar-container {
        height: 180px;
        width: 180px;
        margin: 30px auto 0 auto;
        border-radius: 200px;
        border: solid 2px #C0C5DA;
        padding: 3px;
  
        @media (max-width: 720px) {
          height: 120px;
          width: 120px;
          margin: 10px 0;
        }

        @media (max-width: 360px) {
          height: 100px;
          width: 100px;
        }
  
        .locum-avatar {
          height: 180px;
          width: 180px;
          border-radius: 200px;
  
          @media (max-width: 720px) {
            height: 120px;
            width: 120px;
          }

          @media (max-width: 360px) {
            height: 100px;
            width: 100px;
          }
        }
      }

      .name {
        font-size: 24px;
        color: #56a08d;
        margin: 20px 15px;
        text-align: center;
  
        @media (max-width: 1050px) {
          font-size: 22px;
        }

        @media (max-width: 720px) {
          font-size: 20px;
        }
      }

      .avatar-image-upload {
        visibility: hidden;
        height: 0;
      }

      .upload-avatar {
        background-color: #28446a;
        color: #FFFFFF;
        border-radius: 5px;
        padding: 8px 0;
        width: 160px;
        font-size: 14px;
        margin: 15px auto 0 auto;
        text-align: center;

        @media (max-width: 420px) {
          font-size: 13px;
          width: 140px;
        }

        @media (max-width: 360px) {
          font-size: 12px;
          width: 120px;
        }

        &:hover {
          cursor: pointer;
          opacity: 0.9;
          filter: brightness(125%);
        }
      }
    }

    .locum-summary {
      display: flex;
      flex-direction: column;
      align-items: center;

      .profile-buttons {
        display: none;

        @media (max-width: 720px) {
          display: flex;
          width: auto;
          min-width: 160px;
          align-self: flex-start;
          margin-bottom: 20px;
        }

        @media (max-width: 480px) {
          display: flex;
          width: auto;
          min-width: 160px;
          align-self: flex-start;
          margin-bottom: 10px;
        }

        @media (max-width: 480px) {
          min-width: 140px;
        }
      }

      .shifts-completed, .suburb-and-state, .abn, .acn, .ahpra {
        margin-bottom: 10px;
        font-size: 16px;

        @media (max-width: 480px) {
          font-size: 14px;
          margin-bottom: 5px;
        }
      }

      .shifts-completed {
        .colored-emphasis {
          color: #56a08d;
        }
      }
    }
  }

  .locum-details {
    display: flex;
    flex-direction: column;
    margin: 25px 15px;
    overflow-y: auto;
    min-width: 380px;
    width: 100%;

    @media (max-width: 720px) {
      margin: 0;
      padding: 5px 20px;
      width: calc(100% - 40px);
    }

    @media (max-width: 480px) {
      padding: 5px 10px;
      min-width: 270px;
    }

    .experiences-to-date {
      display: flex;
      flex-direction: column;
      margin: 5px 0px;

      .title {
        font-size: 20px;
        font-weight: 900;
      }

      @media (max-width: 480px) {
        font-size: 18px;
      }

      .coming-soon {
        color: #808080;
        margin-top: 5px;
      }

      .experience-list {
        .experience-item {
          border: solid 1px #808080;
          background-color: #E3E1D6;
          padding: 8px;
          text-align: center;
          font-size: 14px;
          font-family: Arial, Helvetica, sans-serif;
          margin: 10px 0;

          .experience-label {
            font-weight: 900;
            font-family: Arial, Helvetica, sans-serif;
          }
        }
      }
    }

    .about-and-confirm {
      display: flex;
      
      .about {
        width: 100%;
        min-width: 200px;
        margin-right: 15px;

        @media (max-width: 720px) {
          margin-right: 0;
        }

        .title {
          font-size: 22px;
          font-weight: 900;

          @media (max-width: 720px) {
            font-size: 20px;
          }

          @media (max-width: 480px) {
            font-size: 18px;
          }
        }

        .about-text {
          font-size: 16px;
          min-width: 196px;
          height: 130px;
          border: solid 2px #E6E6E6;
          padding: 10px;
          border-radius: 15px;
          margin: 5px 0 15px 0;
          width: 100%;

          @media (max-width: 720px) {
            height: 60px;
            border-radius: 8px;
            width: auto;
          }

          @media (max-width: 480px) {
            height: 80px;
            font-size: 14px;
          }
        }
      }

      .profile-buttons {
        min-width: 180px;
        width: 75%;
      }

      @media (max-width: 980px) {
        .profile-buttons {
          min-width: 180px;
          width: 85%;
        }
      }

      @media (max-width: 720px) {
        .profile-buttons {
          display: none;
        }
      }
    }
  }
}

.profile-buttons {
  display: flex;
  flex-direction: column;
  width: 100%;

  .applicant-button {
    font-size: 14px;
    color: #FFFFFF;
    border-radius: 20px;
    min-width: 160px;
    height: 35px;
    align-self: center;
    margin: 5px auto;

    @media (max-width: 720px) {
      min-width: 200px;
    }

    @media (max-width: 540px) {
      min-width: 150px;
    }
  }

  .confirm-locum-button {
    background-color: #28446a;

    &.disabled {
      opacity: 0.4  ;

      &.very-light:hover {
        cursor: auto;
        filter: brightness(100%);
      }
    }
  }

  .message-locum-button {
    background-color: #808080;

    &.disabled {
      opacity: 0.4;

      &.very-dark:hover {
        cursor: auto;
        filter: brightness(100%);
      }
    }
  }
}