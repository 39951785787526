.validate-identity-section {
  .inputs {
    height: 350px;

    .title {
      font: 700 16px Inter, Helvetica, Arial, sans-serif;
      margin: 10px 0;
    }

    .subtitle {
      font: 400 14px Inter, Helvetica, Arial, sans-serif;
      margin-bottom: 20px;

      @media (max-width: 420px) {
        margin-bottom: 10px;
      }
    }

    .submit-button-container {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 40px 0;

      @media (max-width: 420px) {
        margin: 20px 0 10px 0;
      }

      .submit-button {
        border-radius: 18px;
        background: #55A08D;
        color: #FFFFFF;
        font: 400 15px Inter, Helvetica, Arial, sans-serif;
        width: 150px;
        padding: 8px 10px;
      }
    }

    .go-to-buttons {
      height: 0;
      display: flex;
      width: 100%;

      .go-to-button {
        position: absolute;
        bottom: 30px;
        background: transparent;

        @media (max-width: 600px) {
          bottom: 40px;
        }

        @media (max-width: 480px) {
          bottom: 50px;
        }

        &.reversed {
          left: 25px;
          right: auto;
          bottom: 34px;

          @media (max-width: 600px) {
            bottom: 44px;
          }

          @media (max-width: 480px) {
            bottom: 54.5px;
          }

          @media (max-width: 420px) {
            bottom: 56px;
          }
        }
      }
    }
  }
}