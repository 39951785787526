.find-pabe {
  .shift {

    @media (max-width: 480px) {
      border-width: 2px 0 2px 0;
    }
  }
}

.shift {
  background-color: #FFFFFF;
  border: solid 2px #706F6F;
  display: flex;

  .blurred-map-container {
    display: none;
  }

  @media (max-width: 480px) {
    flex-direction: column;

    .blurred-map-container {
      display: flex;
      align-self: center;
      width: auto;
      margin: 10px 0 30px 0;

      .go-to-map-button {
        width: auto;
        min-width: 300px;
      }
    }
  }

  @media (max-width: 360px) {
    .blurred-map-container {
      .go-to-map-button {
        width: auto;
        min-width: 280px;
      }
    }
  }

  .store {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 35%;
    margin: 20px 0 20px 0;

    .blurred-map-container {
      display: flex;
    }

    @media (max-width: 1100px) {
      width: 35%;
    }

    @media (max-width: 980px) {
      width: 35%;
    }

    @media (max-width: 600px) {
      width: 200px;
    }

    @media (max-width: 480px) {
      flex-direction: row;
      align-items: flex-start;
      margin: 10px 10px 0 10px;
      width: auto;
      justify-content: space-evenly;

      .blurred-map-container {
        display: none;
      }
    }
    
    .back-arrow-container {
      align-self: flex-start;
      margin-left: -10px;
    }

    .business-details {
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 16px;

      @media (max-width: 1250px) {
        font-size: 14px;
      }

      @media (max-width: 980px) {
        font-size: 12px;
      }

      .poster-name, .network-name {
        margin: 10px 0;
        display: flex;

        @media (max-width: 480px) {
          font-size: 14px;
        }

        @media (max-width: 420px) {
          flex-direction: column;
          justify-content: center;
          margin: auto 0;
          width: 100%;
          text-align: center;
        }

        .label {
          margin-right: 5px;

          @media (max-width: 420px) {
            margin-right: 0;
          }
        }
      }

      .view-store {
        background-color: #28446a;
        border-radius: 10px;
        box-shadow: 0 3px 6px 0 rgb(0 0 0 / 16%);
        color: #fff;
        font-size: 14px;
        margin: 8px 0;
        padding: 8px;
        width: 180px;

        @media (max-width: 980px) {
          width: 160px;
        }

        @media (max-width: 420px) {
          margin: 10px 0;
          width: 150px;
        }

        @media (max-width: 360px) {
          width: 120px;
          font-size: 12px;
        }
      }

      .send-message-button {
        width: 180px;
        background-color: #56a08d;
        margin: 8px 0px;
        border-radius: 10px;
        padding: 8px;
        color: #FFFFFF;
        font-size: 14px;
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);

        @media (max-width: 980px) {
          width: 160px;
        }

        @media (max-width: 420px) {
          margin: 10px 0;
          width: 150px;
        }

        @media (max-width: 360px) {
          width: 120px;
          font-size: 12px;
        }

        &.disabled {
          opacity: 0.4;

          &.very-dark:hover {
            cursor: auto;
            filter: brightness(100%)
          }
        }
      }
    }

    .back-and-avatar {
      display: flex;
      flex-direction: column;
    }

    .avatar-container {
      border: solid 2px #56A08D;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 10px 0px 20px 0;

      .avatar {
        width: 240px;
        height: 220px;

        @media (max-width: 1100px) {
          width: 200px;
          height: 180px;
        }

        @media (max-width: 980px) {
          width: 160px;
          height: 140px;
        }

        @media (max-width: 420px) {
          width: 140px;
          height: 120px;
        }
      }

      @media (max-width: 480px) {
        margin: 0 10px 0 0;
      }
    }
  }

  .shift-details {
    width: 65%;
    display: flex;
    flex-direction: column;
    margin: 20px 0;

    @media (max-width: 480px) {
      width: 100%;
      margin: 0 0 10px 0;
    }

    .store-and-total {
      display: flex;
      margin: 0 20px 0 10px;

      @media (max-width: 700px) {
        margin: 0;
      }

      .store-details {
        display: flex;
        flex-direction: column;
        width: 50%;
        padding: 10px 10px 10px 0;

        @media (max-width: 480px) {
          padding: 10px 0;
          width: auto;
          justify-content: center;
          align-items: center;
          text-align: center;
        }
    
        .store-name {
          font-size: 16px;
          font-weight: 900;
          font-family: Arial, Helvetica, sans-serif;
          margin-bottom: 3px;

          @media (max-width: 700px) {
            font-size: 14px;
          }
        }

        .suburb-and-state,
        .date,
        .time,
        .rate,
        .negotiable {
          font-size: 14px;
          margin: 3px 0;

          @media (max-width: 700px) {
            font-size: 12px;
          }
        }

        .negotiable {
          background-color: #808080;
          width: 80px;
          color: #FFFFFF;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 2px 10px;
          border: solid 2px #ECECEC;
        }
      }

      .total-and-buttons {
        width: 50%;
        display: flex;
        flex-direction: column;

        @media (max-width: 480px) {
          width: 60%;
          padding: 10px 0;
        }

        @media (max-width: 420px) {
          width: 70%;
        }

        @media (max-width: 360px) {
          width: 85%;
        }

        .request-message {
          text-align: center;
          
          .link {
            text-decoration: underline;

            &:hover {
              cursor: pointer;
              filter: brightness(70%);
            }
          }
        }

        .total-container {
          font-size: 16px;
          padding: 10px;
          text-align: center;

          .total-display {    
            .total {
              font-size: 16px;
              color: #56A08D;
            }
          }

          @media (max-width: 980px) {
            margin-bottom: 10px;
          }

          @media (max-width: 700px) {
            display: flex;
            align-items: center;
            flex-direction: column;
            padding: 0;

            .total-display {
              margin-top: 5px;
              font-size: 18px;
              
              .total {
                font-size: 18px;
                color: #56A08D;
              }
            }
          }
        }

        .edit-link {
          text-decoration: underline;
          color: -webkit-link;
        }

        .request-button {
          background-color: #28446a;
          border: solid 2px #28446a;
          border-radius: 20px;
          color: #fff;
          font-size: 16px;
          padding: 10px 0px;
          width: 90%;
          margin: 5px auto;

          @media (max-width: 700px) {
            margin: 0 auto;
            font-size: 14px;
          }

          @media (max-width: 600px) {
            font-size: 12px;
          }

          &.disabled {
            opacity: 0.4;

            &.very-light:hover {
              cursor: auto;
              filter: brightness(100%);
            }
          }
  
          &.requested {
            color: #28446a;
            background-color: #FFFFFF;
  
            &.very-light:hover {
              cursor: auto;
              filter: brightness(100%);
            }
          }
        }

        .enter-number-button {
          background-color: #28446a;
          border: solid 2px #28446a;
          border-radius: 20px;
          color: #fff;
          font-size: 16px;
          padding: 10px 0px;
          width: 90%;
          margin: 5px auto;

          @media (max-width: 700px) {
            margin: 10px auto;
          }

          @media (max-width: 600px) {
            font-size: 12px;
          }

          @media (max-width: 500px) {
            font-size: 11px;
          }

          @media (max-width: 480px) {
            font-size: 12px;
          }

          &.disabled {
            opacity: 0.4;

            &.very-light:hover {
              cursor: auto;
              filter: brightness(100%);
            }
          }
        }

        .withdraw-button {
          background-color: #808080;
          border: solid 2px #808080;
          border-radius: 20px;
          color: #fff;
          font-size: 16px;
          padding: 10px 0px;
          width: 90%;
          margin: 5px auto;

          @media (max-width: 720px) {
            font-size: 12px;
          }

          @media (max-width: 600px) {
            font-size: 11px;
          }
        }
      }
    }

    .requirements-and-information {
      display: flex;
      flex-direction: column;
      height: 480px;
      padding-right: 20px;
      overflow-y: auto;
      margin: 20px 20px 0 10px;

      @media (max-width: 1100px) {
        padding-right: 10px;
      }

      @media (max-width: 980px) {
        margin-left: 20px;
        height: 460px;
      }

      @media (max-width: 700px) {
        margin: 0 10px;
      }

      @media (max-width: 480px) {
        height: auto;
        padding-right: 0;
      }

      .requirements {
        display: flex;
        flex-direction: column;
        margin: 10px 0;

        .title {
          font-size: 18px;
        }
        
        .requirement-list {
          width: 100%;

          .requirement {
            border: solid 1px #808080;
            background-color: #E3E1D6;
            padding: 10px;
            text-align: center;
            font-size: 14px;
            font-family: Arial, Helvetica, sans-serif;
            margin: 10px 0;

            &.bold, .bold {
              font-weight: 900;
              font-family: Arial,Helvetica,sans-serif;
            }
          }
        }
      }

      .information-container {
        margin-bottom: 10px;

        @media (max-width: 980px) {
          margin-bottom: 5px;
        }

        .title {
          font-size: 18px;

          @media (max-width: 980px) {
            font-size: 18px;
          }
        }

        .information-list {
          margin-top: 10px;
          width: 100%;

          .information {
            border: solid 1px #808080;
            background-color: #E3E1D6;
            padding: 10px;
            text-align: center;
            font-size: 14px;
            font-family: Arial, Helvetica, sans-serif;
            margin: 10px 0;
            height: auto;
            overflow-wrap: break-word;

            &.bold, .bold {
              font-weight: 900;
              font-family: Arial,Helvetica,sans-serif;
            }
          }
        }
      }
    }
  }
}

.confirmation-modal-container.request-shift {
  .confirmation-modal {
    width: 360px;
    height: 250px;

    @media (max-width: 720px) {
      margin: auto;
      height: 260px;
    }

    @media (max-width: 480px) {
      height: 270px;
      width: 320px;
    }

    @media (max-width: 420px) {
      height: 290px;
      width: 260px;
    }

    @media (max-width: 360px) {
      width: 230px;
    }

    .confirmation-modal-content {
      .message {
        font-size: 14px;
        width: auto;
        height: 70px;
        border: solid 2px #E6E6E6;
        padding: 10px;
        border-radius: 15px;
        margin: 5px 0;
      }

      @media (max-width: 420px) {
        .buttons {
          display: flex;
          justify-content: space-between;
          align-self: normal;

          .cancel, .confirm {
            margin-right: 0;
            font-size: 14px;
            padding: 10px 25px;
          }
        }
      }
    }
  }
}

.confirmation-modal-container.enter-provider-number {
  .confirmation-modal {
    width: 360px;
    height: 360px;

    @media (max-width: 420px) {
      padding: 15px;
      width: 270px;
      height: 450px;
    }

    .confirmation-modal-content {
      .number {
        font-size: 14px;
        width: auto;
        border: solid 2px #E6E6E6;
        padding: 8px 10px;
        border-radius: 15px;
        margin: 10px 0;
      }

      @media (max-width: 480px) {
        .buttons {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;

          .cancel, .confirm {
            font-size: 14px;
            padding: 10px 20px;
          }
        }
      }
    }
  }
}

.confirmation-modal-container.withdraw-shift {
  .confirmation-modal {
    height: 200px;
    width: 340px;

    @media (max-width: 720px) {
      margin: auto;
    }

    @media (max-width: 480px) {
      width: 300px;
      height: 220px;
    }

    @media (max-width: 420px) {
      width: 280px;
      padding: 30px 20px;
    }

    @media (max-width: 360px) {
      width: 260px;
    }

    .confirmation-modal-content {
      @media (max-width: 480px) {
        .buttons {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;

          .cancel, .confirm {
            font-size: 14px;
            padding: 10px 20px;
          }
        }
      }
    }
  }
}

.blurred-map-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 15px 0px 10px 0px;

  @media (max-width: 480px) {
    width: 160px;
    height: 140px;
  }

  &:hover {
    cursor: pointer;
    filter: brightness(70%);
  }

  .go-to-map-button {
    padding: 0;
    height: 200px;
    width: 220px;

    @media (max-width: 1100px) {
      width: 200px;
      height: 180px;
    }

    @media (max-width: 980px) {
      width: 180px;
      height: 180px;
    }

    .blurred-map {
      width: 100%;
      height: 100%;
    }

    &:hover {
      cursor: pointer;

      + .caption-container {
        cursor: pointer;
      }
    }
  }

  .caption-container {
    height: 0;
    position: absolute;
    z-index: 2;

    .caption {
      position: relative;
      top: -15px;
      font-size: 18px;
      background-color: #FFFFFF;
      padding: 5px;
    }
  }
}

.provider-number {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 5px 0;
}