.login-options-container {
  background-color: #FFFFFF;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .login-title {
    font-size: 54px;
    margin: 0 0 40px 0;
    font-weight: 900;

    @media (max-width: 1920px) {
      font-size: 48px;
      margin: 0 0 30px 0;
    }

    @media (max-width: 1440px) {
      font-size: 42px;
      margin: 0 0 20px 0;
    }

    @media (max-width: 1250px) {
      font-size: 38px;
    }

    @media (max-width: 980px) {
      font-size: 36px;
    }

    @media (max-width: 480px) {
      font-size: 32px;
    }

    @media (max-width: 420px) {
      font-size: 28px;
    }
  }

  .login-options {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;

    .login-now-button {
      border-radius: 6px;
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
      color: #FFFFFF;
      font-size: 36px;
      margin: 25px;
      padding: 20px 30px;
      width: 540px;
      text-align: left;
      font-weight: 100;

      @media (max-width: 1920px) {
        font-size: 32px;
        margin: 20px;
        width: 500px;
      }
  
      @media (max-width: 1440px) {
        font-size: 28px;
        margin: 15px;
        width: 420px;
        padding: 18px 25px;
      }
  
      @media (max-width: 1250px) {
        font-size: 24px;
        width: 400px;
        padding: 15px 20px;
      }
  
      @media (max-width: 980px) {
        font-size: 22px;
        padding: 13px 20px;
        width: 320px;
      }
  
      @media (max-width: 480px) {
        font-size: 20px;
        padding: 10px 15px;
        width: 280px;
      }

      @media (max-width: 420px) {
        font-size: 18px;
        padding: 10px 15px;
        width: 260px;
      }

      &.business {
        background-color: #636363;
      }

      &.locum {
        background-color: #55A08D;
      }
    }
  }

  .waitlist {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;

    .waitlist-label {
      font-size: 42px;
      margin: 40px auto;

      @media (max-width: 1920px) {
        font-size: 36px;
        margin: 30px auto;
      }
  
      @media (max-width: 1440px) {
        font-size: 32px;
        margin: 25px auto;
      }
  
      @media (max-width: 1250px) {
        font-size: 28px;
        margin: 20px auto;
      }
  
      @media (max-width: 980px) {
        font-size: 26px;
        margin: 15px auto;
      }
  
      @media (max-width: 480px) {
        font-size: 24px;
      }

      @media (max-width: 420px) {
        font-size: 18px;
      }
    }

    .waitlist-button {
      margin-left: 30px;
      border-radius: 200px;
      padding: 15px 50px;
      background-color: #FFFFFF;
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
      border: solid 2px #55A08D;
      font-size: 32px;

      @media (max-width: 1920px) {
        font-size: 30px;
        padding: 12px 40px;
      }
  
      @media (max-width: 1440px) {
        font-size: 28px;
        margin-left: 15px;
        padding: 10px 35px;
      }
  
      @media (max-width: 1250px) {
        font-size: 24px;
        padding: 8px 30px;
      }
  
      @media (max-width: 980px) {
        font-size: 22px;
      }
  
      @media (max-width: 480px) {
        font-size: 20px;
      }

      @media (max-width: 420px) {
        font-size: 16px;
      }
    }
  }
}