.store-shift-item.permanent {
  display: flex;
  padding: 10px 15px;

  .job-details {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: flex-start;
    width: 330px;
    margin-right: 20px;

    @media (max-width: 360px) {
      margin-right: 10px;
      width: 300px;
    }

    .job-title {
      color: #231F20;
      font: 500 15px Inter, Helvetica, Arial, sans-serif;
    }

    .location-name {
      color: #231F20;
      font: 400 12px Inter, Helvetica, Arial, sans-serif;
      margin: 4px 0;
    }

    .summary-text {
      color: #231F20;
      font: 300 10px Inter, Helvetica, Arial, sans-serif;

      @media (max-width: 360px) {
        font-size: 9px;
      }
    }
  }

  .job-actions {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: flex-end;
    width: 100px;

    .role-type {
      font: 500 13px Inter, Helvetica, Arial, sans-serif;
      font-style: italic;

      @media (max-width: 360px) {
        font-size: 11px;
      }
    }

    .salary-range {
      margin: 4px 0;
      font: 500 13px Inter, Helvetica, Arial, sans-serif;

      @media (max-width: 360px) {
        font-size: 11px;
      }
    }

    .seek-button {
      background: #55A08D;
      border-radius: 50px;
      padding: 6px 10px;
      font: 400 9px Inter, Helvetica, Arial, sans-serif;

      @media (max-width: 360px) {
        font-size: 8px;
      }
    }
  }
}