@font-face {
  font-family: mundo-bold;
  src: url('../../fonts/mundo-bold.otf');
}

.contact-page {
  margin: 30px;
  padding: 30px 0 0 0;

  .title {
    font: 900 32px Arial;
    margin: 0 0 10px 0;
  }

  .subtitle {
    font-size: 18px;
  }

  .contact {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;

    .email {
      display: flex;
      align-items: center;
      box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
      padding: 10px;
      border-radius: 10px;
      background-color: #ecf8f7;
      width: 300px;
      height: 50px;
      margin: 20px 0;

      .email-image {
        height: 30px;
        width: 30px;
        margin-right: 20px;
        padding: 10px;
        box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
        border-radius: 10px;
      }

      .email-details {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-top: 5px;

        .label {
          font-size: 12px;
        }

        .address {
          font-size: 18px;
          font-weight: 900;
        }
      }
    }

    .message-container {
      display: flex;
      box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
      padding: 30px;
      border-radius: 10px;
      background-color: #ecf8f7;
      width: 450px;

      @media (max-width: 480px) {
        width: 100%;
      }

      .message-us {
        display: flex;
        flex-direction: column;
        align-items: center;

        .error-text {
          color: red;
          font-size: 14px;
          min-height: 20px;
          display: flex;
          margin: 5px 0 0 0px;
        }

        .title {
          align-self: flex-start;
        }

        .subtitle {
          align-self: flex-start;
          font-size: 16px;
        }

        .input-name, .input-email, .input-message {
          border: none;
          border-radius: 20px;
          padding: 10px 15px;
          width: 340px;
          margin: 10px 0;
          font-size: 16px;

          @media (max-width: 480px) {
            width: 280px;
          }

          @media (max-width: 360px) {
            width: 240px;
          }
        }

        .input-message {
          height: 100px;
        }

        .submit-button {
          border-radius: 20px;
          padding: 10px 20px;
          width: 380px;
          margin: 10px 0;
          font-size: 18px;
          background-color: #28446A;
          color: #FFFFFF;

          @media (max-width: 480px) {
            width: 300px;
          }

          @media (max-width: 360px) {
            width: 240px;
          }

          &:hover {
            filter: brightness(120%);
          }
        }
      }

      .confirmation {
        text-align: center;
        font-size: 18px;
      }
    }
  }

}