.complete-payments-page {
  display: flex;
  flex-direction: column;
  width: calc(100% - 260px);
  
  @media (max-width: 980px) {
    width: 100%;
  }

  @media (max-width: 480px) {
    align-items: center;
  }
  
  .complete-payments-title {
    font: 400 24px inter-bold;
    margin: 10px 0;
    color: #1a1a1a;
    
    @media (max-width: 480px) {
      text-align: center;
    }
  }
  
  .complete-payments-subtitle {
    color: #1A1A1A;
    margin: 5px 0;
    font-size: 16px;

    @media (max-width: 480px) {
      text-align: center;
    }
  }

  .back-arrow-container {
    margin-left: 0;
  }

  .shift-viewer-container {
    margin: 42px 0px 0 0;
    align-self: flex-start;
    width: calc(100% - 20px);

    .shift-list-viewer {
      height: 400px;
      
      .shift-list {
        height: 100%;
      }
    }

    @media (max-width: 980px) {
      width: calc(100% - 40px);
    }

    @media (max-width: 620px) {
      width: calc(100%);
    }
  }
}

.shift-viewer {
  display: flex;
  flex-direction: column;
  background-color: #E3E1D6;
  justify-content: center;
  align-items: center;
  padding: 20px;
  width: 100%;
  height: 380px;

  .shift-list {
    display: flex;
    width: 100%;
    overflow-y: hidden;
    overflow-x: auto;

    &.no-shifts {
      justify-content: center;
      align-items: center;
      height: 100%;
    }

    .no-shifts-text {
      margin: -20px 0 0 0;
    }
  }
}