$cloudfront-cdn-url: 'https://dq55dzprwc7f6.cloudfront.net';

@keyframes fade-in {
  from {
    background-color: #C9E3DD;
  }
  
  to {
    background-color: white;
  }
}

.search {
  .search-background {
    background-color: #FFFFFF;
    background: #C9E3DD url($cloudfront-cdn-url + '/home/search-background.svg') no-repeat;
    background-size: 100%;
    display: flex;
    flex-direction: column;
    height: 1300px;
    padding: 80px 0 80px 0;
    z-index: 2;
    animation: fade-in 2s ease-in-out forwards;

    @media (max-width: 1250px) {
      padding: 120px 0 80px 0;
    }

    @media (max-width: 980px) {
      background: #C9E3DD url($cloudfront-cdn-url + '/home/mobile-search-background.png') no-repeat;
      background-size: 100%;
      height: auto;
      padding: 80px 0 180px 0;
    }

    @media (max-width: 480px) {
      background: #C9E3DD url($cloudfront-cdn-url + '/home/mobile-search-background-long.png') no-repeat;
      background-size: 100%;
      padding: 80px 0 0 0;
    }

    .header {
      display: flex;
      flex-direction: column;
      margin: 0 auto 0 180px;

      @media (max-width: 1440px) {
        margin: 0 auto 0 80px;
      }

      @media (max-width: 980px) {
        margin: 0 auto;
      }
    }

    .search-title {
      display: flex;
      flex-direction: column;
      font-size: 72px;
      font-family: avenir;
      color: #FFFFFF;
      text-align: left;
      width: 80%;

      .title {
        font-family: avenir;
        
        .italics {
          font-family: avenir;
          font-style: italic;
        }
      }

      @media (max-width: 1920px) {
        font-size: 72px;
        width: 85%;
      }

      @media (max-width: 1440px) {
        font-size: 64px;
        width: 75%;
      }

      @media (max-width: 1250px) {
        font-size: 42px;
        width: 85%;
        margin-top: -40px;
      }

      @media (max-width: 980px) {
        font-size: 48px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: #231F20;
        text-align: center;
        margin: 30px 0 0 0;
        width: 100%;


        .typing {
          justify-content: center !important;
        }
      }

      .sign-up-button {
        margin: 30px 100px auto 0;
        font-family: avenir-bold;
        font-size: 26px;
        padding: 18px 8px;

        &.login-button {
          background-color: #55A08D;
          margin: 10px auto;
        }

        @media (max-width: 1250px) {
          margin: 20px 100px auto 0;
        }

        @media (max-width: 980px) {
          margin: 50px auto 20px auto;
          font-size: 20px;
          padding: 15px 6px;

          &.login-button {
            background-color: #ABD2C8;
          }
        }

        @media (max-width: 480px) {
          margin: 25px 0 15px 0;
          font-size: 16px;
          padding: 12px 6px;
        }
      }

      .typing {
        display: flex;
        justify-content: flex-start;

        .typing-section {
          height: 70px;
          font-family: avenir;
          color: #55A08D;
          font-weight: 900;
        }
      }
    }
  }

  .buttons {
    display: flex;
    width: 100%;
    margin: 20px 0 10px 0;
    align-self: center;
    justify-content: space-evenly;
    position: relative;
    top: 50px;
  }


  .search-bar-container {
    width: 100%;
    margin-top: 270px;

    @media (max-width: 1800px) {
      margin-top: 190px;
    }

    @media (max-width: 1600px) {
      margin-top: 160px;
    }

    @media (max-width: 1440px) {
      margin-top: 110px;
    }

    @media (max-width: 1300px) {
      margin-top: 80px;
    }

    @media (max-width: 1080px) {
      margin-top: 40px;
    }
  }

  .flexible-work {
    margin: 270px auto 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    @media (max-width: 980px) {
      margin: 100px auto 0 auto;
    }

    @media (max-width: 480px) {
      margin: 50px auto 0 auto;
    }

    .title {
      color: #231F20;
      font: 900 35px avenir;
      margin: 0 0 50px 0;

      @media (max-width: 480px) {
        font: 900 28px avenir;
        margin: 0 20px 50px 20px;
      }
    }

    .work-carousel {
      display: flex;
      flex-direction: column;
      align-items: center;

      .carousel-image {
        width: 328px;
        height: 583px;
      }

      @media (max-width: 480px) {
        .carousel-image {
          width: 300px;
          height: 500px;
        }
      }

      .triple-dots {
        margin-top: 30px;
        display: flex;
        width: 100px;
        justify-content: space-evenly;
      }
    }
  }
}

@keyframes blink {
  from, to {
    color: transparent;
  }
  50% {
    color: #55A08D;
  }
}

.blinking-cursor {
  font-weight: 100;
  font-size: 64px;
  color: #55A08D;
  animation: 1s blink step-end infinite;

  @media (max-width: 620px) {
    font-size: 34px;
  }

  @media (max-width: 480px) {
    font-size: 28px;
  }
  @media (max-width: 1100px) {
    font-size: 52px;
  }

  @media (max-width: 880px) {
    font-size: 48px;
  }

  @media (max-width: 620px) {
    font-size: 42px;
  }

  @media (max-width: 480px) {
    font-size: 36px;
  }

  @media (max-width: 420px) {
    font-size: 34px;
  }

  @media (max-width: 360px) {
    font-size: 30px;
  }
}

.type-button {
  background-color: #ABD2C8;
  color: #FFFFFF;
  font: 900 18px avenir;
  width: 160px;
  border-radius: 50px;
  padding: 8px 10px;
}

.sign-up-button {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #55A08D;
  color: #FFFFfF;
  line-height: 17px;
  padding: 15px 0 13px 0;
  width: 360px;
  padding: 21px 0 19px 0;
  border-radius: 200px;
  letter-spacing: 0.04em;
  font: 900 32px Inter, mundo-bold;

  &:hover {
    opacity: 0.8;
  }

  @media (max-width: 1920px) {
    width: 300px;
    font-size: 28px;
    padding: 19px 0 17px 0;
  }

  @media (max-width: 1440px) {
    width: 250px;
    font-size: 24px;
    padding: 16px 0 14px 0;
  }

  @media (max-width: 1250px) {
    width: 220px;
    font-size: 22px;
    padding: 15px 0 13px 0;
  }

  @media (max-width: 980px) {
    width: 180px;
    font-size: 16px;
  }

  @media (max-width: 880px) {
    width: 160px;
    font-size: 18px;
    padding: 13px 0 11px 0;
  }

  @media (max-width: 600px) {
    margin-top: 20px;
  }

  @media (max-width: 480px) {
    padding: 11px 0 9px 0;
    font-size: 16px;
  }
}