.store-review-quote {
  background: #F6F5F5;
  padding: 25px 20px;
  width: 300px;
  height: 260px;
  margin: 10px 0 15px 0;
  border-radius: 10px;
  display: flex;
  flex-flow: column;

  @media (max-width: 480px) {
    width: 260px;
    height: 220px;
  }

  .review-title {
    margin: 20px 0;
    display: flex;
    align-items: center;
    justify-content: center;

    .recommend-icon {
      width: 50px;
      height: 50px;
      margin-right: 10px;

      @media (max-width: 480px) {
        width: 45px;
        height: 45px;
      }
    }

    .title {
      font: 700 15px Inter, Helvetica, Arial, sans-serif;

      @media (max-width: 480px) {
        font-size: 14px;
      }
    }
  }

  .pros-text, .cons-text {
    display: flex;
    flex-flow: column;
    align-items: center;
    margin: 15px 0;

    .title {
      font: 700 15px Inter, Helvetica, Arial, sans-serif;
    }

    .text {
      font: 400 13px Inter, Helvetica, Arial, sans-serif;
    }
  }
}
