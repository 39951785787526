.subscribe-to-store {
  margin-top: 25px;
  display: flex;
  flex-flow: column;

  @media (max-width: 480px) {
    margin-top: 15px;
  }

  .checkboxes {
    margin-top: 10px;

    @media (max-width: 480px) {
      margin-top: 5px;
    }
  }

  .subscribe-button {
    align-self: flex-end;
    background: #55A08D;
    width: 150px;
    padding: 10px 0;
    border-radius: 18px;
    font: 600 15px Inter, Helvetica, Arial, sans-serif;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;

    @media (max-width: 480px) {
      width: 120px;
      font-size: 14px;
    }
  }
}