.invoice-card.business {
  background-color: #fff;
  border: 1px solid rgba(0,0,0,.125);
  border-radius: 5px;
  color: gray;
  display: flex;
  flex-direction: column;
  font-size: 11px;
  line-height: 19px;
  margin: 0px 15px;
  min-width: 230px;
  max-width: 230px;
  padding: 15px 25px;

  .status {
    padding: 0 10px;
    font-size: 11px;
    border-radius: 8px;
    width: 100px;
    height: 30px;
    line-height: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 0 3px auto;
    font-weight: 900;
    font-family: Helvetica, Arial, sans-serif;

    .subtitle {
      font-style: italic;
      font-size: 10px;
      font-weight: 400;
    }

    &.invoice {
      background-color: #FCC938;
      color: #000000;
      font-size: 11px;
    }

    &.disputed {
      background-color: #d40700;
      color: #FFFFFF;
    }

    &.completed {
      background-color: #000000;
      color: #FFFFFF;
    }
  }

  .store-name {
    font-size: 12px;
    font-weight: 600;
    color: #000000;
    margin-bottom: 5px;
    height: 60px;
  }

  .shift-date, .shift-time, .shift-location, .shift-worker {
    font-size: 11px;
  }

  .shift-date,
  .shift-time,
  .shift-location,
  .shift-total,
  .shift-rate {
    color: #808080;

    .shift-icon {
      width: 12px;
      height: 14px;
      margin-right: 6px;
      position: relative;
      top: 3px;
    }
  }

  .shift-rate {
    color: #000000;
  }

  .invoice-buttons {
    display: flex;
    flex-direction: column;
    height: 100px;
    margin-top: 5px;

    .download-invoice-button {
      background-color: #FCC938;
      border-radius: 5px;
      box-shadow: none;
      color: #000000;
      font-size: 12px;
      margin-bottom: 8px;
      padding: 5px 5px;
    }

    .report-issue-button {
      background-color: #56a08d;
      border-radius: 5px;
      box-shadow: none;
      color: #fff;
      font-size: 12px;
      margin-bottom: 8px;
      padding: 5px 5px;
    }

    .message-locum-button {
      background-color: #28446A;
      border-radius: 5px;
      box-shadow: none;
      color: #fff;
      font-size: 12px;
      margin-bottom: 8px;
      padding: 5px 5px;
    }

    .view-shift-button {
      background-color: #808080;
      border-radius: 5px;
      box-shadow: none;
      color: #fff;
      font-size: 12px;
      margin-bottom: 8px;
      padding: 5px 5px;
    }
  }
}

.confirmation-modal-container.report-shift {
  .confirmation-modal {
    width: 360px;
    height: 320px;

    @media (max-width: 720px) {
      margin: auto;
    }

    @media (max-width: 480px) {
      height: 340px;
      width: 320px;
    }

    @media (max-width: 420px) {
      height: 400px;
      width: 260px;
    }

    @media (max-width: 360px) {
      width: 230px;
      height: 420px;
    }

    .confirmation-modal-content {
      .message {
        font-size: 14px;
        width: auto;
        height: 100px;
        border: solid 2px #E6E6E6;
        padding: 10px;
        border-radius: 15px;
        margin: 5px 0;

        @media (max-width: 480px) {
          height: 120px;
        }

        @media (max-width: 420px) {
          height: 140px;
        }

        @media (max-width: 360px) {
          height: 150px;
        }
      }

      @media (max-width: 420px) {
        .buttons {
          display: flex;
          justify-content: space-between;
          align-self: normal;

          .cancel, .confirm {
            margin-right: 0;
            font-size: 14px;
            padding: 10px 25px;
          }
        }
      }
    }
  }
}