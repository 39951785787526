.store-profile-modal-container {
  position: fixed;
  z-index: 9999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%; 
  overflow: auto;
  background-color: rgba(0,0,0,0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000000;
  white-space: normal;

  .store-profile-modal {
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), inset 0px 4px 4px rgba(0, 0, 0, 0.02);
    border-radius: 15px;
    display: flex;
    flex-flow: column;
    padding: 10px;
    width: 420px;
    height: 220px;
    justify-content: center;

    @media (max-width: 600px) {
      width: 360px;
    }

    @media (max-width: 480px) {
      width: 330px;
    }

    @media (max-width: 420px) {
      width: 300px;
      height: 200px;
    }

    @media (max-width: 360px) {
      width: 270px;
    }

    .close-modal-button-container {
      height: 0;
      align-self: flex-end;

      .close-modal-button {
        font-size: 24px;
        position: relative;
        right: 5px;
        top: -5px;
      }
    }

    .modal-content {
      display: flex;
      flex-flow: column;
      align-items: center;
      text-align: center;
      width: calc(100% - 40px);
      height: calc(100% - 40px);
      padding: 20px;
      justify-content: center;

      .text {
        font: 600 24px Inter, Helvetica, Arial, sans-serif;
        color: #55A08D;
        margin: 20px 0 30px 0;

        @media (max-width: 600px) {
          font-size: 22px;
        }

        @media (max-width: 480px) {
          margin: 10px 0 20px 0;
          font-size: 20px;
        }

        @media (max-width: 360px) {
          font-size: 18px;
        }
      }

      .description {
        font: 400 18px Inter, Helvetica, Arial, sans-serif;
        color: #231F20;
        line-height: 25px;

        @media (max-width: 600px) {
          font-size: 16px;
          line-height: 23px;
        }

        @media (max-width: 480px) {
          font-size: 15px;
          line-height: 21px;
        }

        @media (max-width: 360px) {
          font-size: 14px;
          line-height: 19px;
        }
      }
    }
  }
}