.store-card-navigation-button {
  display: flex;
  align-items: flex-start;
  height: 40px;
  background-color: #E3E1D6;
  font-size: 12px;

  .navigation-icon {
    width: 30px;
    height: 30px;
  }

  .button-text {
    margin: auto 0 auto 10px;
  }
}