.confirmation-modal-container {
  position: fixed;
  z-index: 9999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%; 
  overflow: auto;
  background-color: rgba(0,0,0,0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000000;

  a, .link {
    text-decoration: underline;
    color: -webkit-link;
  }

  .confirmation-modal {
    margin: auto;
    background-color: #FFFFFF;
    width: 400px;
    height: 180px;
    display: flex;
    flex-direction: column;
    border-radius: 20px;
    padding: 30px;
    border: solid 4px #FCC938;

    .confirmation-modal-content {
      display: flex;
      flex-direction: column;

      .error-text {
        color: red;
        font-size: 14px;
        min-height: 20px;
        display: flex;
        margin: 10px 0 0 0px;
        justify-content: center;
      }

      .confirmation-title {
        font-weight: 600;
        font-size: 18px;
        font-family: Arial, Helvetica, sans-serif;
        margin: -10px 0 10px 0;
      }

      .confirmation-text {
        margin: 10px 0;
        font-size: 16px;
        font-family: Arial, Helvetica, sans-serif;
        white-space: pre-line;
        overflow-wrap: break-word;

        b {
          font-weight: 900;
          font-family: Arial, Helvetica, sans-serif;
          white-space: pre-line;
          overflow-wrap: break-word;
        }
      }

      .buttons {
        align-self: flex-end;

        .cancel, .confirm {
          font-size: 16px;
          padding: 10px 35px;
          margin-top: 10px;
        }

        .cancel {
          margin-right: 20px;
          background-color: #808080;
          color: #FFFFFF;
          border-radius: 20px;
        }

        .confirm {
          background-color: #28446A;
          color: #FFFFFF;
          border-radius: 20px;

          &.disabled {
            opacity: 0.4;

            &:hover {
              filter: brightness(100%);
            }
          }

          &:hover {
            filter: brightness(125%);
          }
        }
      }
    }
  }
  
}