.schedule-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 60px;

  .schedule-banner {
    display: flex;
    flex-direction: column;
    border-radius: 30px;
    width: 100%;
    padding: 25px;
    box-sizing: border-box;

    color: #FFFFFF;
    background-color: #55A08D;

    .title-name {
      font-weight: 900;
      margin-bottom: 20px;
    }

    .title-confirmed {

    }

    .title-requested {

    }
  }

  .date-container {
    .react-datepicker {
      border: none;

      .react-datepicker__month-container {
        width: 100vw;
        float: none;

        .react-datepicker__header {
          background-color: #FFFFFF;
          border-bottom: none;

          .react-datepicker__day-names {
            display: flex;
            justify-content: space-around;
          }
  
          .header-container-top {
            display: flex;
            justify-content: flex-end;
            align-items: center;
  
            .react-datepicker__navigation {
              position: relative;
              height: auto;
              z-index: 0;

              .react-datepicker__navigation-icon:before {
                border-color: #000000;
                border-width: 1px 1px 0 0;
                height: 6px;
                width: 6px;
                top: 8px;
              }
            }

            .react-datepicker__current-month {
              font-weight: 500;
              text-align: end;
              font-size: 12px;
            }
          }
        }

        .react-datepicker__month {
          margin: 0.4rem 0;
          
          .react-datepicker__week {
            display: flex;
            justify-content: space-around;
  
            .react-datepicker__day {
              color: #CAE3DD;
              pointer-events: none;
              cursor: default;
              font-size: 16px;
              border: 2px solid transparent;
              background-color: transparent;

              &.react-datepicker__day--outside-month {
                visibility: hidden;
              }

              &.react-datepicker__day--keyboard-selected {
                background-color: transparent;
              }

              &.react-datepicker__day--today {
                color: #55A08D;
                font-weight: 500;
              }

              &.react-datepicker__day--highlighted-confirmed {
                border: 2px solid #55A08D;
                border-radius: 10px;
              }
              
              &.react-datepicker__day--highlighted-requested {
                border: 2px solid #959493;
                border-radius: 10px;
              }
              
              &.react-datepicker__day--highlighted-pending-pay {
                border: 2px solid #EFCB68;
                border-radius: 10px;
              }

              &.react-datepicker__day--highlighted-paid {
                border: 2px solid #55A08D;
                border-radius: 10px;
              }
            }
          }
        }
      }
    }
  }

  .month-view {
    background-color: #55A08D;
    position: fixed;
    bottom: 0px;
    color: #FFFFFF;
    width: 100%;
    border-radius: 30px 30px 0px 0px;
    transition: bottom 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955), visibility 0.2s !important;

    @media (max-width: 480px) {
      bottom: 60px;
    }

    &.view-transform {
      bottom: 0 !important;
    }

    &.hide-view {
      height: 45px !important;
    }

    .no-upcoming {
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 16px;

      .find-button {
        background-color: transparent;
        font-size: 16px;
        color: #FFFFFF;
        font-weight: 900;
      }
    }

    .header-bar {
      display: flex;
      justify-content: center;
      width: 100%;
      margin-top: 10px;
      cursor: pointer;

      .bar-img {
        height: 10px;
      }
    }

    .shift-container {
      font-size: 12px;
      margin-left: 5%;
      height: calc(100% - 46px);
      padding: 25px 0 20px;
      overflow-y: scroll;

      &.no-shifts {
        margin-left: 0;
        margin-top: 30px;
      }

      .day-container {
        padding: 5px 0;
        &:last-of-type {
          margin-bottom: 20px;
        }
        .shift-day {
          width: 100%;
          margin: 26px 0;
          border-bottom: 1px solid #FFFFFF;
          line-height: 0.1em;

          .shift-day-div {
            font-size: 14px;
            padding-right: 10px;
            font-weight: 600;
            background-color: #55A08D;
          }
        }

        .day-info {
          width: 95%;

          .shift-details {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin: 5px 0;
            position: relative;

            .manage-shift-modal {
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              position: absolute;
              bottom: 20px;
              right: 10px;
              z-index: 1;
  
              .manage-shift-container {
                display: flex;
                justify-content: space-around;
                align-items: flex-start;
                flex-direction: column;
                border-radius: 6px;
                background-color: #FFFFFF;
                padding: 10px;
                box-shadow: 0px 6.50602px 7.80723px rgba(0, 0, 0, 0.12);

                .enter-number-button {
                  font-size: 12px;
                  margin: 5px;
                }
  
                .withdraw-button {
                  font-size: 12px;
                  margin: 5px;
                }
  
                .complete-button {
                  font-size: 12px;
                  margin: 5px;
  
                }
  
                .report-issue-button {
                  font-size: 12px;
                  margin: 5px;
                }
  
                .download-invoice-button {
                  font-size: 12px;
                  margin: 5px;
                }
  
                .message-store {
                  font-size: 12px;
                  margin: 5px;
                }
              }
            }

            .edit-shift {
              cursor: pointer;
              filter: brightness(10);

              @media (max-width: 380px) {
                height: 15px;
              }
            }

            .status-container {
              display: flex;
              align-items: center;

              .status-icon {
                margin-right: 3px;
              }

              .status-text {
                font-size: 10px;

                @media (max-width: 380px) {
                  font-size: 8px;
                }
              }
            }

            .day-time {
              display: flex;
              flex-direction: column;

              @media (max-width: 380px) {
                font-size: 10px;
              }
            }

            .store-details {
              width: 160px;

              @media (max-width: 380px) {
                width: 140px;
                font-size: 10px;
              }

              .store-name {
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
  
                .store-link {
                  color: #FFFFFF;
                }
              }
  
              .store-address {
                font-weight: 200;
              }
            }
          }
        }
      }
    }
  }
}