.yes-no-recommendation-buttons {
  margin: 5px 0 0 0;
  display: flex;
  width: 90px;
  justify-content: space-between;

  .recommend-button {
    background: transparent;

    .recommend-icon {
      width: 40px;
    }

    .recommend-icon.reverse {
      transform: rotate(180deg);
    }
  }
}