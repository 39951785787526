.stores-page {
  background-color: #FFFFFF;
  margin-bottom: 60px;
  padding: 30px 0 0 0;
  display: flex;
  flex-direction: column;
  font-size: 18px;
  margin-left: 20px;

  @media (max-width: 480px) {
    margin: 40px 15px;
  }

  .stores-introduction {
    font-size: 28px;
    font-weight: 900;
    height: auto;
  }

  .stores-subtitle {
    margin: 10px 0 20px 0;
  }

  .store-list {
    display: flex;
    overflow-x: auto;
    min-height: 250px;
    padding-bottom: 20px;
  }

  .add-stores-subtitle {
    align-self: center;
    margin-top: 40px;
    text-align: center;

    @media (max-width: 480px) {
      margin-top: 20px;
    }
  }

  .add-another-store-button {
    width: 220px;
    margin: 20px auto 20px auto;
    padding: 8px 0px;
    border-radius: 8px;
    font-size: 18px;
    color: #FFFFFF;
    background-color: #56A08D;
    text-align: center;
  }
}


.filter-search-container {
  display: flex;
  margin: 5px 0 25px 0;

  .filter-search-input {
    border: 1px solid #000000;
    border-radius: 5px;
    padding: 15px 5px;
    width: 440px;
    font-size: 16px;

    @media (max-width: 480px) {
      width: 400px;
      font-size: 14px;
    }

    @media (max-width: 360px) {
      width: 300px;
      font-size: 14px;
    }
  }
}
