.dropdown-multiselect-option {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 10px 10px;

  @media (max-width: 480px) {
    padding: 6px 5px;
  }

  .dropdown-checkbox {
    transform: scale(1.3);
    margin-right: 20px;

    @media (max-width: 480px) {
      transform: scale(1);
      margin-right: 10px;
    }
  }

  .dropdown-checkbox-label {
    font: 400 16px Inter, Helvetica, Arial, sans-serif;

    @media (max-width: 480px) {
      font-size: 14px;
    }

    @media (max-width: 420px) {
      font-size: 12px;
    }
  }
}