.shift-card-container {
  list-style: none;
  display: flex;
  flex-direction: column;
  background-color: #FFFFFF;
  width: 100%;
  box-shadow: inset 0px 1px 10px rgba(0, 0, 0, 0.12);
  border-radius: 15px;
  padding: 30px 10px 15px;
  box-sizing: border-box;
  cursor: pointer;
  position: relative;

  @media (max-width: 1080px) {
    padding: 30px 10px 15px;
  }

  @media(max-width: 720px) {
    padding: 30px 10px 15px;
  }

  @media(max-width: 480px) {
    margin: 8px 0;
  }

  .shift-post-date {
    position: absolute;
    left: 0;
    top: 15px;
    font-size: 8px;
    font-weight: 900;
    padding: 3px 12px;

    @media (max-width: 480px) {
      top: 0px;
      left: 12px;
      padding: 2px 8px;
    }

    &.new-shift {
      background-color: #CBE4DE;
    }

    &.old-shift {
      background-color: #E5E5E5;
    }

    &.store-shift {
      top: 0px;
      left: 12px;
    }
  }

  .shift-container {
    box-sizing: border-box;
    padding: 4px;

    @media (max-width: 465px) {
      font-size: 12px;
    }

    @media (max-width: 365px) {
      font-size: 10px;
    }
    
    .title-container {
      display: grid;
      grid-template-columns: minmax(0, 1fr) max-content;
      grid-template-rows: unset;
      gap: 2px;
    }

    .address-container {
      display: grid;
      grid-template-columns: minmax(0, 1fr) max-content;
      grid-template-rows: unset;
      gap: 2px;

      .shift-rate {
        grid-row: 1;
        display: flex;
        justify-content: flex-end;

        @media (max-width: 360px) {
          font-size: 12px;
        }
      }
  
      .shift-nego {
        grid-row: 2;
        grid-column: 2;
        font-size: 12px;

        @media (max-width: 435px) {
          font-size: 10px;
        }
      }
  
      .shift-address {
        grid-row: 1;
        display: flex;
        color: #55A08D;
      }
    }

    .shift-title {
      font-size: 18px;
      font-weight: 800;
      grid-row: 1;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;

      @media (max-width: 435px) {
        font-size: 16px;
      }

      @media (max-width: 360px) {
        font-size: 14px;
      }
    }

    .shift-distance {
      grid-row: 1;
      color: #55A08D;
      font-size: 14px;
      display: flex;
      align-items: flex-end;

      @media (max-width: 435px) {
        font-size: 12px;
      }
    }

    .shift-details {
      display: grid;
      grid-template-columns: minmax(0, 1fr) max-content;
      grid-template-rows: unset;
      gap: 2px;
      font-size: 14px;

      @media (max-width: 435px) {
        font-size: 12px;
      }

      @media (max-width: 360px) {
        font-size: 10px;
      }

      .right-section {
        grid-column: -2 / -1;
        justify-self: end;
      }
  
      .shift-date {
        grid-row: 4;
        font-size: 12px;

        @media (max-width: 435px) {
          font-size: 10px;
        }
      }
  
      .shift-time {
        grid-row: 5;
        font-size: 12px;

        @media (max-width: 435px) {
          font-size: 10px;
        }
      }
  
      .shift-buttons {
        grid-row: 4 / 6;
        display: flex;
        align-items: flex-end;
      }
  
      .shift-button {
        background-color: #56a08d;
        padding: 5px 15px;
        justify-self: center;
        box-sizing: border-box;
        border-radius: 15px;
        color: #FFFFFF;
  
        @media (max-width: 450px) {
          font-size: 12px;
        }

        @media (max-width: 390px) {
          font-size: 10px;
          padding: 4px 12px;
        }

        @media (max-width: 330px) {
          font-size: 9px;
          padding: 3px 9px;
        }
      }
  
      .add-to-cart {
        background-color: #EFCB68;
        margin-left: 5px;
      }
    }
  }

  .web-shift-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    justify-content: space-between;

    @media (max-width: 720px) {
      margin-top: 15px;
    }
    
    .title-container {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      margin: 10px 0;

      @media (max-width: 720px) {
        margin: 5px 0;
      }

      .shift-title {
        font-size: 16px;
        text-align: center;

        @media (max-width: 720px) {
          font-size: 14px;
        }
      }
      
      .shift-address {
        color: #55A08D;
        font-size: 12px;

        @media (max-width: 720px) {
          font-size: 10px;
        }
      }
    }

    .shift-date-container {
      margin: 15px 0;
      width: 100%;

      @media (max-width: 1440px) {
        font-size: 12px;
        margin: 5px 0;
      }

      @media (max-width: 720px) {
        font-size: 12px;
        margin: 10px 0;
      }

      .shift-date {
        font-weight: 300;
      }
    }

    .shift-distance {
      margin: 5px 0;
      color: #55A08D;
      width: 100%;
      font-size: 14px;

      @media (max-width: 720px) {
        font-size: 12px;
        margin: 2px 0;
      }
    }

    .shift-rate-container {
      margin: 10px 0;
      width: 100%;
      font-size: 14px;

      @media (max-width: 720px) {
        font-size: 12px;
        margin: 5px 0;
      }

      .shift-nego {
        font-size: 12px;
        font-weight: 300;
        font-style: italic;

        @media (max-width: 720px) {
          font-size: 10px;
        }
      }
    }

    .shift-buttons {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      width: 100%;

      @media (max-width: 520px) {
        flex-direction: column;
      }

      &.requested {
        justify-content: center;
      }
    }

    .shift-button {
      background-color: #56a08d;
      padding: 5px 15px;
      justify-self: center;
      box-sizing: border-box;
      border-radius: 15px;
      color: #FFFFFF;
      margin: 5px 0;

      @media (max-width: 1440px) {
        font-size: 12px;
      }

      @media (max-width: 1020px) {
        padding: 5px 10px;
      }

      @media (max-width: 520px) {
        width: 100%;
        margin: 2px 0;
      }
    }

    .add-to-cart {
      background-color: #EFCB68;
    }
  }
}