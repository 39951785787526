.review-applicants-page {
  display: flex;
  flex-direction: column;
  width: calc(100% - 260px);

  @media (max-width: 980px) {
    width: 100%;
  }

  @media (max-width: 480px) {
    align-items: center;
  }
  
  .review-applicants-title {
    font: 400 24px inter-bold;
    margin: 10px 0;
    color: #1a1a1a;

    @media (max-width: 480px) {
      text-align: center;
    }
  }
  
  .review-applicants-subtitle {
    color: #1A1A1A;
    margin: 5px 0;
    font-size: 16px;

    @media (max-width: 480px) {
      text-align: center;
    }
  }

  .back-arrow-container {
    align-self: flex-start;
    margin-left: -5px;

    .back-text {
      margin-top: 4px;
    }
  }

  .shift-viewer-container {
    margin: 42px 30px 0 0;


    @media (max-width: 620px) {
      margin: 42px 0 0 0;
    }

    @media (max-width: 480px) {
      width: calc(100% - 10px);
    }
  }
}

.applicant-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 320px;
  min-width: 200px;
  margin: 0px 15px;
  border-radius: 0px;
  border: solid 2px #808080;
  padding: 10px 20px 10px 20px;
  font-size: 12px;
  background-color: #FFFFFF;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  .applicant-details {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .applicant-avatar {
      width: 120px;
      height: 120px;
      border-radius: 120px;
      margin: 10px auto 5px 10px;
    }

    .applicant-name {
      font-size: 18px;
      font-weight: 900;
      margin: 5px auto;
    }

    .shifts-completed {
      font-size: 16px;
      color: #808080;
    }
  }

  .buttons-container {
    display: flex;
    flex-direction: column;
    width: 100%;

    .applicant-button {
      padding: 5px 0px;
      margin: 4px 0;
      font-size: 14px;
      font-weight: 600;
      border-radius: 6px;

      &.view-locum-button {
        background-color: #AACFC6;
        color: #212529;
      }

      &.message-locum-button {
        background-color: #808080;
        color: #FFFFFF;
      }

      &.confirm-locum-button {
        background-color: #28446A;
        color: #FFFFFF;
      }
    }
  }
}

.applicants-viewer-container {
  margin: 42px 30px 0 0;

  @media (max-width: 620px) {
    margin: 42px 0 0 0;
  }

  @media (max-width: 480px) {
    width: calc(100% - 10px);
  }

  .applicants-viewer {
    display: flex;
    flex-direction: column;
    background-color: #E3E1D6;
    justify-content: center;
    align-items: center;
    padding: 20px;
    width: 100%;
    height: 350px;
    
    @media (max-width: 620px) {
      padding: 20px 0;
    }

    .applicant-list {
      display: flex;
      flex-direction: row;
      width: 100%;
      overflow-y: hidden;
      overflow-x: auto;
    }
  }
}

.applicant-container {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  width: 100%;
}

.confirm-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .party-popper-image {
    margin: 0px;
    width: 360px;
    height: 360px;
  }
  
  .party-popper-text {
    margin: 10px;
    font-size: 22px;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 900;
  }

  .notify-text {
    margin: 10px;
    font-size: 16px;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 600;
    color: #808080;
  }

  .review-another-shift {
    font-size: 16px;
    margin: 10px;
    background-color: #28446A;
    color: #FFFFFF;
    padding: 10px 50px;
    border-radius: 20px;

    &:hover {
      filter: brightness(125%);
    }
  }
}

.confirmation-modal-container.confirm-locum {
  .confirmation-modal {
    height: 220px;

    @media (max-width: 620px) {
      width: 360px;
    }

    @media (max-width: 480px) {
      width: 300px;
      height: 260px;
    }

    @media (max-width: 420px) {
      height: 320px;
      width: 230px;
    }
  
    .confirmation-modal-content {
      .buttons {
        margin-top: 5px;

        .cancel, .confirm {
          @media (max-width: 420px) {
            font-size: 14px;
            padding: 10px 20px;
          }
        }
      }
    }
  }
}