.rating-star-button {
  margin: 0 7px;

  @media (max-width: 600px) {
    margin: 0 4px;

    .star {
      width: 32px;
      height: 32px;
    }
  }

  @media (max-width: 420px) {
    margin: 0 3px;

    .star {
      width: 30px;
      height: 30px;
    }
  }

  @media (max-width: 360px) {
    .star {
      width: 25px;
      height: 25px;
    }
  }
}