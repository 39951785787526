.store-dropdown {
  display: flex;
  margin: 5px 0 15px 0;

  .store-selector {
    border: solid 1px #808080;
    background-color: #FFFFFF;
    color: #56A08D;
    font-size: 13px;
    width: 224px;
    border-radius: 6px;
    text-align: left;
    padding: 8px 5px 8px 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .store-text {
      width: 190px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    .dropdown-arrow {
      width: 18px;
      height: 18px;
      position: relative;
      top: -1px;

      &.open {
        transform: rotate(180deg);
      }
    }

    &:hover {
      cursor: pointer;
      filter: brightness(95%);
    }

    &.disabled {
      color: #808080;

      &:hover {
        cursor: auto;
        filter: brightness(100%);
      }
    }
  }

  .store-selection-container {
    position: absolute;
    height: 0;

    .store-list {
      display: flex;
      flex-direction: column;
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
      width: 222px;
      background-color: #FFFFFF;
      position: relative;
      top: 36px;
      z-index: 4;
      max-height: 360px;
      overflow-y: auto;

      .store-item {
        .store-selection-button {
          background-color: #FFFFFF;
          border: none;
          width: 100%;
          color: rgb(64, 64, 64);
          text-align: left;
          padding: 10px;
          border-radius: 0;
          font-size: 13px;

          &:hover {
            cursor: pointer;
            filter: brightness(95%);
          }
        }
      }
    }
  }
}