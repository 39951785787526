.complete-shift-modal-container {
  position: fixed;
  z-index: 9999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%; 
  overflow: auto;
  background-color: rgba(0,0,0,0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000000;
  white-space: normal;

  .close-button-container {
    height: 0;

    .close-button {
      float: right;
      position: relative;
      top: -27px;
      right: -20px;
      font-size: 22px;
      background-color: transparent;

      &:hover {
        cursor: pointer;
        opacity: 0.6;
      }
    }
  } 

  .complete-shift-modal {
    margin: auto;
    background-color: #FFFFFF;
    width: 550px;
    height: 360px;
    display: flex;
    flex-direction: column;
    border-radius: 20px;
    padding: 30px;

    @media (max-width: 700px) {
      width: 420px;
      padding: 30px;
      height: 420px;
    }

    @media (max-width: 480px) {
      width: 300px;
      padding: 30px;
    }

    @media (max-width: 420px) {
      width: 250px;
      padding: 30px;
      height: 450px;
    }

    @media (max-width: 360px) {
      width: 220px;
      padding: 25px;
    }

    .modal-header {
      text-align: center;
      font-size: 26px;

      @media (max-width: 700px) {
        font-size: 18px;
      }
    }

    .modal-subtitle {
      text-align: center;
      font-size: 14px;
      margin-bottom: 10px;

      @media (max-width: 700px) {
        font-size: 12px;
      }
      
      &.above {
        margin-top: 10px;
      }
    }

    .modal-content {
      min-height: 268px;
      max-height: 268px;

      @media (max-width: 700px) {
        min-height: 330px;
        max-height: 330px;
      }

      @media (max-width: 480px) {
        max-height: 360px;
        min-height: 360px;
      }

      @media (max-width: 420px) {
        max-height: 380px;
        min-height: 380px;
      }
    }

    .continue-button {
      background-color: #56A08D;
      color: #FFFFFF;
      padding: 8px 40px;
      width: 150px;
      margin: 4px 0;
      font-size: 14px;
      border-radius: 6px;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      align-self: center;
      margin-top: 20px;

      &:hover {
        cursor: pointer;
      }

      &.loading {
        &:hover {
          cursor: auto;
        }
      }

      &.submit {
        background-color: #28446A;
      }

      &.back {
        background-color: #504f4f; 
        margin: 4px 0;
      }
    }

    .generate-invoice-selection {
      display: flex;
      flex-direction: column;
      align-items: center;

      .selection-options {
        display: flex;
        flex-direction: column;
        margin: 0;
      }
    }
  }
}

.selection-option {
  background-color: #F2F3F3;
  margin: 10px 0 5px 0;
  padding: 10px;
  height: 80px;
  color: #818C8D;
  font-size: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;

  @media (max-width: 420px) {
    height: 110px;
  }
  
  @media (max-width: 700px) {
    font-size: 13px;
  }

  .selection-checkbox {
    margin: 5px 10px 5px 10px;
    background-color: white;
    border-radius: 50%;
    vertical-align: middle;
    border: 1px solid #ddd;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    &:hover {
      cursor: pointer;
    }

    filter: brightness(95%);

    .rounded-checkbox {
      filter: brightness(100%);
    }
  }

  &:hover {
    filter: brightness(95%);
    cursor: pointer;
  }

  .selection-label {
    text-align: left;
  }
}

.store-review-selection, .store-enjoyment-selection {
  display: flex;
  flex-direction: column;

  .selection-options {
    margin-bottom: 5px;

    .selection-option {
      width: 100%;
      text-align: left;
      justify-content: flex-start;
      height: 40px;

      .selection-label {
        font-size: 18px;

        @media (max-width: 700px) {
          font-size: 16px;
        }

        @media (max-width: 420px) {
          font-size: 13px;
        }
      }
    }
  }
}

.feedback-selection {
  .feedback-container {
    background-color: #F2F3F3;
    padding: 5px 10px;
    margin-top: 10px;

    .feedback-text {
      width: 100%;
      height: 140px;
      background-color: #F2F3F3;
      color: #000000;
      border: none;
      resize: none;
      outline: none;

      @media (max-width: 480px) {
        height: 200px;
      }

      @media (max-width: 360px) {
        height: 180px;
      }
    }
  }
}


.rounded-checkbox {
  position: relative;
  margin: 10px;

  label {
    background-color: #fff;
    border: 2px solid #818C8D;
    border-radius: 50%;
    cursor: pointer;
    height: 16px;
    left: 0;
    position: absolute;
    top: 0;
    width: 16px;

    .filled {
      border-radius: 50%;
      background-color: #818C8D;
      height: 12px;
      width: 12px;
      position: absolute;
      left: 2px;
      top: 2px;
    }
  }

  input[type="checkbox"] {
    visibility: hidden;
  }
}

.confirm-details-selection {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;

  .continue-button {
    align-self: center;
  }

  .edit-button {
    color: #FFFFFF;
    background-color: #28446A;
    padding: 9px 40px;
    width: 150px;
    margin: 4px 0;
    border-radius: 6px;
    margin: 0px auto 2px auto;

    &:hover {
      cursor: pointer;
      filter: brightness(110%);
    }
  }

  .cancel-and-save {
    margin: 4px auto;
    width: 150px;
    display: flex;
    justify-content: space-between;

    .cancel-button, .save-button {
      color: #FFFFFF;
      padding: 8px 0;
      width: 65px;
      border-radius: 6px;
      &:hover {
        cursor: pointer;
        filter: brightness(110%);
      }
    }

    .cancel-button {
      background-color: #504f4f; 

      &.disabled {
        opacity: 0.6;

        &:hover {
          cursor: auto;
          filter: brightness(100%);
        }
      }
    }

    .save-button {
      background-color: #28446A;
    }
  }
}

.mobile-shift-details-container {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  height: 250px;

  .shift-details-confirmation {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;

    .detail-container {
      display: flex;
      justify-content: space-between;
      margin: 3px 0;

      .detail {
        font: 550 13px mundo;

        .react-datepicker-wrapper input {
          width: 77px;
          padding: 2px;
          font-size: 12px;
          text-align: center;
          border: solid 2px #BFBFBF;
          border-radius: 3px;
          position: relative;
          top: -8px;
        }

        input.rate {
          border: solid 2px #BFBFBF;
          border-radius: 3px;
          font-size: 12px;
          padding: 2px;
          position: relative;
          top: -8px;
          width: 77px;
          text-align: center;
        }

        .dropdown-button.yes-no {
          height: 23px;
          margin: 0;
  
          .data-view-button {
            font-size: 12px;
            width: 65px;
            text-align: center;
            padding: 0;
            height: 23px;
          }
  
          .dropdown-list-container {
            .dropdown-list.yes-no {
              width: calc(100% - 4px);
            }
          }
        }

        .dropdown-button.time {
          margin: 0;
          width: fit-content;
          position: relative;
          top: -7.5px;
          height: 22.5px;
          
          .data-view-button {
            width: 85px;
            height: 22.5px;
            font-size: 12px;
            border: solid 2px #BFBFBF;
            border-radius: 3px;
            text-align: center;
          }

          .dropdown-list-container {
            width: calc(100% + 1px);
            top: 0px;

            .dropdown-list {
              .dropdown-item {
                .dropdown-option {
                  font-size: 11px;
                }
              }
            }
          }
        }
      }
      
      .detail-label {
        font: 550 13px mundo-bold;
      }

      &.date {
        .detail {
          color: #56A08D;
          font: 100 16px mundo-bold;
        }
      }
    }
  }
}

.mobile-total-details-container {
  display: flex;
  flex-direction: column;
  margin: 15px 0;

  .detail-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .detail-label {
      font: 100 13px mundo-bold;
      margin-right: 20px;
    }

    .detail {
      margin: 4px 0;
      font: 100 13px mundo;

      .dropdown-button.yes-no {
        height: 23px;
        margin: 0;
        position: relative;
        top: -12px;

        .data-view-button {
          font-size: 12px;
          width: 85px;
          text-align: center;
          padding: 0;
          height: 23px;
        }

        .dropdown-list-container {
          .dropdown-list.yes-no {
            width: calc(100% - 4px);
          }
        }
      }
    }
  }

  .detail-subtitle {
    margin: 0px 0 0px 0;
    font-size: 11px;
  }
}

.shift-details-container {
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  height: 180px;

  .shift-details-confirmation {
    display: flex;
    justify-content: space-between;
    min-height: 60px;
    max-height: 60px;

    .detail-container {
      display: flex;
      flex-direction: column;
      flex: 2;

      &.date {
        flex: 3.5;
      }

      &.start-time, &.end-time {
        flex: 1.5;
      }

      .detail-label {
        font: 100 13px mundo-bold;
      }

      .detail {
        margin: 10px 0;
        font: 100 12px mundo;

        .react-datepicker-wrapper input {
          width: 50px;
          padding: 2px;
          margin-top: -10px;
          font-size: 12px;
          text-align: center;
          border: solid 2px #BFBFBF;
          border-radius: 3px;
        }

        .dropdown-button.yes-no {
          height: 23px;
          margin: 0;
  
          .data-view-button {
            font-size: 12px;
            width: 65px;
            text-align: center;
            padding: 0;
            height: 23px;
          }
  
          .dropdown-list-container {
            .dropdown-list.yes-no {
              width: calc(100% - 4px);
            }
          }
        }

        input.rate {
          border: solid 2px #BFBFBF;
          border-radius: 3px;
          font-size: 12px;
          padding: 2px;
          position: relative;
          top: -5px;
          width: 50px;
          text-align: center;
        }

        .dropdown-button.time {
          margin: -4.5px 0 0 0;
          width: fit-content;
          height: 23px;
          
          .data-view-button {
            width: 75px;
            font-size: 12px;
            border: solid 2px #BFBFBF;
            border-radius: 3px;
            text-align: center;
            height: 23px;
          }

          .dropdown-list-container {
            width: calc(100% + 1px);
            top: 0px;

            .dropdown-list {
              .dropdown-item {
                .dropdown-option {
                  font-size: 11px;
                }
              }
            }
          }
        }
      }
    }
  }
}

.total-details-container {
  display: flex;
  flex-direction: column;
  align-self: flex-end;
  margin: 15px 0;

  .total-details {
    display: flex;
    flex-direction: column;
  }

  .detail-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    .detail-label {
      font: 100 13px mundo-bold;
      margin-right: 20px;
    }

    .detail {
      margin: 5px 0;
      font: 100 12px mundo;

      .dropdown-button.yes-no {
        height: 23px;
        margin: 0;

        .data-view-button {
          font-size: 12px;
          width: 65px;
          text-align: center;
          padding: 0;
          height: 23px;
        }

        .dropdown-list-container {
          .dropdown-list.yes-no {
            width: calc(100% - 4px);
          }
        }
      }
    }
  }

  .detail-subtitle {
    margin: 3px 0 0px 0;
    font-size: 11px;
  }
}