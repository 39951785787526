.how-it-works-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: linear-gradient(180deg, #EFF7F5 15.83%, rgba(239, 247, 245, 0) 100%);
  padding-top: 50px;

  .title {
    font: 900 35px avenir;
    margin: 20px 0 10px 0;
  }

  .subtitle {
    color: #55A08D;
    font: 900 22px avenir;
    margin: 10px 0;
  }

  .how-it-works {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;

    .how-it-works-row {
      display: flex;
      margin: 40px 0;
      align-items: center;

      @media (max-width: 980px) {
        flex-direction: column;
        text-align: center;
        margin: 40px 20px;
      }

      .image {
        width: 200px;
        height: 200px;
      }

      .content {
        margin-left: 30px; 
        width: 500px;

        @media (max-width: 980px) {
          margin-left: 0;
          width: auto;
          margin: 0 10px;
        }

        .title {
          font: 500 20px avenir;
          color: #55A08D;
        }

        p {
          font: 400 15px avenir;
          color: #231F20;
        }
      }
    }
  }
}