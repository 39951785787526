.store-profile-title {
  background-color: #CAE3DD;
  display: flex;
  flex-flow: column;
  align-items: center;
  padding: 30px 0 110px 0;
  text-align: center;

  @media (max-width: 480px) {
    padding-bottom: 90px;
  }

  .store-avatar-container {
    border-radius: 50%;
    border: solid 4px #55A08D;
    margin: 14px 0;
    overflow: hidden;
    height: 100px;
    width: 100px;

    .store-avatar {
      width: 100px;
      height: 100px;
    }
  }

  .store-name {
    color: #55A08D;
    font: 600 26px Inter, Helvetica, Arial, sans-serif;
    margin: 0 15px;

    @media (max-width: 480px) {
      font-size: 24px;
    }

    @media (max-width: 360px) {
      font-size: 22px;
    }
  }

  .location-name {
    margin: 10px 15px;
    color: #55A08D;
    font: 600 18px Inter, Helvetica, Arial, sans-serif;

    @media (max-width: 480px) {
      font-size: 16px;
    }
  }
}