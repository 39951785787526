.subscribe-to-store-input {
  display: flex;
  align-items: center;
  padding: 10px 0;
  z-index: 1;

  @media (max-width: 480px) {
    padding: 7px 0;
  }

  .label-text {
    width: 80px;
    font: 400 14px Inter, Helvetica, Arial, sans-serif;

    @media (max-width: 480px) {
      font-size: 13px;
      width: 70px;
    }

    @media (max-width: 360px) {
      width: 55px;
      font-size: 11px;
    }
  }

  .text-input {
    padding: 10px 8px;
    font: 600 16px Inter, Helvetica, Arial, sans-serif;
    color: #1A1A1A;
    width: calc(100% - 120px);
    background: #F7F5F5;
    border-radius: 6px;
    border: none;

    @media (max-width: 480px) {
      font-size: 15px;
      width: calc(100% - 60px);
      padding: 6px 4px;
    }

    @media (max-width: 360px) {
      width: calc(100% - 75px);
      font-size: 12px;
    }
  }
}