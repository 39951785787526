.store-profile-section.location {
  display: flex;
  align-self: center;
  flex-flow: column;
  align-items: center;

  .location-button {
    background: #55A08D;
    border: 2px solid #55A08D;
    border-radius: 50px;
    width: 280px;
    height: 45px;
    font: 700 16px Inter, Helvetica, Arial, sans-serif;
    text-align: center;
    color: #FFFFFF;
    margin-top: 40px;

    @media (max-width: 480px) {
      width: 250px;
      font-size: 14px;
      height: 39px;
      margin-top: 30px;
    }
  }
}