.rate-store-section {
  .inputs {
    height: 350px;

    .title {
      font: 700 16px Inter, Helvetica, Arial, sans-serif;
      margin: 10px 0;
    }

    .go-to-buttons {
      height: 0;
      display: flex;
      width: 100%;

      .go-to-button {
        position: absolute;
        bottom: 30px;
        background: transparent;

        @media (max-width: 600px) {
          bottom: 40px;
        }

        @media (max-width: 480px) {
          bottom: 50px;
        }

        &.next {
          right: 25px;
        }

        &.reversed {
          left: 25px;
          right: auto;
          bottom: 34px;

          @media (max-width: 600px) {
            bottom: 44px;
          }

          @media (max-width: 480px) {
            bottom: 54.5px;
          }

          @media (max-width: 420px) {
            bottom: 56px;
          }
        }
      }
    }
  }
}