.login-page {
  .login-background {
    background-color: #FFFFFF;
    height: 1000px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: -100px;

    @media (max-width: 1920px) {
      height: 900px;
    }

    @media (max-width: 1440px) {
      height: 850px;
    }

    @media (max-width: 1250px) {
      margin-top: -70px;
      height: 750px;
    }

    @media (max-width: 980px) {
      height: 650px;
      margin-top: -50px;
    }

    @media (max-width: 480px) {
      height: 500px;
      margin-top: 0;
    }

    .back-arrow-container {
      align-self: flex-start;
      position: relative;
      top: -80px;

      @media (max-width: 1920px) {
        top: -60px;
      }

      @media (max-width: 1440px) {
        top: -50px;
      }

      @media (max-width: 1250px) {
        top: -40px;
      }

      @media (max-width: 980px) {
        top: -30px;
      }
  
      @media (max-width: 480px) {
        top: 20px;
      }
      
      .back-arrow-icon {
        width: 35px;
        height: 35px;

        @media (max-width: 1920px) {
          width: 30px;
          height: 30px;
        }

        @media (max-width: 1440px) {
          width: 25px;
          height: 25px;
        }

        @media (max-width: 1250px) {
          width: 22px;
          height: 22px;
        }

        @media (max-width: 480px) {
          width: 18px;
          height: 18px;
        }
      }

      .back-text {
        font-size: 18px;
        margin-top: 6px;

        @media (max-width: 1920px) {
          font-size: 18px;
        }
  
        @media (max-width: 1440px) {
          font-size: 18px;
        }
  
        @media (max-width: 1250px) {
          font-size: 18px;
          margin-top: 4px;
        }
  
        @media (max-width: 980px) {
          font-size: 18px;
        }
    
        @media (max-width: 480px) {
          font-size: 18px;
        }
      }
    }
  }
}