.store-profile-button {
  color: #55A08D;
  font: 800 12px Inter, Helvetica, Arial, sans-serif;
  text-align: center;
  border: 2px solid #55A08D;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: #CAE3DD;
  }
}