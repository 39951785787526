.home-shift-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 17px;
  width: 270px;
  height: 350px;
  min-width: 270px;
  min-height: 350px;
  max-width: 270px;
  max-height: 350px;
  
  .information {
    padding: 10px 20px;
    display: flex;
    flex-direction: column;

    .store-details {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border-bottom: 0.5px solid #231F20;
      text-align: center;
      padding: 20px 6px;
      height: 140px;

      .store-avatar {
        height: 80px;
        width: 130px;
      }

      .name {
        font: 100 13px inter-extra-light;
        margin: 10px 0 5px 0;
      }

      .type {
        font: 900 12px inter-bold;
      }
    }

    .shift-details {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      padding: 10px 10px;

      .detail {
        margin: 3px 0;
        display: flex;

        .icon {
          width: 18px;
          height: 18px;
        }

        .label {
          margin-left: 10px;
          font: 100 12px inter-extra-light;
        }
      }
    }
  }

  .footer {
    display: flex;
    justify-content: space-between;
    padding: 10px 20px;
    background: #EFF7F5;
    border-radius: 0 0 17px 17px;

    .payment {
      display: flex;
      flex-direction: column;

      .rate {
        color: #231F20;
        line-height: 13px;
        font: 100 12px inter-extra-light;
        margin: 0 0 3px 0;
      }

      .total {
        color: #231F20;
        font: 100 16px inter-bold;
      }
    }

    .view-shift {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 200px;
      background-color: #55A08D;
      padding: 10px 10px 10px 12px;
    }
  }
}