.dropdown-multiselect-container {
  padding: 10px 8px;
  color: #1A1A1A;
  width: calc(100% - 120px);
  background: #F7F5F5;
  border-radius: 6px;
  border: none;
  display: flex;
  flex-flow: column;
  z-index: 0;

  @media (max-width: 480px) {
    font-size: 15px;
    width: calc(100% - 60px);
    padding: 6px 4px;
  }

  @media (max-width: 360px) {
    width: calc(100% - 75px);
    font-size: 12px;
  }

  .dropdown {
    position: relative;
    height: 0;
    left: -8px;

    @media (max-width: 480px) {
      left: -4px;
      top: 4px;
    }

    .dropdown-list {
      position: absolute;
      z-index: 1;
      display: flex;
      flex-direction: column;
      background: #F7F5F5;
      padding: 10px 8px;
      border-top: none;
      width: 100%;
      align-items: flex-start;

      @media (max-width: 480px) {
        padding: 6px 4px;
      }
    }
  }
}