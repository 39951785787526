.map-shift-container {
  // padding-bottom: 118px;
  visibility: visible;
  z-index: 1;
  bottom: 118px;
  position: fixed;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  // pointer-events: none;
  // touch-action: none;

  ::-webkit-scrollbar { 
    display: none; 
  }

  .map-shift-carousel {
    display: flex;
    overflow-x: scroll;
    scroll-snap-type: x mandatory;
    overscroll-behavior: contain;
    scroll-behavior: smooth;
    pointer-events: all;
    touch-action: pan-x;

    .map-shift-position {
      display: flex;
      justify-content: center;
      box-sizing: border-box;
      scroll-snap-align: center;
      

      &:first-child {
        margin-left: 40px;
      }

      &:not(:last-child) {
        margin-right: 12px;
      }

      &:last-child {
        margin-right: 40px;

        @media (max-width: 480px) {
          margin-right: 0;
          border-right: 20px solid transparent;
        }
      }
  
      .map-shift {
        // background-color: #FFFFFF;
        // border-radius: 12px;
        // box-shadow: 0 6px 20px rgba(0,0,0,0.2);
        // height: 126px;
        // position: relative;
        // display: flex;
        // max-width: 400px;
        // width: calc(100vw - 48px);
        // color: #222222;
    
        // @media (max-width: 375px) {
        //   height: 116px;
        // }

        .thumbnail-container {
          border-bottom-left-radius: 12px;
          border-top-left-radius: 12px;
          background-color: #DDDDDD;
          flex-shrink: 0;
          overflow: hidden;
          pointer-events: none;
          position: relative;
          box-sizing: border-box;
  
          @supports (aspect-ratio: 1 / 1) {
            aspect-ratio: 1;
          }
  
          .shift-img {
            object-fit: cover;
            height: 100%;
            width: 100%;
          }
        }
  
        .shift-info-container {
          padding: 12px;
          display: grid;
          gap: 4px;
          grid-template-columns: minmax(0, 1fr) max-content max-content;
          grid-template-rows: min-content minmax(0, 1fr) min-content;
          flex: 1;
          box-sizing: border-box;

          .shift-add-cart {
            cursor: pointer;
            padding: 8px 12px;
            color: white;
            background-color: #55A08D;
            border-radius: 22px;
            z-index: 1000;
            pointer-events: all;
          }
  
          .map-shift-title {
            line-height: inherit;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            word-break: break-all;
            color: #222222;
            font-weight: 600;
            font-size: 14px;
  
            @supports (overflow: clip) {
              overflow: clip;
            }
          }
  
          .map-shift-date {
            grid-column: 1 / -1;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            -webkit-box-pack: justify;
  
            .date-text-container {
              overflow: hidden;
              line-height: inherit;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 1;
              color: #717171;
              box-sizing: border-box;
            }
          }
        }
      }
    }
  }
}