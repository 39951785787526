.blog-page.additional-provider-numbers {
  display: flex;
  flex-direction: column;
  width: 100%;

  .back-arrow-container {
    margin: 30px 0 0 30px;

    .back-text {
      margin-top: 4px;
      font-size: 24px;

      @media (max-width: 480px) {
        font-size: 18px;
      }
    }
  }
  
  .image-banner {
    width: 100%;
    margin: 0 0 30px 0;
  }

  .article {
    width: 720px;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: 30px auto;

    @media (max-width: 980px) {
      width: 600px;
    }

    @media (max-width: 720px) {
      width: 500px;
    }

    @media (max-width: 620px) {
      width: 430px;
    }

    @media (max-width: 480px) {
      width: 380px;
    }

    @media (max-width: 420px) {
      width: 320px;
    }

    @media (max-width: 360px) {
      width: 280px;
    }

    .title {
      font-size: 24px;
      text-align: center;
      font-family: sans-serif;

      @media (max-width: 480px) {
        font-size: 20px;
      }
    }

    .date {
      text-align: center;
      font-size: 16px;
      margin: 10px 0;
      font-family: sans-serif;

      @media (max-width: 480px) {
        font-size: 14px;
      }
    }

    .link {
      text-decoration: underline;
      color: #56A08D;
    }

    .paragraph {
      margin: 10px 0;
      font-size: 18px;
      font-family: sans-serif;
      font-weight: 100;

      @media (max-width: 480px) {
        font-size: 16px;
      }

      .bold {
        font-weight: 900;
      }

      &.end {
        text-align: center;
      }
    }

    .tutorial-list {
      margin: 15px 0;
      display: flex;
      align-items: center;
      flex-direction: column;

      .tutorial {
        display: flex;
        align-items: center;
        flex-direction: column;

        .guide {
          text-align: center;
          margin: 20px 0;
          font-size: 18px;
          font-family: sans-serif;
          font-weight: 500;
          color: #56A08D;

          @media (max-width: 480px) {
            font-size: 16px;
            margin: 15px 0;
          }
        }

        .paragraph {
          text-align: center;
        }

        .subguide {
          text-align: center;
          font-size: 16px;
          margin: 20px 0 10px 0;

          @media (max-width: 480px) {
            font-size: 14px;
          }
        }

        img {
          width: 100%;
          margin: 0 0 10px 0;

          @media (max-width: 480px) {
            margin: 10px 0;
          }
        }
      }
    }
  }
}