.where-padding {
  padding-bottom: 12px;

  @media (max-width: 480px) {
    --staggered_animation_delay: 0s;
    animation: fadeInDown-header-body ease 0.5s;
    animation-delay: var(--staggered_animation_delay, 1);
  }

  .where-detail {
    display: flex;

    &.active {
      flex-direction: column;
    }
  }

  .where-input-padding {
    padding-top: 16px;
    box-sizing: border-box;
    width: 33%;

    @media (max-width: 480px) {
      width: unset;
    }

    .where-align {
      display: flex;
      align-items: center;
      box-sizing: border-box;
      width: 100%;

      .where-form {
        flex: 1;
        box-sizing: border-box;
        position: relative;

        .clear-container {
          display: flex;
          position: absolute;
          right: 26px;
          top: 0px;
          bottom: 0px;
          height: 100%;
          align-items: center;

          .clear-button {
            appearance: none;
            display: inline-block;
            border-radius: 50%;
            outline: none;
            margin: 0px;
            padding: 0px;
            color: rgb(34, 34, 34);
            cursor: pointer;
            touch-action: manipulation;
            position: relative;
            background: transparent;
            transition: -ms-transform 0.25s ease 0s, -webkit-transform 0.25s ease 0s, transform 0.25s ease 0s !important;
            font-size: 100%;

            &::before {
              content: "";
              display: block;
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              width: 20px;
              height: 20px;
              border-radius: 50%;
              background: rgb(221, 221, 221);
              box-sizing: border-box;
            }

            .clear-holder {
              position: relative;

              .clear-image {
                display: block;
                fill: none;
                height: 12px;
                width: 12px;
                overflow: visible;
                box-sizing: border-box;
              }
            }
          }
        }

        .where-label {
          flex: 1 1 0%;
          overflow: hidden;
          position: relative;
          align-items: center;
          display: flex;
          margin: 0px;
          padding: 0px 20px;
          white-space: nowrap;
          height: 60px;
          border-radius: 12px;
          border: 1px solid rgb(176, 176, 176);
          background: rgb(255, 255, 255);
          color: rgb(34, 34, 34);
          box-sizing: border-box;

          .where-icon-container {
            display: flex;
            align-items: center;
            margin-right: 10px;
            height: 100%;
            color: rgb(34, 34, 34);
            
            .where-icon {
              display: block;
              height: 16px;
              width: 16px;
              overflow: visible;
            }
          }

          .where-input {
            width: 100%;
            min-height: 1px;
            border: none;
            outline: none;
            margin: 0px;
            color: inherit;
            background-color: transparent;
            font-size: 14px;
            font-weight: 600;
            line-height: 18px;
            padding-right: 8px;
            height: 100%;
            text-overflow: ellipsis;
            box-sizing: border-box;
          }
        }
      }
    }
  }

  .active-search-padding {
    width: 100%;
    padding-top: 0px !important;
  }

  .where-search-active {
    margin: 0;
    left: 0;
    right: 0;
    bottom: 0;
    top: 85px;
    z-index: 1;

    @media (max-width: 480px) {
      position: absolute;
    }

    .autofill-container {
      left: 0;
      right: 0;
      border: none;
      border-radius: 0;
      width: 100vw;

      @media (max-width: 480px) {
        position: absolute;
      }

      .autofill-padding {
        height: 100%;
        padding: 0;
        padding-top: 16px;
        margin: 0;
        box-sizing: border-box;

        .suggestion-box {
          cursor: pointer;
          list-style-type: none;
          width: 100%;
          display: flex;
          padding: 8px 32px 8px 0;
          box-sizing: border-box;

          @media (max-width: 480px) {
            padding: 8px 32px 8px 24px;
          }

          &:hover {
            background-color: rgb(247, 247, 247);
          }

          .suggestion-icon-container {
            font-size: 17px;
            background-color: rgb(235, 235, 235);
            border-radius: 12px;
            min-width: 48px;
            height: 48px;
            margin-right: 16px;
            display: flex;
            align-items: center;
            justify-content: center;

            .suggestion-icon {
              display: block;
              height: 22px;
              width: 22px;
            }
          }

          .suggestion-place {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            box-sizing: border-box;

            .suggestion-text {
              overflow: clip;
              font-size: 16px;
              line-height: 20px;
              font-weight: 500;
              color: rgb(34, 34, 34);
              text-overflow: ellipsis;
              box-sizing: border-box;
            }
          }
        }
      }
    }
  }

  .where-state-picker {
    display: flex;
    scroll-snap-type: x proximity;
    box-sizing: border-box;
    width: 67%;
    padding-left: 20px;
    border-left: 1px solid #EBEBEB;
    margin-left: 20px;
    flex-direction: column;

    @media (max-width: 480px) {
      width: unset;
      margin: 12px -24px 0 -24px;
      padding: 0;
      border-left: none;
      flex-direction: row;
      overflow-x: scroll;
    }

    .state-search-text {
      font-weight: 800;
    }
    
    .state-picker-carousel {
      display: flex;
      box-sizing: border-box;
      flex-wrap: wrap;
      
      @media (max-width: 480px) {
        flex-wrap: nowrap;
      }

      .state-picker-item {
        height: 150px;
        width: 126px;
        margin-top: 4px;

        @media (max-width: 480px) {
          height: 158px;
          width: 132px;
        }

        &:first-child {
          @media (max-width: 480px) {
            margin-left: 22px;
          }
        }

        &:not(:last-child) {
          margin-right: 12px;
        }

        &:last-child {
          margin-right: 22px;
        }

        .state-picker-button-container {
          padding: 4px;
          display: flex;
          flex-direction: column;
          height: 100%;
          width: 100%;
          box-sizing: border-box;

          .state-picker-button {
            cursor: pointer;
            text-align: center;
            border: 1px solid rgb(221, 221, 221);
            outline: none;
            padding: 0px;
            margin: 0px;
            position: relative;
            transition-property: -ms-transform, -webkit-transform, transform, background-color, border-color;
            transition-duration: 0.15s;
            transition-timing-function: ease-in-out;
            border-radius: 12px;
            height: 100%;
            overflow: hidden;
            width: 100%;
            background-color: rgb(247, 247, 247);

            &:active {
              color: rgb(0, 0, 0);
              border-color: rgb(0, 0, 0);
              transform: scale(0.925);
            }

            .state-picker-image {
              display: flex;
              height: 100%;
              width: 100%;
              box-sizing: border-box;
              cursor: pointer;
            }
          }

          .selected-button {
            border: 2px solid rgb(0, 0, 0);
          }

          .carousel-item-label {
            margin: 8px 2px 0 2px;
            color: #222222;
            font-size: 14px;
            line-height: 18px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
      }
    }
  }
}