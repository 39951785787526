@keyframes close-dropdown {
  0% {
    border-radius: 10px 10px 0 0;
  }
  99% {
    border-radius: 10px 10px 0 0;
  }
  100% {
    border-radius: 10px;
  }
}

.store-shift-list {
  display: flex;
  flex-flow: column;
  align-items: center;

  .store-shift-list-toggle {
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    padding: 25px 30px;
    text-align: center;
    width: 450px;
    align-items: center;
    transition-delay: 0.8s;
    transition-property: border-radius;

    @media (max-width: 480px) {
      width: 380px;
    }

    @media (max-width: 420px) {
      width: 330px;
    }

    @media (max-width: 360px) {
      width: 290px;
    }

    .text {
      font: 500 18px Inter, Helvetica, Arial, sans-serif;

      @media (max-width: 480px) {
        font-size: 15px;
      }

      @media (max-width: 360px) {
        font-size: 14px;
      }
    }

    .dropdown-arrow {
      width: 20px;
      height: auto;

      @media (max-width: 480px) {
        width: 16px;
      }

      @media (max-width: 360px) {
        width: 15px;
      }
    }

    &.open {
      transition-delay: 0s;
      transition-property: border-radius;
      border-radius: 10px 10px 0 0;

      .dropdown-arrow {
        transform: scaleY(-1);
        position: relative;
        top: -1px;
      }
    }

    &.disabled {
      opacity: 0.6;

      &:hover {
        cursor: auto;
        filter: brightness(100%);
      }
    }
  }

  .store-shifts-container {
    transform-origin: center top;
    transition: max-height 0.8s ease-in-out;
    width: 450px;
    position: relative;
    top: -2px;
    overflow: hidden;

    @media (max-width: 480px) {
      width: 380px;
    }

    @media (max-width: 420px) {
      width: 330px;
    }

    @media (max-width: 360px) {
      width: 290px;
    }

    .store-shift-item {
      height: 120px;

      &.odd {
        background: #CAE3DD;
      }

      &.even {
        background: #ABD2C8;
      }
    }
  }
}