.store-sidebar-navigation-item {
  width: 224px;

  .store-sidebar-navigation {
    width: 100%;
    text-align: left;
    padding: 15px 20px;
    border-bottom : solid 2px #F6F8FE;
    background-color: #FFFFFF;
    font-size: 16px;

    &.active {
      background-color: #56A08D;
      color: #FFFFFF;

      &:hover {
        cursor: auto;
        filter: brightness(100%);
      }
    }
  }
}