.forgot-password-container {
    background-color: #FFFFFF;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width: 570px;
  
    border-radius: 1rem;
    border-color: #eaeced;
    border-style: none;
    box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  
    @media (max-width: 1920px) {
      width: 500px;
    }
  
    @media (max-width: 1440px) {
      width: 430px;
    }
  
    @media (max-width: 980px) {
      width: 380px;
    }
  
    @media (max-width: 480px) {
      width: 350px;
      margin: auto;
    }
  
    @media (max-width: 420px) {
      width: 320px;
    }
  
    .form-container {
      padding: 60px;
      text-align: center;
      width: 100%;
  
      @media (max-width: 1920px) {
        padding: 50px;
      }
  
      @media (max-width: 1440px) {
        padding: 45px;
      }
  
      @media (max-width: 1250px) {
        padding: 40px;
      }
  
      @media (max-width: 980px) {
        padding: 30px;
      }
  
      @media (max-width: 480px) {
        padding: 30px;
      }
  
      @media (max-width: 420px) {
        font-size: 24px;
      }
    }
  
    .input-container {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      
      @media (max-width: 400px) {
        width: 260px;
      }
    }
  
    .loading-page {
      width: 100%;
      height: 100%;
      background-color: #e3e1d6;
      margin-top: 100px;
    }
  
    .passwords {
      margin-top: 0;
      padding-top: 0;
      background-color: #FFFFFF;
      width: 100%;
    }
  
    &.success {
      margin: 50px auto 0 auto;
      width: 400px;
  
      @media (max-width: 420px) {
        width: 300px;
      }
    }
  
    .login-text {
      text-align: center;
      font-weight: 900;
      margin: 0;
      font-size: 42px;
  
      @media (max-width: 1920px) {
        font-size: 36px;
      }
  
      @media (max-width: 1440px) {
        font-size: 34px;
      }
  
      @media (max-width: 1250px) {
        font-size: 32px;
      }
  
      @media (max-width: 980px) {
        font-size: 24px;
      }
  
      @media (max-width: 480px) {
        font-size: 24px;
      }
  
      @media (max-width: 420px) {
        font-size: 24px;
      }
  
      &.success {
        font-size: 44px;
  
        @media (max-width: 1920px) {
          font-size: 40px;
        }
    
        @media (max-width: 1440px) {
          font-size: 40px;
        }
    
        @media (max-width: 1250px) {
          font-size: 36px;
        }
    
        @media (max-width: 980px) {
          font-size: 32px;
        }
    
        @media (max-width: 480px) {
          font-size: 28px;
        }
    
        @media (max-width: 420px) {
          font-size: 24px;
        }
      }
    }
  
    .reset-password-subtitle {
      text-align: center;
      font-size: 24px;
      margin: 10px 0;
  
      @media (max-width: 1920px) {
        font-size: 22px;
      }
  
      @media (max-width: 1440px) {
        font-size: 20px;
      }
  
      @media (max-width: 1250px) {
        font-size: 18px;
      }
  
      @media (max-width: 980px) {
        font-size: 16px;
      }
  
      @media (max-width: 480px) {
        font-size: 14px;
      }
  
      .bold {
        color: #000000;
        font-weight: 900;
      }
  
      @media (max-width: 420px) {
        font-size: 15px;
      }
  
      .link {
        background-color: transparent;
        font-size: 16px;
        text-decoration: underline;
        color: #56A08D;
  
        @media (max-width: 420px) {
          font-size: 13px;
        }
  
        &:hover {
          cursor: pointer;
          color: #28446A;
        }
      }
    }
  
    .response-text {
      min-height: 20px;
      font-size: 14px;
      display: flex;
      justify-content: center;
  
      .error-text {
        color: red;
        font-size: 18px;
        margin-top: 10px;
        margin-bottom: 0;
        align-items: flex-end;
  
        @media (max-width: 1920px) {
          font-size: 16px;
        }
      
        @media (max-width: 1440px) {
          font-size: 14px;
          margin-top: 5px;
        }
      
        @media (max-width: 480px) {
          font-size: 12px;
        }
      
        @media (max-width: 400px) {
          font-size: 11px;
          margin: 0;
        }
      }
  
      .success-message {
        color: #56A08D;
      }
    }
  
    @media (max-width: 480px) {
      margin: auto;
    }
  
    .email-address-container:hover {
      border-color: #AEAEB5; 
    }
  
    .email-address-container {
      border-style: solid;
      border-color: #F0F0F4;
      border-radius: 0.5rem;
      border-width: 1px;
      background-color: #FAFAFC;
      padding: 16px 12px;
      width: 100%;
      font-size: 20px;
      margin: 10px 0;
      outline: none;
  
      @media (max-width: 1920px) {
        font-size: 18px;
        padding: 15px 10px;
      }
  
      @media (max-width: 1440px) {
        font-size: 16px;
      }
  
      @media (max-width: 1250px) {
        font-size: 15px;
      }
  
      @media (max-width: 980px) {
        font-size: 14px;
        padding: 13px 8px;
        margin: 5px 0;
      }
  
      @media (max-width: 480px) {
        font-size: 13px;
        margin: 5px 0;
      }
  
      @media (max-width: 420px) {
        font-size: 12px;
      }
    }
  
    .confirm-buttons {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
  
      .continue-button {
        border-radius: 0.5rem;
        padding: 13px 10px;
        text-align: center;
        font-size: 30px;
        color: #FFFFFF;
        margin: 15px;
        background-color: #55a08d;
        width: 100%;
  
        @media (max-width: 1920px) {
          padding: 12px 9px;
        }
    
        @media (max-width: 1440px) {
          margin: 10px;
          padding: 15px 40px;
          font-size: 18px;
        }
  
        @media (max-width: 980px) {
          padding: 13px 40px;
          font-size: 18px;
        }
  
        @media (max-width: 480px) {
          font-size: 16px;
          padding: 13px 30px;
          margin: 5px;
        }
  
        &:hover {
          filter: brightness(125%);
        }
  
        &.loading {
          opacity: 0.4;
  
          &.very-dark:hover {
            cursor: auto;
            filter: brightness(100%);
          }
        }
      }
  
      .success-back-to-login {
        border-radius: 20px;
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
        padding: 15px 80px;
        text-align: center;
        font-size: 24px;
        color: #FFFFFF;
        margin: 5px;
        background-color: #28446A;
  
        @media (max-width: 1920px) {
          padding: 15px 60px;
        }
    
        @media (max-width: 1440px) {
          padding: 10px 40px;
          font-size: 18px;
        }
  
        @media (max-width: 480px) {
          font-size: 16px;
          padding: 8px 30px;
        }
  
        &:hover {
          filter: brightness(125%);
          cursor: pointer;
        }
      }
  
      .back-to-login {
        color: #56A08D;
        background: transparent;
        margin-top: 30px;
        margin-bottom: 15px;
        font-size: 26px;
  
        @media (max-width: 1920px) {
          font-size: 24px;
          margin-bottom: 5px;
        }
    
        @media (max-width: 1440px) {
          margin-top: 20px;
          font-size: 18px;
          margin-bottom: 5px;
        }
  
        @media (max-width: 480px) {
          font-size: 16px;
          margin-top: 10px;
        }
  
        &:hover {
          cursor: pointer;
          color: #28446A;
        }
      }
    }
  }
