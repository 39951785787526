.other-padding {
  padding-bottom: 12px;

  @media (max-width: 480px) {
    --staggered_animation_delay: 0.1s;
    animation: fadeInDown-header-body ease 0.5s;
    animation-delay: var(--staggered_animation_delay, 1);
  }

  .other-container {
    // margin-bottom: -16px; commented out until MobileOtherItem is needed again.
    box-sizing: border-box;

    .other-item-container {
      border-top: 1px solid #ebebeb;
      margin-top: 12px;
      color: rgb(34, 34, 34);
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-top: 16px;
      padding-bottom: 16px;
      padding-right: 4px;

      &:not(:last-child) {
        border-bottom: 1px solid rgb(235, 235, 235);
      }

      .item-name {
        margin-right: 5px;
        box-sizing: border-box;

        .item-title {
          font-size: 16px;
          line-height: 20px;
          font-weight: 600;
        }
      }

      .incrementer {
        min-width: 104px;
        margin-left: 5px;
        box-sizing: border-box;

        .incrementer-container {
          display: inline-flex;
          align-items: center;
          justify-content: space-between;
          width: 104px;
          height: 32px;
          color: rgb(34, 34, 34);
          font-weight: 400;
          font-size: 16px;
          line-height: 20px;

          .incrementer-button {
            width: 32px;
            height: 32px;
            flex-grow: 0;
            flex-shrink: 0;
            cursor: pointer;
            display: inline-flex;
            margin: 0px;
            padding: 0px;
            text-align: center;
            text-decoration: none;
            border-width: 1px;
            border-style: solid;
            border-color: rgb(176, 176, 176);
            color: rgb(113, 113, 113);
            font-family: inherit;
            outline: none;
            touch-action: manipulation;
            align-items: center;
            justify-content: center;
            background: rgb(255, 255, 255);
            border-radius: 50%;
            font-size: 100%;
            box-sizing: border-box;

            &:hover {
              color: rgb(34, 34, 34);
              border-color: rgb(34, 34, 34);
              background: rgb(255, 255, 255);
            }

            &:disabled {
              cursor: not-allowed;
              color: rgb(235, 235, 235);
              border-color: rgb(235, 235, 235);
              background: rgb(255, 255, 255);
            }

            .inc-image {
              height: 22px;
              width: 22px;
              padding: 5px;
              box-sizing: border-box;

              .inc-svg {
                display: block;
                fill: none;
                height: 12px;
                width: 12px;
                stroke: currentcolor;
                stroke-width: 5.33333;
                overflow: visible;
                box-sizing: border-box;
              }
            }
          }

          .incrementer-count {
            position: relative;
            color: inherit;
            font-family: inherit;
            font-size: inherit;
            line-height: inherit;
            box-sizing: border-box;

            .inc-num {
              box-sizing: border-box;
            }
          }
        }
      }
    }
  }
}