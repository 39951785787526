.experiences-container {
  display: flex;
  margin-top: 20px;

  .experience-title,
  .option-title {
    font-family: Arial,Helvetica,sans-serif;
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 20px;

    @media (max-width: 670px) {
      font-size: 14px;
    }
  }

  .experience-labels {
    background-color: #e3e1d6;
    height: 650px;
    padding: 20px;
    width: 600px;

    @media (max-width: 1250px) {
      max-width: 350px;
    }

    @media (max-width: 980px) {
      width: 300px;
    }

    @media (max-width: 980px) {
      width: 300px;
    }

    @media (max-width: 720px) {
      height: 750px;
      min-width: 150px;
      max-width: 150px;
      width: auto;
    }

    @media (max-width: 480px) {
      height: 850px;
      max-width: 120px;
      min-width: 120px;
      padding: 10px;
    }

    @media (max-width: 420px) {
      padding: 10px 5px;
    }

    @media (max-width: 360px) {
      height: 885px;
    }

    .experience-label {
      font-size: 18px;

      @media (max-width: 1250px) {
        font-size: 16px;
      }

      &.one {
        margin: 15px 0 85px 0;
      }

      &.two {
        margin: 0 0 90px 0;
      }

      &.three {
        margin: 0 0 85px 0;
      }

      &.four {
        margin: 0 0 80px 0;
      }

      &.five {
        margin: 0 0 115px 0;

        @media (max-width: 360px) {
          margin: 10px 0 140px;
        }
      }

      @media (max-width: 1250px) {
        &.one, &.two, &.three, &.four {
          margin: 10px 0 70px 0;
        }

        &.five {
          margin: 10px 0 90px 0;
        }
      }

      @media (max-width: 720px) {
        font-size: 14px;
      }
    }
  }

  .options-container {
    margin: 20px 30px;
    
    @media (max-width: 720px) {
      min-width: 200px;
      margin: 20px;
    }

    @media (max-width: 480px) {
      min-width: 200px;
      margin: 10px 0 10px 10px;
    }

    .option-list {
      display: flex;
      flex-direction: column;

      .option-item {
        .other-networks {
          border: 2px solid gray;
          border-radius: 10px;
          font-size: 16px;
          padding: 5px 10px;
          max-width: 286px;
          height: 50px;

          @media (max-width: 670px) {
            font-size: 14px;
          }

          @media (max-width: 720px) {
            min-width: 228px;
            width: auto;
            height: 70px;
          }

          @media (max-width: 600px) {
            min-width: 208px;
          }

          @media (max-width: 420px) {
            min-width: 168px;
          }

          @media (max-width: 360px) {
            min-width: 138px;
          }
        }

        &.one {
          margin: 5px 0 60px 0;

          @media (max-width: 670px) {
            margin: 5px 0 55px 0;
          }

          @media (max-width: 720px) {
            margin: 15px 0 70px 0;
          }

          @media (max-width: 480px) {
            margin: 20px 0 90px 0;
          }

          @media (max-width: 360px) {
            margin: 20px 0 70px 0;
          }
        }

        &.two {
          margin-bottom: 35px;

          @media (max-width: 720px) {
            margin-bottom: 40px;
          }

          @media (max-width: 480px) {
            margin-bottom: 55px;
          }

          @media (max-width: 360px) {
            margin-bottom: 30px;
          }
        }

        &.three {
          margin-bottom: 55px;

          @media (max-width: 670px) {
            margin-bottom: 45px;
          }

          @media (max-width: 720px) {
            margin-bottom: 55px;
          }

          @media (max-width: 480px) {
            margin-bottom: 75px;
          }
        }

        &.four {
          margin-bottom: 55px;

          @media (max-width: 720px) {
            margin-bottom: 70px;
          }

          @media (max-width: 480px) {
            margin-bottom: 85px;
          }

          @media (max-width: 360px) {
            margin-bottom: 60px;
          }
        }

        &.five {
          margin-bottom: 50px;

          @media (max-width: 670px) {
            margin-bottom: 40px;
          }

          @media (max-width: 720px) {
            margin-bottom: 65px;
          }

          @media (max-width: 360px) {
            margin-bottom: 10px;
          }
        }

        .option-selection {
          display: flex;
          flex-direction: column;

          @media (max-width: 360px) {
            flex-direction: row;
          }
      
          .first-row {
            display: flex;
            margin-bottom: 5px;

            @media (max-width: 360px) {
              flex-direction: column;
              margin: 0;
            }
          }

          .second-row {
            display: flex;

            @media (max-width: 360px) {
              flex-direction: column;
            }
          }
      
          .selection-button {
            font-size: 10px;
            width: 100px;
            margin-right: 5px;

            &.end {
              margin-right: 0px;
            }

            @media (max-width: 720px) {
              min-width: 80px;
              width: 100%;
            }

            @media (max-width: 600px) {
              font-size: 10px;
            }

            @media (max-width: 420px) {
              font-size: 9px;
              min-width: 60px;
            }

            @media (max-width: 360px) {
              width: 80px;
              font-size: 10px;
              margin-bottom: 5px;
            }
          }
        }
      }
    }
  }

  .experience-labels,
  .options-container {
    display: flex;
    flex-direction: column;
  }

  .options-container {
    min-width: 160px;
  }

  .dropdown-button {
    .data-view-button {
      background-color: #56A08D;
      color: #908F8C;
      border-radius: 2px;
      min-width: 200px;
      width: 100%;
      padding: 10px 15px;
      font-size: 16px;
      text-align: center;
      height: 40px;
      color: #FFF;
      border-radius: 10px;

      @media (max-width: 420px) {
        min-width: 180px;
      }

      @media (max-width: 360px) {
        min-width: 165px;
      }

      &.open {
        border-radius: 10px 10px 0 0;
      }

      .down-arrow, .up-arrow {
        border: solid #FFF;
        border-width: 0 2px 2px 0;
        padding: 3px;
        position: relative;
        float: right;
      }

      .down-arrow {
        transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
        top: 2px;
      }

      .up-arrow {
        transform: rotate(-135deg);
        -webkit-transform: rotate(-135deg);
        top: 6px;
      }

      &:hover {
        cursor: pointer;
        filter: brightness(110%);
      }
    }
  }

  .option-selection {
    .selection-button {
      background-color: #E3E1D7;
      color: #908F8C;
      border: none;
      border-radius: 10px;
      width: 150px;
      font-size: 16px;
      text-align: center;
      height: 40px;
  
      &.yes {
        margin-right: 5px;
      }
  
      &.no {
        margin-left: 5px;
      }
  
      &:hover {
        cursor: pointer;
        filter: brightness(90%);
      }
  
      &.active {
        background-color: #56A08D;
        color: #FFF;
  
        &:hover {
          cursor: pointer;
          filter: brightness(110%);
        }
      }
    }
  }
}

.experiences {
  .confirm-buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 20px 30px 0 0;

    @media (max-width: 480px) {
      margin: 20px 10px 0 0;
    }

    @media (max-width: 420px) {
      margin: 20px 0 0 0;
    }

    .cancel-button {
      width: 110px;
      padding: 10px 0px;
      border-radius: 6px;
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
      background-color:  #636363;
      text-align: center;
      font-size: 18px;
      color: #FFFFFF;

      @media (max-width: 360px) {
        font-size: 16px;
        width: 90px;
      }

      &:hover {
        cursor: pointer;
        filter: brightness(110%);
      }

      &.disabled {
        opacity: 0.5;

        &:hover {
          cursor: auto;
          filter: brightness(100%);
        }
      }
    }

    .confirm-experiences-button {
      width: 200px;
      padding: 10px 0px;
      border-radius: 6px;
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
      background-color: #55a08d;
      text-align: center;
      font-size: 18px;
      color: #FFFFFF;

      @media (max-width: 360px) {
        font-size: 16px;
        width: 150px;
      }

      &:hover {
        cursor: pointer;
        filter: brightness(90%);
      }

      &.disabled {
        opacity: 0.5;

        &:hover {
          cursor: auto;
          filter: brightness(100%);
        }
      }
    }
  }
}

