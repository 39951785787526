.cart-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: #CAE3DD;

  @media (max-width: 720px) {
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
  }
  
  .logo-header {
    display: flex;
    width: 100%;
    position: fixed;
    max-height: 50px;
    align-items: center;
    z-index: 1001;
    background-color: #CAE3DD;
    top: 0;

    .logo-container {
      display: flex;
      justify-content: center;
      width: 100%;
      height: 100%;

      .locumly-logo {
        max-height: 40px;
        padding: 5px 0;
        width: auto;
      }
    }
  }

  .msg-container {
    padding: 0 18px;
    box-sizing: border-box;
    width: 100%;
    margin: 10px 0;
    box-sizing: border-box;

    .answer-form {
      margin: 10px 0 ;

      .answer-box {
        width: 100%;
        height: 70px;
        border-radius: 14px;
        padding: 10px;
        box-sizing: border-box;
      }
    }
  }

  .cart-detail-container {
    height: 100%;
    width: 100%;
    background-color: #FFFFFF;
    border-top-left-radius: 60px;
    border-top-right-radius: 60px;
    padding: 60px;
    box-sizing: border-box;

    @media (max-width: 720px) {
      border-top-left-radius: 30px;
      border-top-right-radius: 30px;
      padding: 30px 0;
    }

    .complete-screen {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      text-align: center;
      padding: 30px;
      box-sizing: border-box;
      margin-top: 60px;

      .complete-title {
        color: #55A08D;
        font-size: 25px;
      }
      
      .complete-img {
        margin: 40px 0;
      }

      .complete-text {
        color: #000000;
        font-size: 14px;
      }
    }

    .empty-cart-container {
      margin-top: 70px;
      padding: 0 30px;
      .empty-cart-text {
        font-size: 12px;
      }
      .empty-cart {
        font-size: 12px;
        color: #EFCB68;
      }
    }

    .cart-detail-heading {
      display: flex;
      margin: 0 30px;

      @media (max-width: 720px) {
        flex-direction: column;
      }

      .cart-detail-title {
        display: flex;
        align-items: center;

        .cart-title {
          font-size: 25px;
          color: #55A08D;
        }

        .notif-number {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 24px;
          width: 24px;
          border-radius: 5px;
          background-color: #F16B58; 
          font-size: 11px;
          color: #FFFFFF;
          font-weight: 900;
          margin-left: 15px;
        }
      }

      .select-container {
        display: flex;
        justify-content: space-between;
        padding: 0 30px;
        margin: 10px 0;

        @media (max-width: 720px) {
          padding: 0;
        }

        .edit-buttons {
          display: flex;
          align-items: center;

          .select-shifts {
            font-size: 12px;
            font-weight: 200;
            color: #000000;
            margin-right: 10px;
  
            &.bold {
              font-weight: 500;
            }
          }
        }

        .delete-shifts {
          display: flex;
          align-items: center;
          font-size: 12px;
          font-weight: 200;
          color: #000000;

          .trash-icon {
            margin-left: 5px;
          }
        }
      }
    }

    .cart-content {
      display: flex;

      @media(max-width: 720px) {
        display: block;
      }

      .cart-info-container {
        width: 80%;

        @media(max-width: 720px) {
          width: 100%;
        }
  
        .store-scroll {
          height: 100%;

          @media (max-width: 720px) {
            height: calc(100vh - 310px);
            height: calc((var(--vh, 1vh) * 100) - 310px);
            overflow-y: scroll;
          }

          &.message-container {

            @media (max-width: 720px) {
              align-items: flex-start;
              justify-content: flex-start;
              flex-direction: column;
              height: 60vh;
              height: calc(var(--vh, 1vh) * 60);
              padding: 30px 30px 0px 30px;
            }
          }

          .message-questions {
            width: 100%;
            padding: 20px;
            box-sizing: border-box;
            @media(max-width: 720px) {
              padding: 0;
              overflow-y: scroll;
            }
    
            .store-questions-container {
              background-color: #EFF7F5;
              margin-top: 20px;
              border-radius: 20px;
              padding: 10px 20px;
      
              .store-title {
                color: #231F20;
                font-size: 12px;
                font-weight: 500;
                margin-bottom: 15px;
              }
      
              .answer-form {
                .store-question {
                  font-size: 12px;
                  font-weight: 200;
                  margin-bottom: 5px;
                }
      
                .answer-box {
                  resize: none;
                  border-radius: 15px;
                  border: none;
                  width: 100%;
                  height: 70px;
                  padding: 15px;
                  font-size: 10px;
                  box-sizing: border-box;
                }
              }
            }
          }
  
          .store-container {
            display: flex;
            width: 100%;
            flex-wrap: wrap;
            margin: 8px 0;
            
            .store-name {
              font-size: 14px;
              font-weight: 800;
              margin-bottom: 10px;
              padding: 0 30px;
            }
  
            .store-shifts {
              display: grid;
              grid-template-columns: repeat(4, 1fr);
              grid-gap: 20px;
              padding: 0 30px;
              width: 100%;

              @media (max-width: 1720px) {
                grid-template-columns: repeat(3, 1fr);
                grid-gap: 15px;
              }

              @media (max-width: 1080px) {
                grid-template-columns: repeat(2, 1fr);
                grid-gap: 10px;
              }

              @media (max-width: 720px) {
                grid-gap: 0;
                display: flex;
                flex-wrap: wrap;
                padding: 0;
              }
  
              .shift-edit-container {
                display: flex;
                align-items: center;
                width: 100%;

                @media (max-width: 720px) {
                  padding: 0 30px;
                }
      
                &.editing {
                  @media (max-width: 720px) {
                    padding: 0 10px 0 0;
                  }
                }
      
                .shift-edit-checkbox {
                  position: absolute;
                  top: 10px;
                  right: 10px;

                  @media (max-width: 720px) {
                    position: unset;
                    padding: 0 5px;
                  }
                }
                
                .shift-item-container {
                  display: flex;
                  flex-direction: column;
                  justify-content: space-around;
                  box-shadow: inset 0px 1px 10px rgba(0, 0, 0, 0.12);
                  border-radius: 15px;
                  width: 100%;
                  padding: 30px;
                  height: 150px;
                  position: relative;

                  @media (max-width: 720px) {
                    display: grid;
                    grid-template-columns: repeat(12, 1fr);
                    grid-template-rows: repeat(3, 1fr);
                    height: auto;
                    padding: 10px;
                    margin-bottom: 10px;
                  }
        
                  .right-grid {
                    grid-column: span 6;
                    grid-row: span 2;
                    margin: 10px 0;

                    @media (max-width: 720px) {
                      margin: 0;
                    }
        
                    .rate-row {
                      grid-column: span 3;
                      font-size: 12px;
                    }
            
                    .total-row {
                      grid-column: span 4;
                      color: #55A08D;
                      font-size: 12px;
                      margin: 5px 0;

                      @media (max-width: 720px) {
                        margin: 0;
                      }
                    }
        
                    .typical-rate-row {
                      display: flex;
                      align-items: center;
                      justify-content: flex-start;
                      grid-column: span 6;
                      font-size: 10px;
                      font-weight: 200;

                      @media (max-width: 720px) {
                        justify-content: flex-end;
                        text-align: end;
                      }
        
                      @media (max-width: 360px) {
                        font-size: 8px;
                      }
                    }
        
                    .rate-display {
                      display: flex;
                      justify-content: flex-start;

                      @media (max-width: 720px) {
                        justify-content: flex-end;
                      }
                    }
                  }
          
                  .date-row {
                    grid-column: span 12;
                    font-weight: 600;
        
                    @media(max-width: 390px) {
                      font-size: 14px;
                    }
                  }
          
                  .time-row {
                    grid-column: span 6;
                    grid-row: 2;
                    color: #55A08D;
                    font-size: 11px;
                  }
                  
                  .new-rate-row {
                    grid-column: span 6;
                    grid-row: span 2;
        
                    .new-rate {
                      width: fit-content;
                      appearance: none;
                      padding: 6px 15px;
                      border-radius: 30px;
                      border-color: #CAE3DD;
                      color: #959493;
                      font-size: 10px;

                      &.fixed {
                        border-color: #efcb68;
                      }
        
                      @media(max-width: 360px) {
                        padding: 3px 8px;
                        font-size: 9px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
    
        .total-pay {
          width: 100%;
        }
      }
  
      .cart-summary-container {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        background: #FFFFFF;
        width: 20%;
        padding: 30px 0;
        box-sizing: border-box;
        flex-direction: column;

        @media (max-width: 720px) {
          flex-direction: row;
          justify-content: space-between;
          box-shadow: 0px 0px 5px 5px rgba(0, 0, 0, 0.05);
          border-radius: 30px 30px 0px 0px;
          position: fixed;
          padding: 30px;
          bottom: 0;
          width: 100%;
        }

        @media (max-width: 480px) {
          bottom: 60px;
        }
  
        &.message-container {
          @media (max-width: 720px) {
            align-items: flex-start;
            justify-content: flex-start;
            flex-direction: column;
            height: 60vh;
            height: calc(var(--vh, 1vh) * 60);
            padding: 30px 30px 0px 30px;
          }
        }

        .proceed-button-container {
          display: flex;
          justify-content: center;
      
          .proceed-button {
            cursor: pointer;
            padding: 8px 30px;
            border-radius: 30px;
            color: #FFFFFF;
            background-color: #55A08D;
          }
        }
  
        .message-questions {
          width: 100%;
          overflow-y: scroll;
  
          .store-questions-container {
            background-color: #EFF7F5;
            margin-top: 20px;
            border-radius: 20px;
            padding: 10px 20px;
    
            .store-title {
              color: #231F20;
              font-size: 12px;
              font-weight: 500;
              margin-bottom: 15px;
            }
    
            .answer-form {
              .store-question {
                font-size: 12px;
                font-weight: 200;
                margin-bottom: 5px;
              }
    
              .answer-box {
                resize: none;
                border-radius: 15px;
                border: none;
                width: 100%;
                height: 70px;
                padding: 15px;
                font-size: 10px;
                box-sizing: border-box;
              }
            }
          }
        }
  
        .cart-summary {
          display: flex;
          font-size: 12px;
          line-height: 20px;
          flex-direction: column;

          &.message-summary {
            margin-bottom: 10px;
            justify-content: space-between;
            width: 100%;
            flex-direction: row;
          }

          .summary-info {
            display: flex;
            flex-direction: column;
          }

          .proceed-button-container {
            display: flex;
            justify-content: center;
        
            .proceed-button {
              cursor: pointer;
              padding: 8px 30px;
              border-radius: 30px;
              color: #FFFFFF;
              background-color: #55A08D;
            }
          }
  
          .shift-req-num {
            margin-bottom: 10px;
            @media (max-width: 720px) {
              margin: 0;
            }
          }

          .shift-req-hours {
            font-weight: 200;
            margin-bottom: 10px;

            @media (max-width: 720px) {
              margin: 0;
            }
          }
        }
  
        .request-shift {
          color: #FFFFFF;
          background-color: #55A08D;
          padding: 10px 20px;
          font-size: 14px;
          border-radius: 30px;
        }
      }
    }
  }
}