@font-face {
  font-family: mundo-bold;
  src: url('../../../fonts/mundo-bold.otf');
}

.edit-store-page {
  display: flex;
  flex-direction: column;
  width: calc(100% - 240px);
  height: auto;

  @media (max-width: 980px) {
    width: 100%;
  }

  .edit-store-title {
    font: 400 24px inter-bold;
    margin: 10px 0;
    color: #1a1a1a;
    
    @media (max-width: 480px) {
      text-align: center;
    }
  }
  
  .edit-store-subtitle {
    color: #1A1A1A;
    margin: 5px 0;
    font-size: 16px;
  }
}

.edit-store-page, .shift-store-view {
  .store-details {
    background-color: #FFFFFF;
    border: solid 2px #706F6F;
    display: flex;
    flex-direction: column;
    margin: 30px 0;
    min-height: 700px;

    .address-and-contact {
      border-bottom: 1px solid #706f6f;

      @media (max-width: 800px) {
        border: none;
      }
    }

    .organization-and-banking {
      border-top: 1px solid #706f6f;

      @media (max-width: 800px) {
        border: none;
      }
    }
  
    .address-and-contact, .organization-and-banking {
      display: flex;

      @media (max-width: 800px) {
        flex-direction: column;
        border: none;
      }

      .details.contact, .details.organization {
        border-right: 1px solid #706f6f;

        @media (max-width: 800px) {
          border: none;
        }
      }

      .details.address, .details.banking {
        border-left: 1px solid #706f6f;

        @media (max-width: 800px) {
          border: none;
        }
      }
  
      .details {
        display: flex;
        flex-direction: column;
        align-items: center;
        flex: 1;
        min-height: 350px;
        min-width: 320px;
        padding: 15px;

        @media (max-width: 480px) {
          min-width: 260px;
        }

        .detail-title {
          display: flex;
          justify-content: space-between;
          width: 100%;
          align-items: center;
          height: 28px;
  
          .title {
            font: 900 22px Inter, mundo-bold;
            padding-left: 7px;

            @media (max-width: 480px) {
              font-size: 20px;
              padding-left: 5px;
            }
          }

          .edit-account-button {
            @media (max-width: 480px) {
              .edit-button {
                font-size: 16px;
              }

              .cancel-button, .save-button {
                font-size: 13px;
              }
            }
          }
        }
        
        .error-text {
          margin: 5px 0;
          font-size: 14px;
          min-height: 16px;
        }

        .edit-options {
          width: 100%;

          .upload-onboarding-container {
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;
            margin: 10px 0;

            .delete-container {
              margin-top: 15px;

              .file-name {
                font-size: 14px;
              }

              .delete {
                font-size: 16px;
                background-color: #1A1A1A;
                border-radius: 100px;
                padding: 0 5px;
                color: #FFFFFF;
                position: relative;
                top: -10px;
                left: 3px;

                &:hover {
                  cursor: pointer;
                  filter: brightness(120%);
                }
              }
            }

            .download-onboarding {
              background-color: #28446a;
              border-radius: 5px;
              color: #fff;
              font-size: 12px;
              margin: 15px auto 0;
              padding: 10px 20px;
              text-align: center;

              &:hover {
                filter: brightness(120%);
                cursor: pointer;
              }
            }

            .upload-onboarding {
              background-color: #808080;
              border-radius: 5px;
              color: #fff;
              font-size: 13px;
              margin: 15px auto 0;
              padding: 8px 20px;
              text-align: center;

              &:hover {
                filter: brightness(120%);
                cursor: pointer;
              }
            }

            .onboarding-upload {
              visibility: hidden;
              height: 0;
              width: 0;
            }
          }

          .postcode-and-country {
            display: flex;
            justify-content: space-between;
            
            .edit-option.text {
              width: calc(45% - 10px);

              .edit-text {
                width: calc(100% - 10px);
              }
            }
          }

          .edit-option.dropdown {
            .dropdown-button {
              border: 1px solid #000;
              border-radius: 6px;
              font-size: 14px;
              margin-bottom: 10px;
              width: 240px;
              height: 29px;
              padding: 0 5px;

              &.structure {
                z-index: 2;
              }

              &.premises {
                z-index: 1;
              }

              &.open {
                border-radius: 6px 6px 0 0;
              }

              &.disabled {
                border: none;
              }

              .data-view-button {
                border-radius: 6px;
                text-align: left;
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;

                .dropdown-arrow {
                  width: 22px;
                  height: 22px;

                  &.open {
                    transform: rotate(180deg);
                  }
                }
              }

              .dropdown-list-container {
                position: absolute;
                height: 0;

                .dropdown-list {
                  background-color: #FFFFFF;
                  border: 1px solid #000000;
                  width: 250px;
                  position: relative;
                  left: -6px;
                  top: -4px;

                  .dropdown-item {
                    width: 100%;

                    .dropdown-option {
                      padding: 10px 5px;
                      text-align: left;
                      width: 100%;

                      &:hover {
                        filter: brightness(90%);
                        cursor: pointer;
                      }
                    }
                  }
                }
              }
            }
          }


          .edit-option.text, .edit-option.dropdown {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            min-width: 320px;
            width: calc(100% - 30px);

            .react-tel-input {
              width: 100%;
              height: 29px;
              margin-bottom: 10px;

              @media (max-width: 420px) {
                width: 270px;
              }

              .phone-number {
                width: 100%;
                height: 29px;
              }
            }
          
            @media (max-width: 480px) {
              width: calc(100% - 10px);
            }
          
            .edit-label {
              padding: 3px 4px;
              font: 900 14px Inter, mundo-bold;
          
              @media (max-width: 480px) {
                width: 160px;
              }
            }
          
            .edit-text {
              font-size: 14px;
              padding: 5px 4px;
              border-radius: 6px;
              border: 1px solid #000000;
              margin-bottom: 10px;
              width: calc(100% - 10px);
          
              @media (max-width: 420px) {
                width: 260px;
              }
          
              &.disabled {
                border: solid 1px transparent;
                background-color: transparent;
                color: #000000;
              }
            }
          }
        }
      }
    }
  }
}

.shift-store-view .store-details.locum-view {
  margin: 0;
}