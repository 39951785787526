.onboarding-page {
  margin: 130px 30px 50px 30px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .title {
    font-size: 32px;
    text-align: center;
    margin-top: 40px;

    .colored {
      color: #56A08D;
    }
  }

  .subtitle {
    font-size: 18px;
    margin: 10px 0;
    text-align: center;
  }

  .locum-numbers-onboarding {
    .subtitle {
      margin: 10px 20px 10px 20px;
    }
  }

  .change-password-onboarding,
  .locum-numbers-onboarding,
  .stripe-onboarding,
  .completed-onboarding {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;

    a {
      text-decoration: underline;
      color: -webkit-link;
    }

    .onboarding-button, .completed-button, .complete-onboarding-button {
      border-radius: 25px;
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
      padding: 15px 60px;
      text-align: center;
      font-size: 16px;
      color: #FFFFFF;
      width: 320px;
      margin: 10px 0 5px 0;
      background-color: #28446A;

      @media (max-width: 480px) {
        padding: 15px 40px;
        width: 300px;
      }

      &:hover {
        filter: brightness(125%);
      }
    }

    .onboarding-button {
      background-color: #28446A;
    }

    .completed-button, .complete-onboarding-button {
      background-color: #56A08D;
    }

    .complete-onboarding-button {
      width: 280px;
    }

    .party-popper {
      height: 400px;
      width: 400px;
      margin-bottom: 30px;

      @media (max-width: 480px) {
        height: 300px;
        width: 300px;
      }
    }
  }
}

.passwords {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #FFFFFF;
  margin-top: 20px;
  width: 300px;

  padding: 5px 30px;
  border-radius: 1rem;
  border-color: #eaeced;
  border-style: none;
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);

  .error-text {
    color: red;
    font-size: 13px;
    min-height: 20px;
    display: flex;
    margin: 10px 0 0 0px;
  }

  .password {
    display: flex;
    flex-direction: column;
    margin: 10px 0;
    width: 100%;

    .password-label {
      font-size: 16px;
      margin: 3px 0;
      width: 100%;
      text-align: start;
    }

    .password-input:hover {
      border-color: #AEAEB5;
    }

    .password-input {
      border-style: solid;
      box-sizing: border-box;
      border-radius: 0.5rem;
      border-width: 1px;
      border-color: #F0F0F4;
      padding: 13px 12px;
      width: 100%;
      margin: 0 0 5px 0;
      font-size: 18px;
      background-color: #FAFAFC;
      outline: none;

      @media (max-width: 980px) {
        font-size: 16px;
      }

      @media (max-width: 420px) {
        font-size: 14px;
      }
    }
  }

  .reset-continue-button {
    border-radius: 0.5rem;
    padding: 9px 10px;
    text-align: center;
    font-size: 30px;
    color: #FFFFFF;
    margin: 15px;
    background-color: #55a08d;
    width: 100%;

    @media (max-width: 980px) {
      font-size: 25px;
    }

    @media (max-width: 480px) {
      font-size: 20px;
    }

    &:hover {
      filter: brightness(125%);
    }
  }

  .continue-button {
    border-radius: 20px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    padding: 10px 60px;
    text-align: center;
    font-size: 16px;
    color: #FFFFFF;
    width: 200px;
    margin: 10px 0 5px 0;
    background-color: #28446A;

    @media (max-width: 980px) {
      font-size: 25px;
    }

    @media (max-width: 480px) {
      font-size: 20px;
    }

    &:hover {
      filter: brightness(125%);
    }
  }
}

.abn-and-ahpra {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #E3E1D6;
  padding: 10px 20px 15px 20px;
  margin-top: 20px;
  width: 300px;

  .error-text {
    color: red;
    font-size: 13px;
    min-height: 20px;
    display: flex;
    margin: 10px 0 0 0px;
  }

  .number {
    display: flex;
    flex-direction: column;
    margin: 10px 0;

    .label {
      font-size: 16px;
      margin: 3px 0;
    }

    .number-input {
      border: none;
      border-radius: 5px;
      padding: 8px 5px;
      width: 240px;
      margin: 0 0 5px 0;
      font-size: 18px;
    }
  }
  
  .buttons {
    display: flex;
    flex-direction: column;
    align-items: center;

    .skip-button, .continue-button {
      border-radius: 20px;
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
      padding: 10px 60px;
      text-align: center;
      font-size: 16px;
      color: #FFFFFF;
      width: 200px;
      margin: 10px 0 5px 0;
    }

    .skip-button {
      background-color: #808080;
    }

    .continue-button {
      background-color: #28446A;

      &:hover {
        filter: brightness(125%);
      }
    }
  }
}