.store-sidebar {
  min-width: 270px;
  max-width: 270px;
  display: flex;
  flex-direction: column;

  .store-image-and-name {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 5px 20px 0 20px;

    .store-image {
      width: 220px;
      height: 210px;
      border: solid 2px #55A08D;

      @media (max-width: 980px) {
        display: none;
      }
    }

    .upload-avatar-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;


      @media (max-width: 980px) {
        display: none;
      }
      
      .avatar-image-upload {
        visibility: hidden;
        height: 0;
      }

      .upload-avatar {
        background-color: #28446a;
        color: #FFFFFF;
        border-radius: 5px;
        padding: 8px 20px;
        width: 160px;
        font-size: 14px;
        margin: 15px auto 0 auto;
        text-align: center;

        &:hover {
          cursor: pointer;
          opacity: 0.9;
          filter: brightness(125%);
        }
      }
    }

    .view-for-text {
      font-weight: 900;
      font-size: 14px;
      margin-top: 10px;
    }
  }

  .store-navigation-list {
    margin: 5px 20px 0 20px;
  }
}
