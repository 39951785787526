.map-iframe {
  .mapboxgl-popup-content {
    padding: 10px;
    border-radius: 37px;
    height: 200px;
    width: 220px;

    .mapboxgl-popup-close-button {
      top: 20px;
      right: 20px;
      z-index: 1;
    }
  }
}

.popup-shift-container {
  height: 100%;
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 10px inset;
  border-radius: 30px;

  .popup-shift-carousel {
    height: 100%;
    width: 100%;
    border-radius: 30px;
    position: relative;

    .direction-shift {
      position: absolute;
      font-size: 12px;
      bottom: 20px;
      left: 20px;
    }

    .dot-container {
      display: flex;
      justify-content: center;

      .dot-styles {
        margin: 0 3px;
        cursor: pointer;
        font-size: 20px;
      }
    }

    .left-arrow {
      position: absolute;
      left: -15px;
      background-color: #000000;
      border-radius: 50%;
      box-shadow: transparent 0px 0px 0px 1px, transparent 0px 0px 0px 4px, rgb(0 0 0 / 18%) 0px 2px 4px !important;
      cursor: pointer;
      width: 25px;
      height: 25px;
      top: 45%;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 2;

      .slide-arr-left {
        filter: brightness(100);
        transform: rotate(90deg);
      }
    }

    .right-arrow {
      position: absolute;
      right: -15px;
      background-color: #000000;
      border-radius: 50%;
      box-shadow: transparent 0px 0px 0px 1px, transparent 0px 0px 0px 4px, rgb(0 0 0 / 18%) 0px 2px 4px !important;
      cursor: pointer;
      width: 25px;
      height: 25px;
      top: 45%;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 2;

      .slide-arr-right {
        filter: brightness(100);
        transform: rotate(-90deg);
      }
    }

    .carousel-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      width: 100%;
      height: 100%;
      position: absolute;
      transition: all 0.3s;
      padding: 10px;
      box-sizing: border-box;
      opacity: 0;

      &.active-shift {
        opacity: 1;
        z-index: 1;
      }

      .shift-tag {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        width: 100%;
        height: 100%;

        .shift-title {
          display: flex;
          flex-direction: column;
          text-align: center;
          .shift-name {
            font-size: 16px;
          }
          .shift-address {
            font-size: 12px;
            color: #55A08D;
          }
        }
        .shift-time {
          display: flex;
          flex-direction: column;
          align-items: center;
          font-size: 12px;
          .shift-date {
            font-size: 300;
          }
          .shift-hours {
  
          }
        }
  
        .store-shifts {
          padding: 5px 50px;
          background-color: #55A08D;
          border-radius: 30px;
          color: #FFFFFF;
        }
        .shift-distance {
          font-size: 14px;
          color: #55A08D;
        }
        .shift-rate {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          .shift-rate-display {
            font-size: 14px;
          }
          .shift-nego {
            font-size: 12px;
            font-weight: 300;
            font-style: italic;
          }
        }
      }
    }
  }
}