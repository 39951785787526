.store-profile-modal-wrapper.subscribe {
  .store-profile-modal {
    width: 600px;

    @media (max-width: 720px) {
      width: 540px;
    }

    @media (max-width: 600px) {
      width: 450px;
    }

    @media (max-width: 480px) {
      width: 380px;
    }

    @media (max-width: 420px) {
      width: 320px;
    }

    @media (max-width: 360px) {
      width: 280px;
    }

    .modal-content {
      padding: 20px;

      @media (max-width: 360px) {
        padding: 15px;
      }

       .modal-title {
        @media (max-width: 420px) {
          font-size: 14px;
        }
      }
    }
  }
}