.review-store-input-container {
  display: flex;
  flex-flow: column;
  margin: 10px 0;

  .review-store-input-label {
    font: 400 12px Inter, Helvetica, Arial, sans-serif;
    color: #1A1A1A;
    margin: 5px 0;

    @media (max-width: 420px) {
      margin: 3px 0;
    }
  }

  .review-store-input {
    padding: 5px;
    height: 20px;
    border: none;
    background: #F6F5F5;
    border-radius: 4px;
    font: 400 15px Inter, Helvetica, Arial, sans-serif;
  }
}