.add-review-button {
  background-color: #FDCA38;
  text-align: center;
  color: #FAFAFA;
  font: 500 16px Inter, Helvetica, Arial, sans-serif;
  padding: 15px 35px;
  width: 250px;
  border-radius: 30px;

  @media (max-width: 480px) {
    padding: 12px 20px;
    width: 200px;
    font-size: 15px;
  }

  @media (max-width: 420px) {
    padding: 10px 20px;
    width: 180px;
    font-size: 14px;
  }
}