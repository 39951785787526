.information-page {
  margin: 30px;

  @media (max-width: 480px) {
    margin: 15px;
  }

  iframe {
    overflow-y: auto;
  }

  .information-title {
    font: 900 28px Arial;
    padding: 30px 0 0 0;
    margin: 0 0 10px 0;
  }

  .update-date {
    font: 100 16px Arial;
    font-style: italic;
  }

  .subtitle {
    font: 100 16px Arial;
    margin: 20px 0 0 0;
    text-align: center;
  }

  iframe {
    border: 2px solid #000000;
  }

  .terms {
    width: 100%;
    height: 1200px;
    margin: 20px 0;
  }

  .about-page {
    padding: 30px 0 0 0;

    .title {
      font: 900 32px Arial;
    }

    .paragraph {
      margin: 20px 50px 20px 0;

      @media (max-width: 800px) {
        margin: 20px 0;
      }
    }
  }
}