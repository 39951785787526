.profile-dropdown-container {
  margin-left: 10px;
  @media (max-width: 480px) {
    margin-left: 0;
  }
  .profile-dropdown {
    background: #56A08D;
    border-radius: 200px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 95px;
    padding: 4px 15px;
  
    .hamburger {
      margin-right: 22px;
      min-width: 22px;
    }
  
    .profile-icon-container {
      .profile-icon {
        position: relative;
        height: 26px;
        width: 26px;
        top: 2px;
      }
    }
  }
}

.dropdown-list-container {
  position: absolute;

  @media (max-width: 480px) {
    bottom: 60px;
  }

  .profile-dropdown-list {
    padding: 20px 0;
    background: #FFFFFF;
    position: relative;
    top: 10px;
    right: 110px;
    border-radius: 14px;
    width: 200px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    display: flex;
    flex-direction: column;
  
    .link {
      align-self: flex-start;
      width: 100%;
      display: flex;

      &.not-mobile {
        @media (max-width: 480px) {
          display: none;
        }
      }

      .navigational-button {
        font: 800 16px avenir;
        padding: 10px 10px 10px 20px;
        text-align: left;
        align-self: flex-start;
        width: 100%;

        &.mobile-only {
          display: none;

          @media (max-width: 750px) {
            display: block;
          }
        }

        &.web-only {
          @media (max-width: 480px) {
            display: none;
          }
        }

        .dropdown-item {
          font-size: 14px;
          font-weight: 300;
          padding: 10px 0 8px 20px;
          z-index: 4;
          background-color: #FFFFFF;
          border-bottom-color: #d4d6db;
          border-bottom-width: 1px;
          border-bottom-style: solid;

          &:hover {
            cursor: pointer;
            color: #56A08D;
          }
        }
      }
    }

    .divider {
      margin: 10px 15px;
      border-bottom: 1.5px solid #ECECEC;
    }
  }
}

.hamburger {
  background-color: transparent;

  .line {
    width: 18px;
    border-radius: 4px;
    height: 2.5px;
    background-color: #FFFFFF;
    display: block;
    margin: 5px auto;
    transition: all 0.3s ease-in-out;
  }

  &.open {
    .line:nth-child(1) {
      transform: translateY(7px) rotate(45deg);
      width: 22px;
    }

    .line:nth-child(2) {
      opacity: 0;
    }

    .line:nth-child(3) {
      transform: translateY(-8px)rotate(-45deg);
      width: 22px;
    }
  }
}

.mobile-profile-dropdown {
  position: relative;

  .mobile-profile-dropdown-list {
    position: absolute;
    right: -25px;
    bottom: 48px;
    display: flex;
    flex-direction: column;
    font-size: 16px;
    width: 300px;
    text-align: left;
    z-index: 4000;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

    @media (max-width: 720px) {
      font-size: 18px;
    }

    .mobile-profile-name-and-info {
      display: flex;
      flex-direction: column;
      background-color: #F6F8FE;
      padding: 10px 0 5px 20px;
      z-index: 4000;

      @media (max-width: 720px) {
        padding: 15px 0 10px 20px;
      }

      &:hover {
        cursor: auto;
      }

      .profile-name {
        font-weight: 600;
      }

      .profile-type {
        font-weight: 400;
        font-size: 14px;
        color: #BEC4DB;
        padding-top: 2px;
      }
    }

    .profile-link {
      text-decoration: none;
      color: #000000;
      border: none;
      height: auto;
      appearance: none;
      padding: 0;
      text-align: left;
      z-index: 1000;

      &.mobile-only {
        display: none;

        @media (max-width: 750px) {
          display: block;
        }

        @media (max-width: 480px) {
          display: none;
        }
      }

      &.web-only {
        @media (max-width: 480px) {
          display: none;
        }
      }

      .dropdown-item {
        font-size: 14px;
        font-weight: 300;
        padding: 10px 0 8px 20px;
        z-index: 4;
        background-color: #FFFFFF;
        border-bottom-color: #d4d6db;
        border-bottom-width: 1px;
        border-bottom-style: solid;

        @media (max-width: 720px) {
          padding: 8px 0 8px 20px;
          font-size: 15px;
        }

        &:hover {
          cursor: pointer;
          filter: brightness(90%);
          background-color: rgb(231, 231, 227);
        }
      }
    }

    .logout-button-container {
      background-color: #FFFFFF;

      .logout-button {
        border: none;
        font-size: 14px;
        background-color: transparent;
        box-shadow: none;
        text-align: left;
        width: 100%;
        padding: 10px 0 10px 20px;

        @media (max-width: 720px) {
          padding: 8px 0 8px 20px;
          font-size: 15px;
        }

        &:hover {
          cursor: pointer;
          filter: brightness(90%);
          background-color: rgb(231, 231, 227);
        }
      }
    }
  }
}

.mobile-inbox-dropdown {
  position: relative;

  .mobile-inbox-dropdown-list {
    position: absolute;
    right: -75px;
    bottom: 48px;
    display: flex;
    flex-direction: column;
    font-size: 16px;
    width: 300px;
    text-align: left;
    z-index: 4000;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

    @media (max-width: 720px) {
      font-size: 18px;
    }

    .inbox-link {
      text-decoration: none;
      color: #000000;
      border: none;
      height: auto;
      appearance: none;
      padding: 0;
      text-align: left;
      z-index: 1000;

      &.mobile-only {
        display: none;

        @media (max-width: 750px) {
          display: block;
        }

        @media (max-width: 480px) {
          display: none;
        }
      }

      .dropdown-item {
        font-size: 14px;
        font-weight: 300;
        padding: 10px 0 8px 20px;
        z-index: 4;
        background-color: #FFFFFF;
        border-bottom-color: #d4d6db;
        border-bottom-width: 1px;
        border-bottom-style: solid;

        @media (max-width: 720px) {
          padding: 8px 0 8px 20px;
          font-size: 15px;
        }

        &:hover {
          cursor: pointer;
          filter: brightness(90%);
          background-color: rgb(231, 231, 227);
        }
      }
    }

    .logout-button-container {
      background-color: #FFFFFF;

      .logout-button {
        border: none;
        font-size: 14px;
        background-color: transparent;
        box-shadow: none;
        text-align: left;
        width: 100%;
        padding: 10px 0 10px 20px;

        @media (max-width: 720px) {
          padding: 8px 0 8px 20px;
          font-size: 15px;
        }

        &:hover {
          cursor: pointer;
          filter: brightness(90%);
          background-color: rgb(231, 231, 227);
        }
      }
    }
  }    
}