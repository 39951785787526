$cloudfront-cdn-url: 'https://dq55dzprwc7f6.cloudfront.net';

.post-page, .how-it-works-page {
  .green-button {
    background: #55A08D;
    border-radius: 50px;
    font: 900 18px avenir;
    line-height: 20px;
    width: 250px;
    color: #FFFFFF;
    padding: 12px 10px;
  }

  .title-banner {
    display: flex;
    justify-content: center;
    padding-top: 40px;

    .staffing-banner {
      display: flex;
      flex-direction: column;
      width: 500px;
      margin: 160px 60px 0 0;

      @media (max-width: 1200px) {
        width: 360px;
        margin: 100px 20px 0 0;
      }

      .title {
        color: #10161E;
        font: 900 44px avenir;
        line-height: 54px;

        @media (max-width: 1200px) {
          font: 900 32px avenir;
        }
      }

      .subtitle {
        font: 100 22px avenir;
        line-height: 28px;
        margin: 20px 0;

        @media (max-width: 1200px) {
          font: 900 18px avenir;
        }
      }

      .green-button {
        margin: 10px 0;
      }
    }

    .title-image {
      width: 450px;
      height: 550px;
      
      @media (max-width: 1200px) {
        width: 360px;
        height: 420px;
      }
    }
  }

  .banner-content {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: 100px 0;

    .title {
      font: 900 42px avenir;
      line-height: 48px;
    }

    .subtitle {
      font: 100 22px avenir;
      margin: 20px 0;
    }

    .image-array {
      display: flex;
      justify-content: space-evenly;
      width: 1000px;
      margin-top: -80px;

      @media (max-width: 1200px) {
        width: 800px;
      }

      img {
        width: 350px;
        height: 350px;

        @media (max-width: 1200px) {
          width: 300px;
          height: 300px;
          margin-top: 40px;
        }
      }
    }
  }

  .card-array {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin: auto;
    width: 1040px;

    &.advantage-array {
      width: 780px;
      margin-top: -40px;

      @media (max-width: 480px) {
        width: 320px;
      }
    }
  }

  .long-card {
    background: #FFFFFF;
    border: 0.5px solid #CBCBCB;
    box-shadow: -4px 5px 14px rgba(0, 0, 0, 0.05);
    border-radius: 20px;
    box-sizing: border-box;
    width: 280px;
    height: 450px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px 30px;

    &.advantage {
      height: 470px;
      padding: 25px 30px;
      justify-content: space-between;
      width: 320px;

      @media (max-width: 420px) {
        width: 300px;
      }
    }

    .main-title {
      font: 900 26px avenir-bold;
      align-self: flex-start;
      line-height: 36px;
    }

    .subtitle {
      color: #55A08D;
      font: 100 16px avenir;
      line-height: 22px;
      margin: 10px 0;
    }

    .title {
      font: 900 22px avenir-bold;
      align-self: flex-start;
      margin: 10px 0;

      .large {
        font-family: avenir-bold;
        font-size: 24px;
      }
      
      .small {
        font-family: avenir;
        font-size: 16px;
        margin-left: 5px;
      }
    }

    .advantage-list {
      list-style-image: url($cloudfront-cdn-url + '/icons/tickmark-icon.svg');
      margin-left: 10px;

      .advantage {
        margin: 10px 0;
      }
    }

    .green-button {
      width: 180px;
      font-size: 15px;
      margin-top: 25px;
    }

    .image-container {
      align-items: center;
      margin-bottom: 20px;

      .card-image {
        height: 150px;
        width: 150px;
      }
    }

    .content {
      .title {
        font: 900 20px mundo;
      }

      p {
        font: 100 14px mundo;
        margin: 12px 0;
      }
    }
  }

  .how-it-works-block {
    display: flex;
    flex-direction: column;
    align-items: center;

    .title {
      font: 900 42px avenir;
      line-height: 48px;
    }

    .step .step-text, .step-paragraph {
      margin-bottom: 10px;
    }
  }

  .how-it-works {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 100px 0;

    .vertical-line {
      height: 1100px;
      border-left: solid 1.5px #55A08D;
      position: absolute;
    }

    .step-column {
      display: flex;
      flex-direction: column;
      margin: 0 60px;
    }

    .step-counters {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;

      .step-number-container {
        margin: 130px 0 170px 0;
      }
    }

    .step-number-container {
      width: 61px;
      height: 61px;
      z-index: 2;
      
      .step-number {
        font: 900 23px avenir;
        border-radius: 200px;
        background-color: #55A08D;
        color: #FFFFFF;
        width: 61px;
        height: 61px;
        display: block;
        line-height: 61px;
        text-align: center;
      }
    }

    .step-text, .step-image {
      height: 300px;
      display: flex;
      flex-direction: column;
      margin: 20px 0;
    }

    .step-image {
      align-items: center;

      img {
        width: 262px;
        height: 262px;
      }
    }

    .step-text {
      width: 400px;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .step-pargraph {
        margin-bottom: 10px;
      }

      .step-header {
        font: 900 22px avenir-bold;
        color: #313131;
        margin-bottom: 30px;
      }
    }
  }

  .testimonial-carousel {
    display: flex;
    margin: auto;
    width: fit-content;
    margin: -40px auto 0 auto;

    .testimonial {
      width: 450px;
      height: 300px;
      margin: 0 120px;
      display: flex;
      align-items: center;
      flex-direction: column;

      .image-container {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100px;
        height: 100px;
        border-radius: 200px;
        margin: 0 0 20px 0;

        &.default {
          background-color: #55A08D;
        }
      }

      .quote {
        font: 100 16px avenir;
        margin: 10px;
        text-align: center;
      }

      .name {
        font: 900 16px avenir-bold;
        margin: 15px 0 5px 0;
      }

      .title {
        font: 100 16px avenir;
      }
    }
  }

  .bottom-banner {
    background-color: #313131;
    color: #FFFFFF;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 80px 0;
    margin-top: 80px;

    .title {
      margin: 20px 0;
      font: 700 32px mundo-bold;
      line-height: 38px;
    }

    .subtitle {
      margin-bottom: 30px;
      font: 400 18px avenir;
      line-height: 25px;
    }
  }
}

.post-page.mobile, .how-it-works-page.mobile {
  .title-banner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding-top: 0;

    .staffing-banner {
      margin: 50px 20px;
      width: 300px;
      text-align: center;

      @media (max-width: 480px) {
        width: 280px;
        margin: 50px 10px 30px 10px;
      }
      
      .title {
        font: 900 36px avenir;
        line-height: 52px;

        @media (max-width: 480px) {
          font: 900 32px avenir;
          line-height: 54px;
        }
      }
    }

    .title-image {
      width: 360px;
      height: 400px;

      @media (max-width: 480px) {
        width: 300px;
        height: 360px;
      }
    }

    .subtitle {
      font: 100 24px avenir;
      line-height: 28px;
      margin: 60px 80px 40px 80px;
      text-align: center;

      @media (max-width: 480px) {
        font: 100 20px avenir;
        margin: 40px 40px;
      }
    }
  }

  .banner-content {
    display: flex;
    text-align: center;
    margin: 100px 40px 10px 40px;

    @media (max-width: 480px) {
      margin: 100px 10px 10px 10px;

      .title {
        font: 900 32px avenir;
      }

      .subtitle {
        font-size: 18px;
      }
    }
  }

  .card-array {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: auto;

    .long-card {
      margin: 20px 0;
    }
  }

  .image-array {
    margin: -20px 20px 60px 20px;
    width: auto;

    @media (max-width: 480px) {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    img {
      height: auto;
      width: 30%;

      @media (max-width: 480px) {
        width: 80%;
      }
    }
  }

  .how-it-works-block {

    @media (max-width: 480px) {
      .title {
        font: 900 32px avenir;
      }
    }

    .how-it-works {
      display: flex;
      flex-direction: column;
      padding: 0;
      margin-top: 20px;

      .step {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        margin: 20px 20px;

        .step-number-container {
          margin: 25px 0;
        }

        .step-image {
          height: auto;
        }

        .step-text {
          height: auto;

          .step-paragraph {
            margin-bottom: 10px;
          }

          @media (max-width: 480px) {
            .step-header {
              font: 900 18px avenir-bold;
              padding: 0 50px;
            }

            .step-paragraph {
              font: 900 14px avenir;
              padding: 0 50px;
            }
          }
        }
      }
    }
  }

  .testimonial-carousel {
    margin: 50px auto;
    text-align: center;

    .testimonial {
      margin: 0 20px;
      width: 360px;

      @media (max-width: 480px) {
        width: 300px;
        height: 360px;
      }

      @media (max-width: 420px) {
        margin: 0;
        width: 280px;
        height: 360px;
      }

      @media (max-width: 360px) {
        margin: 0;
        width: 250px;
        height: 360px;
      }
    }
  }

  .card-array.advantage-array {
    margin-top: 20px;
  }

  .bottom-banner {
    text-align: center;


    @media (max-width: 480px) {
      .title {
        font-size: 28px;
      }

      .subtitle {
        font-size: 16px;
        margin: 0 20px 30px 20px;
      }
    }
  }
}