.store-page {
  display: flex;
  flex-direction: row;
  margin: 0 35px 35px;
  padding: 30px 0 0 0;

  @media (max-width: 1150px) {
    margin-left: 0;
  }

  .store-dropdown-container {
    display: none;
    flex-direction: column;
    align-items: center;
  
    .store-image {
      width: 220px;
      height: 210px;
      border: solid 2px #55A08D;
    }

    .upload-avatar-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      
      .avatar-image-upload {
        visibility: hidden;
        height: 0;
      }

      .upload-avatar {
        background-color: #28446a;
        color: #FFFFFF;
        border-radius: 5px;
        padding: 8px 20px;
        width: 160px;
        font-size: 14px;
        margin: 15px auto 0 auto;
        text-align: center;

        &:hover {
          cursor: pointer;
          opacity: 0.9;
          filter: brightness(125%);
        }
      }
    }

    .view-for-text {
      font-weight: 900;
      font-size: 14px;
      margin-top: 10px;
    }


    .store-dropdown {
      .store-selector {
        width: 284px;

        .store-text {
          width: 254px;
          text-align: center;
        }
      }

      .store-selection-container {
        .store-list {
          width: 282px;
          overflow-y: auto;
          max-height: 420px;
        }
      }
    }
  }

  .store-sidebar-container {
    .store-dropdown {
      display: flex;
    }
  }

  @media (max-width: 980px) {
    display: flex;
    flex-direction: column;
    margin: 0 35px 35px 35px;

    .store-dropdown-container {
      display: flex;
    }

    .store-sidebar-container {
      display: none;
    }
  }

  @media (max-width: 620px) {
    margin: 0 10px 35px 10px;
  }
}
