.review-dropdown {
  padding: 5px 0;
  height: 20px;
  color: #1A1A1A;
  background: #F7F5F5;
  border-radius: 4px;
  border: none;
  display: flex;
  flex-flow: column;

  .store-profile-dropdown-button {
    .button-text {
      font: 400 15px Inter, Helvetica, Arial, sans-serif;
    }
  }

  .dropdown {
    position: relative;
    height: 0;

    .dropdown-list {
      position: absolute;
      z-index: 1;
      display: flex;
      flex-direction: column;
      background: #F7F5F5;
      border-top: none;
      width: 100%;
      align-items: flex-start;


      .review-dropdown-option {
        width: 100%;
        
        .review-option {
          background: #F7F5F5;
          width: 100%;
          padding: 6px 0;
        }
      }
    }
  }
}