.manage-preferences-page {
  display: flex;
  flex-direction: column;
  width: calc(100% - 250px);

  @media (max-width: 980px) {
    width: 100%;
  }

  @media (max-width: 480px) {
    align-items: center;
  }

  .title {
    font: 400 24px inter-bold;
    margin: 10px 0;
    color: #1a1a1a;
    
    @media (max-width: 480px) {
      text-align: center;
    }
  }

  .subtitle {
    font: 100 14px montserrat-medium;
    margin: 30px 0 10px 0;

    @media (max-width: 1260px) {
      margin: 50px 0 10px 0;
      align-self: center;
    }

    @media (max-width: 420px) {
      margin: 50px 50px 10px 50px;
      text-align: center;
    }
  }

  .preferences {
    display: flex;

    @media (max-width: 1260px) {
      flex-direction: column;
      align-items: center;
    }
    
    .store-details {
      display: flex;
      flex-direction: column;
      margin-right: 20px;

      @media (max-width: 1260px) {
        margin-right: 0;
      }

      .store-detail-list {
        display: flex;
        flex-direction: column;
        max-height: 900px;
        overflow: auto;

        .store-detail-card {
          margin: 15px 10px 15px 10px;
          max-width: 280px;

          @media (max-width: 480px) {
            margin: 15px 5px;
            min-width: 280px;
            padding: 15px 10px;
          }
        }
      }
    }

    .manage-preferences {
      display: flex;
      flex-direction: column;
      margin-left: 10px;

      &.disabled {
        button, .dropdown-button {
          opacity: 0.6;

          &:hover {
            cursor: auto;
            filter: brightness(100%);
          }
        }

        button.edit-button {
          opacity: 1;

          &:hover {
            cursor: pointer;
          }

          &.very-dark:hover {
            filter: brightness(110%);
          }

          &.very-light:hover {
            filter: brightness(90%);
          }
        }
      }

      .preference-question {
        display: flex;
        flex-direction: column;
        margin: 10px 0;

        .delete-button {
          background: #1A1A1A;
          color: #FFFFFF;
          border-radius: 200px;
          margin-right: 10px;
          padding: 2px 6px 3px 6px;

          &:hover {
            background: #FF0000;
            color: #FFFFFF;
          }
        }

        .question {
          display: flex;
          flex-direction: row;

          .text {
            font: 700 15px montserrat-medium;

            @media (max-width: 420px) {
              margin: 0 40px;
              text-align: center;
            }
          }
        }

        .dropdown-button {
          margin-top: 15px;

          .dropdown-view-button {
            width: 200px;
            padding: 8px 10px 8px 5px;
            font: 400 14px montserrat-medium;

            @media (max-width: 420px) {
              margin: 0 auto;
            }
          }

          .dropdown-list-container {
            .dropdown-list {
              width: 200px;

              .dropdown-item {
                .dropdown-option {
                  font-size: 13px;
                }
              }
            }
          }
        }
      }

      .buttons {
        display: flex;
        justify-content: center;
        margin-top: 20px;

        .reset-button, .edit-button {
          margin: 15px;
          font: 400 16px avenir;
          border-radius: 200px;
          padding: 10px 25px;
          width: 140px;
          text-align: center;
          color: #FFFFFF;

          @media (max-width: 420px) {
            width: 130px;
          }
        }

        .reset-button {
          background: #636363;
        }

        .edit-button {
          background: #56A08D;
        }
      }
    }
  }


  
  .manage-preferences-subtitle {
    color: #1A1A1A;
    margin: 5px 0;
    font-size: 16px;

    @media (max-width: 480px) {
      text-align: center;
    }
  }

  .preferences-container {
    height: 880px;
    margin-top: 20px;
    display: flex;
    flex-direction: row;

    @media (max-width: 720px) {
      height: auto;
    }

    .preferences-title {
      font-weight: 600;
      font-size: 16px;

      @media (max-width: 420px) {
        font-size: 12px;
      }
    }
  }

  .preference-list-container {
    padding: 10px 20px 20px 20px;
    margin-left: 10px;
    height: 820px;

    @media (max-width: 420px) {
      margin-left: 5px;
    }

    .preference-list {
      .preference-item {
        &.one {
          margin: 27.5px 0 0 0;
        }
  
        &.two {
          margin: 70px 0 0 0;
        }
  
        &.three {
          margin: 70px 0 0 0;
        }
  
        &.four {
          margin: 65px 0 0 0;
        }
  
        &.five {
          margin: 35px 0 0 0;
        }
  
        &.six {
          margin: 25px 0 0 0;
        }
  
        &.seven {
          margin: 40px 0 0 0;
        }
      }
    }

    @media (max-width: 1150px) {
      padding: 10px 0;
      width: 290px; 
        
      .preference-list {
        .preference-selection {
          .selection-button {
            width: 135px;
          }

          .dropdown-button {
            .data-view-button {
              width: 280px;
            }
          }

          .time-preferences, .other-preferences {
            width: 256px;
          }

          &.software .selection-button {
            width: 90px;
          }

          &.equipment .selection-button {
            width: 90px;
          }
        }
      }
    }

    @media (max-width: 720px) {
      width: 220px;
      height: 880px;
        
      .preference-list {
        .preference-selection {
          .first-row, .second-row {
            justify-content: space-between;
            display: flex;
          }

          .selection-button {
            width: 105px;
          }

          .dropdown-button {
            .data-view-button {
              width: 220px;
            }
          }

          .time-preferences, .other-preferences {
            width: 196px;
            height: 80px;
          }

          &.software .selection-button {
            width: 70px;
            margin-right: 0px;
            font-size: 10px;
          }

          &.equipment .selection-button {
            width: 70px;
            margin-right: 0px;
            font-size: 10px;
          }
        }
      }
    }

    .preference-list {
      .preference-item {
        &.one {
          margin: 35px 0 0 0;
        }

        &.two {
          margin: 60px 0 0 0;
        }

        &.three {
          margin: 72.5px 0 0 0;
        }

        &.four {
          margin: 70px 0 0 0;
        }

        &.five {
          margin: 35px 0 0 0;
        }

        &.six {
          margin: 40px 0 0 0;
        }

        &.seven {
          margin: 20px 0 0 0;
        }
      }
    }

    @media (max-width: 550px) {
      .preference-list {
        .preference-item {
          &.one {
            margin: 40px 0 0 0;
          }
  
          &.two {
            margin: 95px 0 0 0;
          }
  
          &.three {
            margin: 85px 0 0 0;
          }
  
          &.four {
            margin: 85px 0 0 0;
          }
  
          &.five {
            margin: 40px 0 0 0;
          }
  
          &.six {
            margin: 70px 0 0 0;
          }
  
          &.seven {
            margin: 60px 0 0 0;
          }
        }
      }
    }

    @media (max-width: 480px) {
      width: 145px;
        
      .preference-list {
        .preference-selection {
          .first-row, .second-row {
            justify-content: space-between;
            display: flex;
            flex-direction: column;
            margin-bottom: 0;
            margin-right: 5px;
          }

          &.software, &.equipment {
            flex-direction: row;
          }

          .selection-button {
            width: 67.5px;
            font-size: 14px;
          }

          .dropdown-button {
            .data-view-button {
              width: 145px;
              font-size: 14px;
            }
          }

          .time-preferences, .other-preferences {
            width: 121px;
            height: 100px;
            font-size: 14px;
          }

          &.software,
          &.equipment {
            .selection-button {
            margin-bottom: 5px;
          }
        }
      }

      .preference-item {
        &.one {
          margin: 40px 0 0 0;
        }
  
        &.two {
          margin: 90px 0 0 0;
        }
  
        &.three {
          margin: 90px 0 0 0;
        }
  
        &.four {
          margin: 75px 0 0 0;
        }
  
        &.five {
          margin: 10px 0 0 0;
        }
  
        &.six {
          margin: 20px 0 0 0;
        }
  
        &.seven {
          margin: 15px 0 0 0;
        }
      }
    }

    @media (max-width: 420px) {
      width: 135px;
        
      .preference-list {
        .preference-selection {
          .first-row, .second-row {
            margin-right: 5px;
          }

          .selection-button {
            width: 60px;
            font-size: 12px;
          }

          .dropdown-button {
            .data-view-button {
              font-size: 12px;
              width: 130px;
            }
          }

          .time-preferences, .other-preferences {
            width: 106px;
            height: 95px;
            font-size: 12px;
          }

          &.software,
          &.equipment {
            .selection-button {
              font-size: 8px;
              width: 62.5px;
            }
          }
        }

        .preference-item {
          &.one {
            margin: 40px 0 0 0;
          }
    
          &.two {
            margin: 100px 0 0 0;
          }
    
          &.three {
            margin: 90px 0 0 0;
          }
    
          &.four {
            margin: 65px 0 0 0;
          }
        }
      }
    }
  }
}

  .confirm-buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 75%;

    @media (max-width: 720px) {
      margin-top: 20px;
    }

    @media (max-width: 480px) {
      width: 100%;
    }

    .cancel-button {
      width: 110px;
      padding: 10px 0px;
      border-radius: 6px;
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
      background-color:  #636363;
      text-align: center;
      font-size: 18px;
      color: #FFFFFF;

      @media (max-width: 480px) {
        padding: 8px 0;
        font-size: 16px;
      }

      &:hover {
        cursor: pointer;
        filter: brightness(110%);
      }
    }

    .save-preferences-button {
      width: 200px;
      padding: 10px 0px;
      border-radius: 6px;
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
      background-color: #55a08d;
      text-align: center;
      font-size: 18px;
      color: #FFFFFF;

      @media (max-width: 480px) {
        width: 160px;
        padding: 8px 0;
        font-size: 16px;
      }

      &:hover {
        cursor: pointer;
        filter: brightness(90%);
      }

      &.disabled {
        opacity: 0.5;

        &:hover {
          cursor: auto;
          filter: brightness(100%);
        }
      }
    }
  }
}

.preference-labels {
  background-color: #E3E1D6;
  padding: 10px 20px 20px 20px;
  min-width: 275px;
  max-width: 275px;
  height: 820px;

  @media (max-width: 1150px) {
    padding: 10px;
    min-width: 250px;
    min-height: 250px;

    .preference-label-list {
      .preference-label {
        font-size: 15px;

        &.one {
          margin: 32.5px 0 50px 0;
        }
      }
    }
  }

  @media (max-width: 720px) {
    min-width: 180px;
    max-width: 180px;
    height: 900px;
  }

  @media (max-width: 550px) {
    min-width: 120px;
    max-width: 120px;
    height: 960px;
  }

  @media (max-width: 420px) {
    padding: 5px;
    min-width: 90px;
    max-width: 90px;
  }

  .preference-label-list {
    .preference-label {
      font-size: 16px;
      font-weight: 100;
      margin: 75px 0;
      font-family: Arial, Helvetica, sans-serif;

      @media (max-width: 550px) {
        font-size: 14px;
      }

      @media (max-width: 420px) {
        font-size: 12px;
      }

      &.one {
        margin: 30px 0 50px 0;
      }
    }
  }
}

.compulsory-list-container {
  padding: 10px 20px 20px 20px;
  margin-left: 10px;
  height: 820px;

  @media (max-width: 1150px) {
    padding: 10px 0;
  }

  .compulsory-list {
    display: flex;
    flex-direction: column;
    margin-left: 15px;

    @media (max-width: 420px) {
      margin-left: 5px;
    }

    .one {
      margin-top: 40px;
    }

    .three {
      margin-top: 185px;
    }

    .four {
      margin-top: 210px;
    }

    .five {
      margin-top: 100px;
    }

    @media (max-width: 980px) {
      .one {
        margin-top: 32.5px;
      }

      .three {
        margin-top: 195px;
      }

      .four {
        margin-top: 205px;
      }

      .five {
        margin-top: 105px;
      }
    }

    @media (max-width: 720px) {
      .one {
        margin-top: 40px;
      }

      .three {
        margin-top: 187.5px;
      }

      .four {
        margin-top: 230px;
      }

      .five {
        margin-top: 100px;
      }
    }

    @media (max-width: 550px) {
      .one {
        margin-top: 45px;
      }

      .three {
        margin-top: 235px;
      }

      .four {
        margin-top: 250px;
      }

      .five {
        margin-top: 130px;
      }
    }

    @media (max-width: 480px) {
      .one {
        margin-top: 47.5px;
      }

      .three {
        margin-top: 232.5px;
      }

      .four {
        margin-top: 252.5px;
      }

      .five {
        margin-top: 127.5px;
      }
    }

    @media (max-width: 420px) {
      .one {
        margin-top: 47.5px;
      }

      .three {
        margin-top: 242.5px;
      }

      .four {
        margin-top: 237.5px;
      }

      .five {
        margin-top: 127.5px;
      }
    }
  }
}

.switch {
  position: relative;
  display: inline-block;
  width: 54px;
  height: 26px;
  margin-top: 27px;

  .compulsory-title {
    font-size: 22px;
    position: relative;
    top: -60px;
    left: -25px;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;

    &:hover {
      cursor: pointer;
      filter: brightness(90%);
    }

    &:before {
      position: absolute;
      content: "";
      height: 20px;
      width: 20px;
      left: 3px;
      bottom: 3px;
      background-color: white;
      -webkit-transition: .4s;
      transition: .4s;
    }

    &.round {
      border-radius: 34px;

      &:before {
        border-radius: 50%;
      }
    }
  }

  .click-input {
    opacity: 0;
    width: 0;
    height: 0;

    &.checked {
      +.slider {
        background-color: #56A08D;

        &:hover {
          cursor: pointer;
          filter: brightness(110%);
        }

        &:before {
          -webkit-transform: translateX(28px);
          -ms-transform: translateX(28px);
          transform: translateX(28px);
        }
      }
    }

    &.disabled {
      +.slider {
        &:hover {
          cursor: auto;
          filter: brightness(100%);
        }
      }
    }

    &:focus + .slider {
      box-shadow: 0 0 1px #56A08D;
    }
  }
}

.manage-preferences-page {
  .dropdown-button {
    .data-view-button {
      background-color: #56A08D;
      color: #908F8C;
      border-radius: 2px;
      width: 310px;
      padding: 10px 15px;
      font-size: 16px;
      text-align: center;
      height: 40px;
      color: #FFF;
      border-radius: 10px;

      &.open {
        border-radius: 10px 10px 0 0;
      }

      .down-arrow, .up-arrow {
        border: solid #FFF;
        border-width: 0 2px 2px 0;
        padding: 3px;
        position: relative;
        float: right;
      }

      .down-arrow {
        transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
        top: 2px;
      }

      .up-arrow {
        transform: rotate(-135deg);
        -webkit-transform: rotate(-135deg);
        top: 6px;
      }

      &:hover {
        cursor: pointer;
        filter: brightness(110%);
      }
    }
  }
}

.preference-selection {
  .selection-button {
    background-color: #E3E1D7;
    color: #908F8C;
    border: none;
    border-radius: 10px;
    width: 150px;
    font-size: 16px;
    text-align: center;
    height: 40px;

    &.yes {
      margin-right: 5px;
    }

    &.no {
      margin-left: 5px;
    }

    &:hover {
      cursor: pointer;
      filter: brightness(90%);
    }

    &.active {
      background-color: #56A08D;
      color: #FFF;

      &:hover {
        cursor: pointer;
        filter: brightness(110%);
      }
    }
  }

  .time-preferences,
  .other-preferences {
    width: 286px;
    padding: 5px 10px;
    border-radius: 10px;
    font-size: 14px;
    border: solid 2px #808080;
  }

  .time-preferences {
    height: 60px;
  }

  .other-preferences {
    height: 100px;
  }

  &.software {
    display: flex;
    flex-direction: column;

    .first-row {
      display: flex;
      margin-bottom: 5px;
    }

    .selection-button {
      font-size: 12px;
      width: 100px;
      margin-right: 5px;
    }
  }

  &.equipment {
    display: flex;
    flex-direction: column;

    .first-row {
      display: flex;
      margin-bottom: 5px;
    }

    .selection-button {
      font-size: 12px;
      width: 100px;
      margin-right: 5px;
    }
  }
}


.time {
  .dropdown-list-container {
    position: relative;

    .dropdown-list {
      display: flex;
      flex-direction: column;
      border: solid 1px #C0C5DC;
      background-color: #FFFFFF;
      border-top-width: 0px;
      z-index: 1;
      position: absolute;
      width: calc(100% - 2px);

      .dropdown-option {
        background-color: #FFFFFF;
        color: rgb(64, 64, 64);
        text-align: center;
        padding-left: 10px;
        padding-top: 10px;
        padding-bottom: 10px;
        width: 100%;

        &:hover {
          cursor: pointer;
          filter: brightness(90%);
        }
      }
    }
  }
}

.yes-no-answer {
  display: flex;
  justify-content: space-between;
  width: 240px;
  margin: 10px 0;

  @media (max-width: 420px) {
    margin: 10px auto;
  }

  .yes-no-button {
    background: #F6F5F5;
    color: #B5B5B5;
    font: 400 14px montserrat-medium;
    width: 110px;
    text-align: center;
    padding: 8px 0;
    border-radius: 6px;

    &.selected {
      color: #FFFFFF;
      background: #56A08D;

      &:hover {
        cursor: auto;
        filter: brightness(100%);
      }
    }
  }
}

.add-question {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 10px 0;

  @media (max-width: 420px) {
    align-items: center;
    margin: 10px auto;
  }

  .add-extra-question {
    color: #56A08D;
    font: 400 16px montserrat-medium;
    margin: 10px 0;
  }
  
  .extra-question-text-area {
    width: 420px;
    height: 60px;
    padding: 10px;
    font: 100 14px montserrat-medium;
    background: #F6F5F5;
    border-radius: 6px;
    color: #1A1A1A;

    @media (max-width: 480px) {
      width: 80%;
      height: 100px;
    }
  }
}

.multiselect {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 10px;

  .multiselect-buttons {
    display: flex;
    flex-wrap: wrap;
    max-width: 480px;

    @media (max-width: 480px) {
      max-width: 400px;
    }

    @media (max-width: 420px) {
      margin: 0 auto;
      max-width: 300px;
    }

    .multiselect-button {
      background: #f6f5f5;
      border-radius: 6px;
      color: #b5b5b5;
      font: 400 14px montserrat-medium;
      padding: 8px 20px;
      text-align: center;
      width: auto;
      margin: 0 15px 10px 0;

      &.selected {
        background-color: #56A08D;
        color: #FFFFFF;
      }
    }
  }

  .other-option {
    display: flex;
    align-items: center;

    @media (max-width: 420px) {
      flex-direction: column;
      margin: 10px auto;
    }

    .label {
      margin-right: 10px;
    }

    input {
      background-color: #F6F5F5;
      color: #1A1A1A;
      font: 400 14px montserrat-medium;
      margin: 10px 0;
      padding: 8px 10px;
      border: none;
      width: 280px;

      @media (max-width: 420px) {
        width: 240px;
      }
    }
  }
}

.additional-question-section {
  margin-top: 20px;

  .title {
    font: 700 16px montserrat-medium;
  }
}

