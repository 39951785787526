.store-reviewer {
  display: flex;
  align-self: center;

  .reviewer-icon {
    width: 45px;
    height: 45px;
    border-radius: 200px;
    border: solid 3px #24231F;
  }

  .reviewer-details {
    margin-left: 10px;
    display: flex;
    flex-flow: column;
    justify-content: center;

    .reviewer-title-container {
      .reviewer-title {
        font: 700 16px Inter, Helvetica, Arial, sans-serif;
      }

      .verified-icon {
        width: 16px;
        height: 16px;
        margin-left: 6px;
        position: relative;
        top: 2px;
      }
    }

    .duration {
      color: #24231F;
      margin: 2px 0 0 0;
      font: 400 13px Inter, Helvetica, Arial, sans-serif;
    }
  }
}