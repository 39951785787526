@font-face {
  font-family: mundo-bold;
  src: url('../../fonts/mundo-bold.otf');
}

.manage-account-page {
  display: flex;
  flex-direction: column;
  width: calc(100% - 260px);
  align-items: center;

  &.locum-acc-page {
    @media (max-width: 480px) {
      background-color: #CAE3DD;
    }
  }

  .manage-header {
    display: none;
    width: 100%;
    max-height: 50px;
    align-items: center;
    background-color: #CAE3DD;

    @media (max-width: 480px) {
      display: flex;
    }

    .logo-container {
      display: flex;
      justify-content: center;
      width: 100%;
      height: 100%;
  
      .locumly-logo {
        max-height: 40px;
        padding: 5px 0;
        width: auto;
      }
    }
  }

  @media (max-width: 980px) {
    width: 100%;
  }

  .manage-account-title {
    color: #000;
    font-size: 22px;
    font-weight: 900;
    margin: 10px 0;
  }

  .manage-account-subtitle {
    color: #1a1a1a;
    font-size: 16px;
    margin: 5px 0;
  }

  .change-password {

    @media (max-width: 980px) {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .change-password-title {
      font-size: 18px;
      font-weight: 900;
      margin: 20px 0 10px 0;
      width: 340px;
      text-align: center;

      @media (max-width: 360px) {
        width: 100%;
      }
    }

    .passwords {
      margin-top: 0;

      @media (max-width: 360px) {
        width: 260px;
        margin: 0 auto;
      }
    }
  }
}

.profile {
  padding: 0 0 80px 0;
  max-width: 750px;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  background-color: #FFFFFF;
  height: 100%;
  width: 100%;

  .setting-button {
    display: flex;
    justify-content: center;
    padding: 20px 0 10px 0;
    border-bottom: 0.2px solid #CECECE;

    &:last-of-type {
      border-bottom: none;
    }

    .open-checkbox-settings {
      color: #55A08D;
      width: 80%;
      text-align: left;
    }
  }
  
  .checkbox-modal {
    display: flex;
    position: fixed;
    padding: 20px;
    margin-top: 50px;
    background-color: #FFFFFF;
    z-index: 1000;
    border-radius: 30px;
    top: 100vh;
    transition: top .3s ease-in-out;
    width: calc(100% - 260px);
    box-sizing: border-box;

    @media (max-width: 980px) {
      width: 100%;
    }

    &.open {
      top: 0;
      height: 100%;
    }
  }

  .profile-details {
    padding: 0 0 10px 0;
    width: 100%;

    display: flex;
    flex-direction: column;

    @media (max-width: 480px) {
      
    }

    .checkbox-scroll {
      display: flex;
      flex-direction: column;
      overflow-y: scroll;
      height: calc(100% - 160px);
      padding-bottom: 40px;
      width: 100%;
      max-width: 800px;

      .checkbox-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        margin: 10px 0;

        .subscribed-stores-container {
          margin-left: 10px;
          .subscribed-stores {
            padding: 10px 0;
            .sub-store {
              cursor: pointer;
              text-decoration: underline;
              color: blue;
              margin: 5px 0;
            }
          }
        }

        .edit-label {
          margin-left: 10px;
          font-size: 12px;
          font-weight: 200;
      
          .title-label {
            font-size: 16px;
            font-weight: 400;
          }
      
          .subtitle-label {
            font-size: 14px;
          }
        }

        .position-container {
          display: flex;
          justify-content: flex-start;
          margin: 10px 0 0 10px;
          
          .max-dist-input {
            display: flex;
            align-items: center;
            position: relative;
  
            &::after {
              position: absolute;
              right: 5px;
              content: attr(data-placeholder);
              pointer-events: none;
              opacity: 0.6;
            }
  
            .max-dist {
              padding: 8px;
            }
          } 
        }

        .checkbox-group {
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          width: 100%;

          @media (max-width: 390px) {
            grid-template-columns: repeat(2, 1fr);
          }
        }
      }
    }

    .locum-avatar-comp {
      display: flex;
      flex-direction: column;
      align-items: center;

      @media (max-width: 720px) {
        // max-width: 300px;
      }

      @media (max-width: 480px) {
        max-width: 180px;
      }

      @media (max-width: 420px) {
        max-width: 160px;
      }

      @media (max-width: 360px) {
        max-width: 140px;
      }

      .locum-avatar-container {
        height: 180px;
        width: 180px;
        // margin: 30px auto 0 auto;
        border-radius: 200px;
        border: solid 2px #C0C5DA;
        padding: 3px;
  
        @media (max-width: 720px) {
          height: 120px;
          width: 120px;
          // margin: 10px 0;
        }

        @media (max-width: 360px) {
          height: 100px;
          width: 100px;
        }
  
        .locum-avatar {
          height: 180px;
          width: 180px;
          border-radius: 200px;
  
          @media (max-width: 720px) {
            height: 120px;
            width: 120px;
          }

          @media (max-width: 360px) {
            height: 100px;
            width: 100px;
          }
        }
      }

      .avatar-image-upload {
        visibility: hidden;
        height: 0;
      }

      .upload-avatar {
        color: #55A08D;
        border-radius: 5px;
        padding: 8px 0;
        width: 160px;
        font-size: 14px;
        margin: 5px auto 0 auto;
        text-align: center;

        @media (max-width: 420px) {
          font-size: 13px;
          width: 140px;
        }

        @media (max-width: 360px) {
          font-size: 12px;
          width: 120px;
        }

        &:hover {
          cursor: pointer;
          opacity: 0.9;
          filter: brightness(125%);
        }
      }
    }

    .title-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: 20px 0 0 0;
      height: 40px;
      width: 100%;

      &.is-account {
        align-items: center;
      }

      &.locum-title {
        align-items: flex-start;
      }

      .exit-button {
        .exit-icon {
          width: 11px;
          filter: brightness(0) saturate(100%);
        }
      }

      @media (max-width: 480px) {
        // flex-direction: column;
        // height: 75px;
      }

      .title {
        margin-top: 20px;
        color: #55A08D;
        font: 500 24px Inter, mundo-bold;

        @media (max-width: 480px) {
          font-size: 20px;
          margin-bottom: 20px;
          margin-left: 10px;
        }
      }
    }

    &.coming-soon {
      @media (max-width: 480px) {
        align-items: center;
      }
    }

    &.account {     
      padding: 0;
      @media (max-width: 480px) {
        align-items: center;
      }
    }

    .account-detail-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      border-top: 0.2px solid #cecece;
      border-bottom: 0.2px solid #cecece;
      padding-bottom: 20px;
    }

    .error-text {
      text-align: center;
      font-size: 11px;
      margin: 10px 0;

      @media (max-width: 480px) {
        margin: 5px 0;
      }
    }

    .edit-option {
      margin: 10px 0 10px 10px;
    }

    .coming-soon-text {
      font-size: 18px;
      color: #808080;

      @media (max-width: 480px) {
        font-size: 16px;
      }
    }
  }
}

.edit-account-button {
  display: flex;
  align-items: center;


  &.profile-edit {
    justify-content: flex-end;
    width: 100%;
    margin: 0 20px;
  }

  &.profile-save {
    justify-content: space-between;
    width: 100%;
    margin: 0 20px;
  }

  .edit-button {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    padding: 4px 14px;
    color: #55A08D;
    border-radius: 25px;

    .edit-icon {
      height: 18px;
      width: 18px;
      margin-left: 8px;
    }
  }

  .save-button, .cancel-button {
    font-size: 15px;
    padding: 4px 15px;
    text-align: center;
    margin: 0 5px;
    font-weight: 900;

    &.disabled {
      opacity: 0.7;
    }

    &.very-dark.disabled:hover {
      cursor: auto;
      filter: brightness(100%);
    }
  }

  .cancel-button {
    color: #231F20;
    font-weight: 900;
  }

  .save-button {
    color: #55A08D;
  }
}

.edit-option.button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90%;

  @media (max-width: 480px) {
    justify-content: unset;
  }

  .edit-label {
    font-size: 12px;
    font-weight: 200;
    width: 120px;
  }

  .change-password-button {
    font-size: 12px;
    font-weight: 600;
    border-bottom: 0.2px solid #CECECE;
    text-align: left;
    width: 250px;
    padding: 5px 4px 0;
    box-sizing: unset;
  }

  .password-hidden {
    border: 1px solid transparent;
    font-size: 24px;
  }
}

.edit-option.text, .edit-option.dropdown {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90%;

  @media (max-width: 480px) {
    justify-content: unset;
  }

  .edit-label {
    font-size: 12px;
    font-weight: 200;
    width: 120px;
  }

  .react-tel-input {
    width: 250px;
    border-bottom: 0.2px solid #CECECE;
    padding: 5px 4px 0;

    .phone-number {
      border: none;
      font-size: 12px;
      font-weight: 600;
      width: 100%;
      height: auto;
      line-height: 0;
    }

    .flag-dropdown {
      border: none;
      font-size: 12px;
      background-color: transparent;

      .selected-flag {
        font-size: 16px;
        background-color: transparent;
        border-radius: 6px 0 0 6px;
      }
    }
  }

  .edit-text {
    font-size: 12px;
    padding: 5px 4px 0px 4px;
    font-weight: 600;
    width: 250px;
    border: none;
    border-bottom: 0.2px solid #CECECE;

    @media (max-width: 480px) {
      // width: 170px;
    }

    &.disabled {
      border: solid 1px transparent;
      background-color: transparent;
      color: #000000;
    }
  }
}

.edit-option.checkbox {
  display: flex;
  margin: 0 0 50px 0;

  .edit-checkbox {
    margin: 0;
    width: 20px;
    height: 20px;
    min-width: 20px;
    min-height: 20px;
  }

  .edit-label {
    margin-left: 10px;
    font-size: 12px;
    font-weight: 200;

    .title-label {
      font-size: 16px;

      &.group-weight { 
        font-weight: 300 !important;
      }
    }

    .subtitle-label {
      font-size: 14px;
    }
  }
}

.confirmation-modal-container.change-password {
  .confirmation-modal {
    border: none;
    border-radius: 10px;
    width: 300px;
    height: 240px;

    @media (max-width: 480px) {
      width: 260px;
      padding: 15px;
    }

    .confirmation-modal-content {
      align-items: center;
      height: 100%;

      .confirmation-title {
        font-size: 12px;
        margin-bottom: 0;
        margin-top: 10px;
      }

      .pass-changed {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;

        .pass-changed-text {
          font-size: 12px;
          font-weight: 200;
          margin-bottom: 25px;
        }

        .pass-changed-button {
          margin-bottom: 20px;

          .pass-changed-icon {
            border: 2px solid #55A08D;
            border-radius: 15.5px;
            padding: 3px 10px;
            width: 15px;
            filter: invert(61%) sepia(44%) saturate(364%) hue-rotate(114deg) brightness(86%) contrast(88%);
          }
        }
      }

      .error-text {
        margin: 2.5px 0;
        font-size: 10px;
        width: 90%;
        text-align: center;
      }

      .password {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 0 0 10px;
        width: 100%;

        .show-pass-icon {
          align-self: flex-end;
          position: absolute;
          margin-right: 15px;
          width: 10px;
        }

        .password-label {
          margin: 5px 0;
        }

        .password-input {
          font-size: 10px;
          padding: 8px 5px;
          border: 1px solid #ABD2C8;
          width: 90%;
        }
      }

      .buttons {
        margin: 10px 0;
        display: flex;
        justify-content: space-evenly;
        align-self: normal;

        .confirm {
          padding: 10px 20px;
          font-size: 15px;
          background-color: #55A08D;
          border-radius: 50px;

          @media (max-width: 480px) {
            font-size: 14px;
          }
        }
      }
    }
  }
}