.subscribe-to-store-checkbox {
  margin: 20px 0;
  display: flex;
  align-items: flex-start;

  @media (max-width: 480px) {
    margin: 15px 0;
  }

  input {
    margin-right: 20px;
    transform: scale(1.4);

    @media (max-width: 480px) {
      transform: scale(1);
      margin-right: 15px;
    }

    @media (max-width: 360px) {
      margin-right: 10px;
    }
  }

  .subscribe-checkbox-label {
    font: 400 16px Inter, Helvetica, Arial, sans-serif;

    @media (max-width: 480px) {
      font-size: 13px;
    }

    @media (max-width: 360px) {
      font-size: 12px;
    }
  }
}