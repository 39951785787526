.add-times-container {
  height: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 980px) {
    height: auto;
  }

  .times-list {
    margin: 20px 0 30px 0;
    overflow-y: auto;
    padding: 0 30px 30px 30px;
    height: 550px;

    @media (max-width: 980px) {
      height: auto;
    }
  }

  &.one-date {
    height: 500px;

    @media (max-width: 980px) {
      height: auto;
    }
    
    .times-list {
      margin: 0;
      height: 340px;
      padding: 0 30px;
    }
  }

  &.two-dates {
    height: 650px;
    margin: 0;

    @media (max-width: 980px) {
      height: auto;
    }

    .times-list {
      margin: 0;
      height: 650px;
      padding: 0 30px;
    }
  }

  .confirm-buttons {
    display: flex;
    justify-content: flex-end;
    width: calc(100% - 60px);

    @media (max-width: 980px) {
      margin-top: 20px;
    }

    .cancel-button, .confirm-shifts-button {
      padding: 10px 0px;
      border-radius: 6px;
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
      text-align: center;
      font-size: 18px;
      color: #FFFFFF;

      @media (max-width: 620px) {
        font-size: 16px;
        padding: 8px 0;
      }

      @media (max-width: 480px) {
        font-size: 14px;
      }

      @media (max-width: 360px) {
        font-size: 13px;
        padding: 5px 0;
      }
    }

    .cancel-button {
      width: 110px;
      background-color:  #636363;
      margin-right: 30px;

      @media (max-width: 620px) {
        width: 100px;
      }

      @media (max-width: 480px) {
        width: 90px;
      }

      @media (max-width: 360px) {
        width: 80px;
      }
    }

    .confirm-shifts-button {
      width: 200px;
      background-color: #55a08d;

      @media (max-width: 620px) {
        width: 180px;
      }

      @media (max-width: 480px) {
        width: 160px;
      }

      @media (max-width: 360px) {
        width: 140px;
      }
    }
  }
}



.add-time-container {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  
  .date-container {
    font-size: 18px;
    color: #FFF;
    background-color: #56A08D;
    border-radius: 15px 15px 0 0px;
    width: 580px;
    padding: 10px;
    text-align: center;
    display: flex;
    justify-content: center;

    @media (max-width: 620px) {
      width: auto;
      min-width: 440px;
    }

    @media (max-width: 620px) {
      border-radius: 0;
      min-width: 380px;
      font-size: 16px;
    }

    @media (max-width: 420px) {
      min-width: 320px;
    }

    @media (max-width: 360px) {
      min-width: 280px;
    }
  }

  .shift-selection-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    background-color: #E3E1D6;
    text-align: left;

    .selection-labels {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      font-size: 16px;
      width: 280px;

      &.short {
        display: none;
      }

      .label {
        margin: 10px 0;
      }

      @media (max-width: 480px) {
        font-size: 14px;
      }

      @media (max-width: 420px) {
        width: 200px;
        display: none;

        &.short {
          display: flex;
        }
      }

      @media (max-width: 360px) {
        width: 150px;
      }
    }

    .selections {
      width: 155px;
      font-size: 16px;

      @media (max-width: 480px) {
        font-size: 14px;
        width: 90px;
      }

      .react-datepicker-wrapper .react-datepicker__input-container input {
        width: 145px;
        font-size: 16px;
        font-weight: 900;
        border: solid 2px #BFBFBF;
        border-radius: 5px;
        padding: 2px 0 2px 5px;
        margin: 6px 0;

        @media (max-width: 480px) {
          font-size: 14px;
          width: 80px;
        }
      }

      .rate {
        margin: 6px 0;
        font-size: 16px;
        border: solid 2px #BFBFBF;
        width: 145px;
        border-radius: 5px;
        padding: 2px 0 2px 5px;

        &.disabled {
          filter: brightness(80%);
        }

        @media (max-width: 480px) {
          font-size: 14px;
          width: 80px;
        }
      }

    }

    .total-container {
      width: 95px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      @media (max-width: 620px) {
        display: none;
      }

      .total-label {
        margin: -5px 0 5px 0;
      }
    }
  }

  .total-container {
    display: none;

    @media (max-width: 620px) {
      display: flex;
      width: 100%;
      background-color: #e3e1d6;
      justify-content: space-between;
      padding: 0 0 10px 0;

      .total-label {
        margin-left: 10px;
      }

      .total {
        margin-right: 15px;
      }
    }
  }
}

.share-shift-page {
  display: flex;
  flex-direction: column;
  max-width: 750px;

  .share-title {
    font: 900 16px Inter, mundo-bold;
  }

  .share-shift-content {
    border: solid 2px #000000;
    box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
    padding: 10px 20px;
    overflow: auto;
    max-height: 500px;

    .paragraph {
      margin: 10px 0;
    }

    .available-shifts-title, .store-details-title {
      font: 900 16px Inter, mundo-bold;
      margin: 15px 0 0 0;
    }

    .available-shift-list, .store-details-list {
      margin: 5px 0 10px 10px;
    }

    .booking-link {
      margin: 0 0 10px 8px;
    }

    .buttons {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .copy-button {
      font-size: 16px;
      margin: 10px 0 5px 0;
      background-color: #28446A;
      color: #FFFFFF;
      padding: 10px 50px;
      border-radius: 20px;
      width: 240px;
  
      &:hover {
        filter: brightness(125%);
      }
    }

    .return-button {
      font-size: 16px;
      margin: 10px 0 5px 0;
      background-color: #808080;
      color: #FFFFFF;
      padding: 10px 50px;
      border-radius: 20px;
      width: 240px;
  
      &:hover {
        filter: brightness(125%);
      }
    }
  }
}

.confirm-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .hang-tight-image {
    margin: 0px;
    width: 360px;
    height: 360px;
  }
  
  .hang-tight-text {
    margin: 10px;
    font-size: 22px;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 900;
    text-align: center;
  }

  .notify-text {
    margin: 10px;
    font-size: 16px;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 600;
    color: #808080;
    text-align: center;
  }

  .share-shifts-button {
    font-size: 16px;
    margin: 10px 0 5px 0;
    background-color: #28446A;
    color: #FFFFFF;
    padding: 10px 50px;
    border-radius: 20px;

    &:hover {
      filter: brightness(125%);
    }
  }

  .add-another-shift {
    font-size: 16px;
    margin: 10px 0 5px 0;
    background-color: #808080;
    color: #FFFFFF;
    padding: 10px 50px;
    border-radius: 20px;

    &:hover {
      filter: brightness(125%);
    }
  }
}

.dropdown-button {
  height: 27px;

  &.time {
    margin: 5px 0 10px 0;
  }

  &.yes-no {
    margin: 6px 0;
  }
  
  &.time, &.yes-no {
    .data-view-button {
      background-color: #FFFFFF;
      width: 154px;
      border: solid 2px #BFBFBF;
      border-radius: 5px;
      font-size: 16px;
      padding: 2px 0 2px 5px;
      background-color: #FFFFFF;
      text-align: left;
      height: 27px;

      &.disabled {
        cursor: auto;
        filter: brightness(80%);
      }

      @media (max-width: 480px) {
        font-size: 14px;
        width: 89px;
      }

      &.open {
        border-bottom: none;
        border-radius: 5px 5px 0 0;
      }

      &:hover {
        cursor: pointer;
        filter: brightness(95%);
      }
    }

    .dropdown-list-container {
      position: relative;
      height: 0;

      .dropdown-list {
        position: absolute;
        z-index: 1;
        display: flex;
        flex-direction: column;
        border: solid 2px #BFBFBF;
        border-top: none;
        width: calc(100% - 5px);
        top: -2px;
        max-height: 150px;
        overflow-y: auto;

        @media (max-width: 620px) {
          max-height: 120px;
        }

        &.yes-no {
          height: 50px;

          .dropdown-option {
            padding: 5px 10px;
          }
        }

        .dropdown-option {
          background-color: #FFFFFF;
          color: rgb(64, 64, 64);
          text-align: center;
          padding: 8px 10px;
          width: 100%;
        }
      }
    }
  }
}

.confirmation-modal-container.add-shifts {
  .confirmation-modal {
    @media (max-width: 980px) {
      margin: auto;
      width: 360px;
    }

    @media (max-width: 480px) {
      width: 320px;
    }

    @media (max-width: 420px) {
      width: 240px;

      .confirmation-modal-content {

        .confirmation-text {
          font-size: 15px;
        }

        .buttons {
          display: flex;
          justify-content: space-between;
          align-self: auto;

          .cancel, .confirm {
            font-size: 14px;
            padding: 10px 20px;
          }
        }
      }
    }
  }
}

.post-shifts-container {
  display: flex;
  height: 600px;
  width: 1020px;

  @media (max-width: 1360px) {
    flex-direction: column;
    align-items: center;
    height: auto;
    width: 700px;

    .add-dates-container, .view-and-edit-container .view-shifts-container, .view-and-edit-container .edit-shifts-container, .additional-details-section {
      .title {
        text-align: center;
        margin: 15px 0;
      }
    }

    .edit-shifts-container {
      .edit-shifts-card-container {
        .edit-shift-card {
          min-width: 280px;
        }
      }
    }
  }

  @media (max-width: 980px) {
    width: auto;
  }

  @media (max-width: 800px) {
    .view-and-edit-container {
      flex-direction: column-reverse;

      .edit-shifts-container {
        margin: 15px 0;
      }

      .view-shifts-container {
        margin: 15px 0;

        .title {
          margin: 10px;
        }
      }
    }
  }

  @media (max-width: 400px) {
    .edit-shifts-container {
      align-items: center;

      .edit-shifts-card-container {
        .edit-shift-card {
          max-width: 300px;
          padding: 15px 25px;
        }
      }
    }

    .view-shfits-container {
      .shift-cards {
        .view-shift-card {
          max-width: 280px;
          min-width: 280px;
        }
      }
    }
  }
}

@media (max-width: 360px) {
  .edit-shifts-container {
    .edit-shifts-card-container {
      .edit-shift-card {
        padding: 15px;
        min-width: 270px;
        max-width: 280px;
      }
    }
  }
}

.add-dates-container {
  display: flex;
  flex-direction: column;

  .title {
    font: 400 15px avenir;
    line-height: 24px;
    margin: 10px 0;
  }

  .react-datepicker {
    box-shadow: 0px 0px 30.5201px rgba(0, 0, 0, 0.1);
    border-radius: 12.7273px;
    padding: 15px;
    border: none;


    .react-datepicker__navigation--previous {
      right: 40px;
      top: 25px;
      left: auto;
    }
    
    .react-datepicker__navigation--next {
      right: 7px;
      top: 25px;
    }

    .react-datepicker__navigation-icon:before {
      border-color: #121212;
    }

    .react-datepicker__month-container {

      .react-datepicker__header {
        background-color: #FFFFFF;
        border-bottom: none;

        .react-datepicker__current-month {
          color: #121212;
          display: flex;
          padding: 10px;
          font-family: mundo-bold;
          font-weight: 900;
          font-size: 1.1rem;
        }
      }

      .react-datepicker__day-name,  .react-datepicker__day, .react-datepicker__time-name {
        font-family: mundo;
      }

      .react-datepicker__day--keyboard-selected {
        background-color: transparent;
        color: #000000;
      }

      .react-datepicker__day--highlighted.react-datepicker__day--keyboard-selected,  .react-datepicker__day--highlighted {
        background-color: #55A08D;
        color: #FFFFFF;
      }
    }
  }

  &.read-only {
    .react-datepicker {
      .react-datepicker__day:hover {
        cursor: auto;
        background-color: transparent;
      }

      .react-datepicker__day.react-datepicker__day--highlighted:hover {
        background-color: #55A08D;
      }
    }
  }
}


.view-shifts-container {
  display: flex;
  flex-direction: column;
  height: 600px;
  margin: 0 30px;

  @media (max-width: 1360px) {
    margin: 0 5px;
  }

  @media (max-width: 980px) {
    height: auto;
  }

  .title {
    font: 400 15px avenir;
    line-height: 24px;
    margin: 10px 10px 0 10px;
  }
}

.edit-shifts-container {
  display: flex;
  flex-direction: column;

  .title {
    font: 400 15px avenir;
    line-height: 24px;
    margin: 10px 10px 0 10px;
  }
}

.edit-shift-card, .store-detail-card {
  min-width: 300px;
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 10px;
  border: 2px solid transparent;
  margin: 10px 0 0 10px;
  padding: 15px 25px;

  .selected-dates-title {
    display: flex;
    flex-direction: column;
    max-height: 120px;
    overflow: auto;

    .selected-date-header {
      margin: 5px 0;
      font: 900 13px montserrat-medium;
    }
  }

  .time-and-rate {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .time-from, .time-to, .rate {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      height: 60px;

      .label {
        font: 100 12px montserrat-medium;
      }
    }

    .rate-input-container {
      display: flex;
      width: 80px;
      background: #f7f5f5;
      border: none;
      border-radius: 6px;
      padding: 0px 10px;
      height: 27px;

      .currency-sign {
        font: 400 11px montserrat-medium;
        margin-top: 6.5px;
        padding-right: 2px;
      }
    }

    .rate-input {
      font: 400 11px montserrat-medium;
      line-height: 19px;
      width: 100%;
      text-align: left;
      background: #f7f5f5;
      border: none;

      &:focus {
        outline: none;
      }
    }
  }

  .duration-and-total {
    margin: 5px 0;
    display: flex;
    justify-content: space-between;

    .duration {
      font: 100 11px montserrat-medium;
    }

    .total {
      font: 900 11px montserrat-medium;
    }
  }

  .unpaid-break {
    display: flex;
    line-height: 17px;
    margin: 10px 0 10px 0;
    width: 200px;

    .label {
      margin-top: 5px;
      width: 100px;
      margin-right: 20px;
    }

    * {
      font: 100 11px montserrat-medium;
    }
  }

  .negotiable {
    display: flex;
    flex-direction: row;
    width: 140px;

    .label {
      margin-right: 16px;
      font: 100 11px montserrat-medium;
      width: 100px;
    }

    input[type="checkbox"] {
      margin: 0;
      accent-color: #56A08D;

      &:hover {
        cursor: pointer;
      }

      &:disabled,
      &[disabled] {
        background-color: #56A08D;
      }
    }
  }
}

.shift-cards {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 20px 10px;
}

.additional-details-section {
  display: flex;
  flex-direction: column;
  height: 600px;
  margin: 0 30px;

  .title {
    font: 400 15px avenir;
    line-height: 24px;
    margin: 10px 10px 0 10px;
  }
}

.additional-details-card {
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  border: 2px solid transparent;
  padding: 20px 25px;
  max-width: 300px;
  margin-bottom: 25px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

  .question-text {
    font: 900 12px montserrat-medium;
    margin: 10px 0 15px 0;
  }
}

.view-shift-card {
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  border: 2px solid transparent;
  padding: 15px 25px;
  min-width: 300px;
  margin-bottom: 15px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

  &.selected {
    border: 2px solid #55A08D;
  }

  .date-title {
    font: 900 12px montserrat-medium;
    line-height: 18px;
    margin: 3px 0;
  }

  .time-and-rate {
    display: flex;
    flex-direction: row;
    line-height: 17px;
    justify-content: space-between;
    margin: 5px 0 2px 0;
    width: 100%;

    * {
      font: 100 11px montserrat-medium;
    }
  }

  .duration {
    margin: 0 0 5px 0;
    font: 100 11px montserrat-medium;
    align-self: flex-start
  }

  .unpaid-break {
    display: flex;
    justify-content: space-between;
    line-height: 17px;
    margin: 10px 0;
    width: 170px;

    * {
      font: 100 11px montserrat-medium;
    }
  }

  .negotiable-and-total {
    display: flex;
    justify-content: space-between;
    margin: 5px 0;
    width: 100%;

    * {
      font: 100 11px montserrat-medium;
    }

    .rate-negotiable {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .label {
        margin-right: 20px;
      }

      input[type="checkbox"] {
        margin: 0;
        accent-color: #56A08D;

        &:hover {
          cursor: pointer;
        }
      }
    }

    .total {
      font: 900 11px montserrat-medium;
    }
  }
}

.dropdown-button {
  &.date-time {
    .dropdown-view-button {
      width: 85px;
    }
  }

  &.additional-questions {
    .dropdown-view-button {
      width: 100%;
      font: 400 13px montserrat-medium;
      padding: 5px 0 5px 10px;
    }

    .dropdown-list-container {
      .dropdown-list {
        .dropdown-item {
          .dropdown-option {
            font: 400 13px montserrat-medium;
          }
        }
      }
    }
  }

  .dropdown-view-button { 
    background: #f7f5f5;
    border: none;
    border-radius: 6px;
    font: 400 11px montserrat-medium;
    line-height: 19px;
    text-align: left;
    padding: 3px 5px;
    display: flex;
    justify-content: space-between;

    img {
      width: 20px;
      height: 20px;
    }
  }

  .dropdown-list-container {
    position: relative;
    height: 0;

    .dropdown-list {
      position: absolute;
      z-index: 1;
      display: flex;
      flex-direction: column;
      background: #f7f5f5;
      width: calc(100%);
      top: -2px;
      max-height: 150px;
      overflow-y: auto;

      .dropdown-item {
        .dropdown-option {
          background-color: #f7f5f5;
          color: rgb(64, 64, 64);
          text-align: center;
          padding: 8px 5px;
          width: 100%;
          font: 400 11px montserrat-medium;
        }
      }
    }
  }
}

.view-and-edit-container {
  display: flex;
}

.add-shifts-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;

  .add-shifts-title {
    font: 400 24px inter-bold;
    margin: 10px 0;
    color: #1a1a1a;

    @media (max-width: 480px) {
      text-align: center;
    }
  }

  .buttons {
    display: flex;
    flex-direction: column;
    align-self: center;
    justify-content: space-evenly;
    height: 120px;

    button {
      color: #FFFFFF;
      text-align: center;
      width: 150px;
      border-radius: 50px;
      font: 400 14px montserrat-medium;
      line-height: 18px;
      padding: 10px 15px;

      &.loading {
        opacity: 0.6;
        
        &:hover {
          cursor: auto;
          filter: brightness(100%);
        }
      }
    }

    .continue-button {
      background-color: #56A08D;
    }
    
    .cancel-button {
      background-color: #636363;
    }
  }
}