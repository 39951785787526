.store-profile-section.photos {
  margin: 40px 0px;

  @media (max-width: 480px) {
    margin: 10px 0px; 
  }

  .store-photo-list {
    display: flex;
    padding: 0 8px;
    flex-wrap: nowrap;
    white-space: nowrap;
    overflow-x: auto;
    margin: 0 20px;

    @media (max-width: 480px) {
      margin: 0 10px;
    }

    .store-photo-item {
      margin: 0 12px;
      padding: 10px 0;

      &:first-child {
        margin-left: auto;
      }

      &:last-child {
        margin-right: auto;
      }

      @media (max-width: 480px) {
        margin: 0 8px;
      }
      
      .store-photo {
        height: 250px;
        width: 350px;

        @media (max-width: 480px) {
          height: 190px;
          width: 280px;
        }
      }
    }
  }
}