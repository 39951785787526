.locum-store-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 60px 0 0;
  background-color: #CAE3DD;

  @media(max-width: 820px) {
    margin: 0 20px;
    background-color: #FFFFFF;
  }

  .store-page-container {
    display: flex;
    justify-content: center;
    width: 100%;
    border-top-left-radius: 60px;
    border-top-right-radius: 60px;
    background-color: #FFFFFF;
    box-sizing: border-box;
    padding: 30px;

    @media (max-width: 820px) {
      flex-direction: column;
      align-items: center;
      border-radius: none;
      padding: 0;
    }

    .store-summary {
      display: flex;
      flex-direction: column;
      width: 66%;
      padding: 0 20px;
    }

    .store-info {
      display: flex;
      flex-direction: column;
      width: 34%;
      background-color: #EFF7F5;
      border-radius: 30px;
      padding: 20px;
      height: 100%;
    }

    .store-back-arrow {
      margin-top: 10px;
      width: 100%;

      .back-arrow-container {
        margin-left: 0;
      }
    }

    .store-header {
      display: flex;
      width: 100%;
      box-sizing: border-box;

      @media (max-width: 820px) {
        padding: 20px;
      }

      .store-avatar-container {
        display: flex;
        align-items: flex-start;
        justify-content: center;

        @media (max-width: 820px) {
          align-items: center;
        }
  
        .store-avatar {
          height: 100px;
          width: 100px;
          border-radius: 200px;
        }
      }
  
      .store-detail-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        color: #55A08D;
        padding: 20px;
  
        .store-detail-name {
          font-size: 18px;
          font-weight: 900;
        }
  
        .store-detail-address {
          font-size: 12px;
          font-weight: 300;
        }
      }
    }
  
    .tag-container {
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: flex-start;

      @media (max-width: 820px) {
        justify-content: space-between;
      }
  
      .tag-item {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #ABD2C8;
        border-radius: 30px;
        padding: 4px 15px;
        color: #FFFFFF;
        height: 30px;
        text-align: center;
        font-size: 14px;
        margin-right: 10px;

        @media (max-width: 820px) {
          margin: none;
          padding: 7px 15px;
        }
  
        &.notified {
          background-color: #55A08D;
        }
  
        &.notify-button {
          cursor: pointer;
        }
  
        @media (max-width: 420px) {
          font-size: 13px;
          padding: 6px 12px;
          height: 27px;
        }
  
        @media (max-width: 380px) {
          font-size: 12px;
          padding: 5px 10px;
          height: 25px;
        }
  
        @media (max-width: 340px) {
          padding: 5px 5px;
        }
  
        .subscribe-bell {
          background-color: #55A08D;
          border-radius: 30px;
          padding: 5px;
          margin-right: 5px;
          height: 20px;
          width: 20px;
  
          @media (max-width: 420px) {
            height: 17px;
            width: 17px;
          }
  
          @media (max-width: 380px) {
            height: 15px;
            width: 15px;
          }
        }
      }
    }
  
    .store-reviews {
      visibility: hidden;
      display: flex;
      flex-direction: column;
      width: 100%;
      margin: 10px 0;

      @media (min-width: 821px) {
        flex-direction: row;
      }
  
      .review {
        margin: 5px 10px 5px 0;
  
        .tick-icon {
          width: 11px;
          height: 11px;
          margin-right: 5px;
        }
  
        .review-text {
          font-size: 14px;
          font-weight: 500;
        }
      }
    }
  
    .info-content-container {
      display: flex;
      justify-content: center;
      width: 100%;
      margin-bottom: 20px;
  
      .accordian {
        display: flex;
        flex-direction: column;
        width: 100%;
  
        .item {
          display: flex;
          background-color: #FFFFFF;
          border-radius: 15px;
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.07);
          padding: 10px 20px;
          margin-top: 5px;
          margin-bottom: 5px;
          cursor: pointer;
  
          .question-container {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
  
            .question-title {
              padding: 10px;
              box-sizing: border-box;
              font-size: 14px;
              font-weight: 900 !important;
  
              @media (max-width: 370px) {
                font-size: 12px;
              }
            }
  
            .answer-content {
              display: block;
              text-align: left;
              padding: 10px;
              max-height: 30px;
              transition: all 0.25s ease;
              font-size: 12px;
  
              @media (max-width: 370px) {
                font-size: 10px;
              }
  
              .info-answers {
                line-height: 16px;
  
                .info-list {
                  padding-left: 20px;
  
                  .requirement {
                    list-style: disc;
  
                    &.no-style {
                      list-style: none;
                    }
                  }
  
                  .information {
                    list-style: disc;
  
                    &.no-style {
                      list-style: none;
                    }
                  }
                }
              }
            }
  
            .hide {
              opacity: 0.6;
            }
  
            .answer-content > span {
              display: inline-block;
            }
          }
  
          .expandable-bar {
            border-radius: 10px;
            width: 5px;
            margin-right: 20px;
          }
  
          .green-sidebar {
            background: linear-gradient(180deg, #55A08D 30.96%, #ABD2C8 79.93%, #CAE3DD 100%);
          }
  
          .yellow-sidebar {
            background: linear-gradient(180deg, #EFCB68 30.96%, rgba(246, 222, 157, 0.2) 100%);
          }
        }
      }
    }

    .faq-container {
      display: flex;
      justify-content: center; 
      align-items: center;
      flex-direction: column;
      margin-top: 20px;
      margin-bottom: 130px;

      @media (min-width: 821px) {
        margin-top: 40px;
        margin-bottom: 0;
      }

      .faq-info {
        display: flex;
        justify-content: center; 
        align-items: center;
        flex-direction: column;
        width: 80%;

        @media (min-width: 821px) {
          width: 90%;
        }

        .faq-title {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
  
          .faq-faq {
            font-weight: 900;
          }
  
          .faq-sub {
            margin: 10px 0;
            font-size: 12px;
  
            @media (max-width: 390px) {
              font-size: 10px;
            }
          }
        }
  
        .faq-accordion {
          display: flex;
          flex-direction: column;

          @media (min-width: 821px) {
            width: 95%;
          }
  
          .faq-item {
            display: flex;
            flex-direction: column;
            padding: 1rem;
            margin-bottom: 10px;
            background-color: #EFF7F5;
            border-radius: 5px;
            box-sizing: border-box;

            @media (min-width: 821px) {
              border-radius: 15px;
              box-sizing: border-box;
              width: 100%;
            }
    
            .faq-header {
              display: flex;
              justify-content: space-between;
              align-items: center;
              width: 100%;
              font-size: 14px;
              text-align: left;
              box-sizing: border-box;
              cursor: pointer;
              font-weight: 900;
            }
    
            .faq-header-icon {
              transition: all 0.2s;
            }
    
            .open {
              transform: rotate(-180deg);
              transition: all 0.2s;
            }
  
            .faq-content {
              display: block;
              text-align: left;
              max-height: 0px;
              transition: all 0.25s ease;
              overflow: hidden;
              font-size: 12px;
              opacity: 1;
  
              @media (max-width: 370px) {
                font-size: 10px;
              }
  
              .faq-answer {
                white-space: pre-wrap;
              }
            }
  
            .show-dropdown {
              padding: 10px 0;
            }
  
            .faq-content > span {
              display: inline-block;
            }
          }
        }
      }
    }
  
    .shifts-container {
      display: flex;
      flex-direction: column;
      background-color: #FFFFFF;
      width: 100%;

      @media (max-width: 820px) {
        margin-bottom: 40px;
      }
  
      .shift-view-title {
        font-weight: 600;
        color: #55A08D;
        padding: 10px;
      }
  
      .shifts-padding {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 10px;

        @media (max-width: 1260px) {
          grid-template-columns: 1fr;
        }
        
        @media (max-width: 820px) {
          display: flex;
          align-items: stretch;
          justify-content: center;
          flex-wrap: wrap;
          width: 100%;
          margin-left: 0px;
          margin-right: 0px;
          box-sizing: border-box;
        }

        .shift-card-container {
          margin: 0;
        }
      }

      .view-more {
        color: #55A08D;
        border: 2px solid #55A08D;
        border-radius: 30px;
        padding: 10px 20px;
        font-size: 14px;
        cursor: pointer;
        margin: 20px auto;

        @media(max-width: 820px) {
          margin: 10px 0;
        }

        @media (max-width: 380px) {
          font-size: 12px;
          padding: 5px 10px;
        }
      }
    }
  }
  
  .loading {
    color: #55A08D;
    font-weight: 900;
    text-align: center;
    font-size: 10px 15px;
  }

  .something-wrong {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 50vh;

    .no-result-svg {
      width: 200px;
      margin-bottom: 20px;
      filter: invert(59%) sepia(55%) saturate(291%) hue-rotate(114deg) brightness(86%) contrast(91%);
    }

    .something-wrong-text {
      color: #55A08D;
      font-weight: 900;
      text-align: center;
      font-size: 20px;
    }
  }
}