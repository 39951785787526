.login-container {
  background-color: #FFFFFF;
  display: flex;
  justify-content: center;
  width: 570px;

  border-radius: 1rem;
  border-color: #eaeced;
  border-style: none;
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);

  @media (max-width: 1920px) {
    width: 520px;
  }

  @media (max-width: 1440px) {
    width: 480px;
  }

  @media (max-width: 980px) {
    width: 440px;
  }

  @media (max-width: 480px) {
    width: 400px;
    margin: auto;
  }

  @media (max-width: 400px) {
    width: 320px;
  }

  .form-container {
    padding: 40px;
    text-align: center;
    width: 100%;
  }

  .login-text {
    font-size: 44px;
    font-weight: 600;
    padding-bottom: 20px;

    @media (max-width: 1920px) {
      font-size: 32px;
    }

    @media (max-width: 1440px) {
      font-size: 28px;
      padding-bottom: 15px;
    }

    @media (max-width: 1250px) {
      font-size: 24px;
    }

    @media (max-width: 980px) {
      font-size: 22px;
      padding-bottom: 10px;
    }

    @media (max-width: 480px) {
      font-size: 20px;
    }

    @media (max-width: 420px) {
      font-size: 18px;
    }
  }

  .input-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    
    @media (max-width: 400px) {
      width: 260px;
    }
  }

  .email-address-container:hover,
  .password-container:hover {
    border-color: #AEAEB5; 
  }

  .email-address-container,
  .password-container {
    border-style: solid;
    border-color: #F0F0F4;
    border-radius: 0.5rem;
    border-width: 1px;
    background-color: #FAFAFC;
    padding: 16px 12px;
    width: 100%;
    font-size: 20px;
    margin: 10px 0;
    outline: none;

    @media (max-width: 1920px) {
      font-size: 18px;
      padding: 15px 10px;
    }

    @media (max-width: 1440px) {
      font-size: 16px;
    }

    @media (max-width: 1250px) {
      font-size: 15px;
    }

    @media (max-width: 980px) {
      font-size: 14px;
      padding: 13px 8px;
    }

    @media (max-width: 480px) {
      font-size: 13px;
      margin: 5px 0;
    }

    @media (max-width: 420px) {
      font-size: 12px;
    }
  }

  .forgot-password {
    width: 100%;
    background: transparent;
    color: #56A08D;
    font-size: 20px;

    @media (max-width: 1920px) {
      font-size: 18px;
    }

    @media (max-width: 1440px) {
      font-size: 16px;
    }

    @media (max-width: 1250px) {
      font-size: 15px;
    }

    @media (max-width: 980px) {
      font-size: 14px;
    }

    @media (max-width: 480px) {
      font-size: 13px;
    }

    @media (max-width: 420px) {
      font-size: 12px;
    }

    &:hover {
      cursor: pointer;
      color: #28446A;
    }
  }

  .confirm-buttons {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;

    .continue-button {
      border-radius: 0.5rem;
      padding: 12px 10px;
      text-align: center;
      font-size: 30px;
      color: #FFFFFF;
      margin: 15px;
      background-color: #55a08d;
      width: 100%;

      @media (max-width: 1920px) {
        font-size: 26px;
      }
  
      @media (max-width: 1440px) {
        font-size: 24px;
      }
  
      @media (max-width: 1250px) {
        font-size: 22px;
        margin: 10px;
      }
  
      @media (max-width: 980px) {
        font-size: 20px;
      }
  
      @media (max-width: 480px) {
        font-size: 18px;
        margin: 10px 0px;
      }
  
      @media (max-width: 420px) {
        font-size: 16px;
      }

      &:hover {
        filter: brightness(125%);
      }

      &.loading {
        opacity: 0.4;

        &.very-dark:hover {
          cursor: auto;
          filter: brightness(100%);
        }
      }
    }
  }

  .login-error-text {
    font-size: 20px;
    min-height: 20px;
    margin-bottom: 0;
    margin-top: 0;

    @media (max-width: 1920px) {
      font-size: 15px;
    }

    @media (max-width: 1440px) {
      font-size: 13px;
    }

    @media (max-width: 980px) {
      font-size: 10px;
    }
  }

  .no-account {
    margin: 10px 0;
    width: 100%;

    @media (max-width: 1920px) {
      margin: 5px 0;
    }

    @media (max-width: 1440px) {
      margin: 0;
    }

    .waitlist-button:hover {
      border-color: #212121;
    }

    .waitlist-button {
      display: flex;
      justify-content: center;
      box-sizing: border-box;

      margin-top: 10px;
      width: 100%;
      padding: 10px 0;
      font-size: 28px;

      border: solid;
      border-radius: 0.5rem;
      border-color: #F0F0F4;
      border-width: 1px;

      @media (max-width: 1920px) {
        font-size: 24px;
        margin-right: 0px;
      }
  
      @media (max-width: 1440px) {
        font-size: 22px;
      }
  
      @media (max-width: 1250px) {
        font-size: 20px;
      }
  
      @media (max-width: 980px) {
        font-size: 18px;
      }
  
      @media (max-width: 480px) {
        font-size: 16px;
      }
  
      @media (max-width: 420px) {
        font-size: 14px;
      }

      &:hover {
        cursor: pointer;
        color: #28446A;
      }
    }
  }
}