.go-to-button {

  &.reversed {
    left: 25px;
    right: auto;
    transform: rotate(180deg);
  }

  &:hover {
    opacity: 0.8;
  }

  &.disabled {
    opacity: 0.4;

    &:hover {
      cursor: auto;
      opacity: 0.4;
    }
  }
}