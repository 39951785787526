$cloudfront-cdn-url: 'https://dq55dzprwc7f6.cloudfront.net';

.how-it-works-page {
  .how-it-works-header {
    background: url($cloudfront-cdn-url + '/marketing/how-it-works-header.svg') no-repeat;
    background-size: 100%;
    background-position: top;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 220px 0 50px 0;

    @media (max-width: 1440px) {
      padding: 140px 0 50px 0;
    }

    @media (max-width: 1200px) {
      padding: 100px 0 30px 0;
    }

    @media (max-width: 980px) {
      padding: 20px 0 20px 0;
    }

    .title {
      margin: 30px 0;
      color: #313131;
      font: 900 42px avenir;

      @media (max-width: 1440px) {
        margin: 10px 0;
      }

      @media (max-width: 1020px) {
        font-size: 36px;
      }

      @media (max-width: 480px) {
        font-size: 28px;
      }
    }
  }
}

.dual-buttons-section {
  margin: 120px auto 120px auto;
  display: flex;

  @media (max-width: 980px) {
    margin: 60px auto 80px auto;
  }

  @media (max-width: 480px) {
    margin: 90px auto;
  }
  
  .buttons-container {
    display: flex;
    width: auto;
    align-items: center;

    .button-selector {
      background: rgba(85, 160, 141, 0.5);
      border-radius: 60px;
      padding: 20px 30px;
      width: 260px;
      color: #FFFFFF;
      font: 600 16px inter;
      margin-left: 45px;

      @media (max-width: 980px) {
        width: 200px;
        padding: 15px 20px;
        font-size: 14px;
      }

      @media (max-width: 480px) {
        font-size: 13px;
      }

      @media (max-width: 420px) {
        width: 170px;
        padding: 10px;
        font-size: 11px;
      }

      &.selected {
        background: #55A08D;
      }

      &.last {
        position: relative;
        margin-left: -45px;

        @media (max-width: 980px) {
          margin-left: -35px;
        }

        @media (max-width: 420px) {
          margin-left: -25px;
        }
      }
    }

    &:last-child {
      position: relative;
      left: -20px;
    }
  }
}