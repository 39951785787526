.when-padding {
  padding-bottom: 12px;

  @media (max-width: 480px) {
    --staggered_animation_delay: 0.05s;
    animation: fadeInDown-header-body ease 0.5s;
    animation-delay: var(--staggered_animation_delay, 1);
  }

  .when-panel-padding {
    height: 100%;
    box-sizing: border-box;

    @media (max-width: 480px) {
      padding: 24px;
    }

    .when-date-container {
      padding-top: 16px;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      height: 100%;
      box-sizing: border-box;

      @media (max-width: 480px) {
        overflow-y: scroll;
        overflow-x: hidden;
        height: calc(100% - 42px);
      }

      .when-date {
        padding-bottom: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        box-sizing: border-box;

        .when-tabs {
          width: 100%;

          .date-type-padding {
            padding-bottom: 8px;

            .date-type-display {
              display: flex;
              align-items: center;
              justify-content: center;

              .date-type-border {
                background-color: rgb(235, 235, 235);
                border-radius: 100px;
                padding-left: 4px;
                padding-right: 4px;
                display: flex;

                .date-type-grid {
                  display: grid;
                  grid-auto-columns: 1fr;
                  grid-auto-flow: column;
                  margin: 0px auto;
                  max-width: 400px;
                  min-width: 303px;

                  @media (max-width: 380px) {
                    min-width: 240px
                  }

                  .date-type-button-selected {
                    border-radius: 100px;
                    font-size: 14px;
                    line-height: 18px;
                    font-weight: 600;
                    background: rgb(255, 255, 255);
                    color: rgb(34, 34, 34);
                    padding: 8px 12px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    margin-top: 4px;
                    margin-bottom: 4px;
                    margin-right: 0px;
                    transition: box-shadow 0.2s ease 0s, -ms-transform 0.1s ease 0s, -webkit-transform 0.1s ease 0s, transform 0.1s ease 0s;
                    position: relative;
                    outline: none;
                    border: 1px solid rgba(0, 0, 0, 0.04);
                    box-shadow: rgb(0 0 0 / 8%) 0px 1px 2px, rgb(0 0 0 / 5%) 0px 4px 12px;
                    cursor: default;

                    &:hover {
                      background: rgb(255, 255, 255);
                    }
                  }

                  .date-type-button {
                    border-radius: 100px;
                    font-size: 14px;
                    line-height: 18px;
                    font-weight: 600;
                    border: none;
                    background: transparent;
                    color: rgb(34, 34, 34);
                    padding: 8px 12px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    margin-top: 4px;
                    margin-bottom: 4px;
                    margin-right: 0px;
                    transition: box-shadow 0.2s ease 0s, -ms-transform 0.1s ease 0s, -webkit-transform 0.1s ease 0s, transform 0.1s ease 0s;
                    position: relative;
                    outline: none;
                    cursor: pointer;

                    &:hover {
                      background: rgb(255, 255, 255);
                    }

                    &:active {
                      transform: scale(0.96);
                    }

                    &:last-child {
                      margin-right: 0px;
                    }
                  }
                }
              }
            }
          }

          .calendar-container {
            display: flex;
            justify-content: center;

            .rdrCalendarWrapper {
              @media (max-width: 380px) {
                width: 100%;
              }

              .rdrMonthAndYearWrapper {
                @media(max-width: 640px) {
                  font-size: 12px;
                }
              }

              .rdrMonths {
                @media(max-width: 640px) {
                  font-size: 10px;
                }
              }
            }

            .rdrDateDisplayWrapper {
              display: none;
            }

            .rdrMonth {
              @media (max-width: 380px) {
                width: 100%;
                box-sizing: border-box;
              }
            }

            .rdrNextPrevButton:first-child {
              @media (max-width: 355px) {
                margin-right: 0;
              }

              @media (max-width: 335px) {
                margin-left: 0.5em;
              }
            }

            .rdrNextPrevButton:last-child {
              @media (max-width: 355px) {
                margin-left: 0;
              }

              @media (max-width: 335px) {
                margin-right: 0.3em;
              }
            }
          }

          .selection-container {
            width: 100%;
            box-sizing: border-box;

            .not-selected {
              display: none;
            }

            .length-container {
              margin-top: 12px;
              padding-bottom: 0px;
              border-top: 1px solid rgb(235, 235, 235);
              width: 100%;

              .length-text {
                padding-top: 12px;
                padding-bottom: 4px;
                padding-right: 8px;
                font-size: 16px;
                line-height: 20px;
                font-weight: 600;
                color: rgb(34, 34, 34);
                margin: 0px;
              }

              .length-type-carousel {
                overflow-x: scroll;
                display: flex;
                margin-left: -32px;
                margin-right: -24px;

                .length-type-item {
                  &:first-child {
                    margin-left: 32px;
                  }

                  &:last-child {
                    margin-right: 16px;
                  }

                  .length-type-padding {
                    display: inline-block;
                    white-space: nowrap;
                    padding-right: 8px;
                    padding-top: 4px;
                    padding-bottom: 4px;

                    .length-type-button-selected {
                      cursor: pointer;
                      text-align: center;
                      border: 1px solid rgb(34, 34, 34);
                      outline: none;
                      margin: 0px;
                      position: relative;
                      transition-property: -ms-transform, -webkit-transform, transform, background-color, border-color;
                      transition-duration: 0.15s;
                      transition-timing-function: ease-in-out;
                      padding: 4px 12px;
                      min-height: 32px;
                      border-radius: 16px;
                      font-size: 14px;
                      line-height: 18px;
                      background-color: rgb(247, 247, 247);
                      color: rgb(34, 34, 34);
                    }

                    .length-type-button {
                      cursor: pointer;
                      text-align: center;
                      border: 1px solid rgb(221, 221, 221);
                      background-color: rgb(255, 255, 255);
                      outline: none;
                      margin: 0px;
                      color: rgb(34, 34, 34);
                      position: relative;
                      transition-property: -ms-transform, -webkit-transform, transform, background-color, border-color;
                      transition-duration: 0.15s;
                      transition-timing-function: ease-in-out;
                      padding: 4px 12px;
                      min-height: 32px;
                      border-radius: 16px;
                      font-size: 14px;
                      line-height: 18px;
                    }

                    .length-type-text {
                      font-weight: 400;
                      font-size: 14px;
                      line-height: 18px;
                    }
                  }
                }
              }
            }

            .month-container {
              margin-top: 12px;
              padding-bottom: 0px;
              width: 100%;

              .month-carousel {
                display: flex;
                flex-wrap: wrap;

                @media (max-width: 480px) {
                  margin-left: -32px;
                  margin-right: -24px;
                  overflow-x: scroll;
                  flex-wrap: unset;
                }

                .month-item {
                  &:first-child {
                    @media (max-width: 480px) {
                      margin-left: 32px;
                    }
                  }
                  &:last-child {
                    margin-right: 16px;
                  }

                  .month-padding {
                    display: inline-block;
                    white-space: nowrap;
                    padding-right: 8px;
                    padding-top: 4px;
                    padding-bottom: 4px;

                    .month-button-selected {
                      cursor: pointer;
                      text-align: center;
                      border: 2px solid rgb(34, 34, 34);
                      outline: none;
                      padding: 0px;
                      margin: 0px;
                      position: relative;
                      transition-property: -ms-transform, -webkit-transform, transform, background-color, border-color;
                      transition-duration: 0.15s;
                      transition-timing-function: ease-in-out;
                      border-radius: 16px;
                      font-size: 12px;
                      line-height: 16px;
                      height: 100%;
                      width: 100%;
                      background-color: rgb(247, 247, 247);
                      color: rgb(34, 34, 34);

                      &:active {
                        color: rgb(0, 0, 0);
                        border-color: rgb(0, 0, 0);
                        transform: scale(0.925);
                      }
                    }

                    .month-button {
                      cursor: pointer;
                      text-align: center;
                      border: 1px solid rgba(176, 176, 176, 0.5);
                      background-color: rgb(255, 255, 255);
                      outline: none;
                      padding: 1px;
                      margin: 0px;
                      color: rgb(34, 34, 34);
                      position: relative;
                      transition-property: -ms-transform, -webkit-transform, transform, background-color, border-color;
                      transition-duration: 0.15s;
                      transition-timing-function: ease-in-out;
                      border-radius: 16px;
                      font-size: 12px;
                      line-height: 16px;
                      height: 100%;
                      width: 100%;

                      &:active {
                        color: rgb(0, 0, 0);
                        border-color: rgb(0, 0, 0);
                        transform: scale(0.925);
                      }
                    }

                    .item-position {
                      position: relative;
                      display: flex;
                      justify-content: center;
                      align-items: center;

                      .item-padding {
                        padding-top: 8px;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        color: rgb(34, 34, 34);
                        width: 112px;
                        height: 104px;

                        .item-img {
                          width: 32px;
                          height: 32px;
                        }

                        .item-month {
                          padding-top: 8px;
                          font-weight: 400;
                          font-size: 14px;
                          line-height: 18px;
                        }

                        .item-year {
                          font-size: 14px;
                          line-height: 18px;
                          font-weight: 400;
                          padding-top: 2px;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

      .when-footer {
        padding: 12px 24px;
        border-top: 1px solid #EBEBEB;
        background-color: #FFFFFF;
        display: flex;
        justify-content: space-between;
        margin-inline: -24px;
        z-index: 100;

        .button-text {
          line-height: 18px;
          box-sizing: border-box;
          cursor: pointer;
          text-align: center;
        }

        .footer-clear {
          cursor: pointer;
          display: inline-block;
          margin: 0px -10px;
          position: relative;
          text-align: center;
          width: auto;
          touch-action: manipulation;
          font-size: 16px;
          line-height: 20px;
          font-weight: 600;
          border-radius: 8px;
          outline: none;
          padding: 10px;
          transition: box-shadow 0.2s ease 0s, -ms-transform 0.1s ease 0s, -webkit-transform 0.1s ease 0s, transform 0.1s ease 0s;
          border: none;
          background: transparent;
          color: #222222;
          text-decoration: underline;

          @media(max-height: 640px) {
            font-size: 12px;
          }
        }

        .footer-next {
          cursor: pointer;
          display: inline-block;
          margin: 0px;
          position: relative;
          text-align: center;
          text-decoration: none;
          width: auto;
          touch-action: manipulation;
          font-size: 16px;
          line-height: 20px;
          font-weight: 600;
          border-radius: 8px;
          outline: none;
          padding: 14px 24px;
          transition: box-shadow 0.2s ease 0s, -ms-transform 0.1s ease 0s, -webkit-transform 0.1s ease 0s, transform 0.1s ease 0s;
          border: none;
          background: #222222;
          color: #FFFFFF;

          @media(max-height: 640px) {
            font-size: 12px;
            padding: 10px 16px;
          }
        }
      }
    }
  }
}