.notifications-page {
  width: 100%;
  background-color: #CAE3DD;
  display: flex;
  flex-direction: column;
  padding: 60px;
  box-sizing: border-box;

  @media (max-width: 980px) {
    padding: unset;
    height: calc(100vh - 60px);
    height: calc(var(--vh, 1vh) * 100 - 60px);
  }

  @media (max-width: 600px) {
    height: calc(100vh - 50px);
    height: calc(var(--vh, 1vh) * 100 - 50px);
  }

  @media (max-width: 520px) {
    height: calc(100vh - 47px);
    height: calc(var(--vh, 1vh) * 100 - 47px);
  }

  @media (max-width: 500px) {
    height: calc(100vh - 54px);
    height: calc(var(--vh, 1vh) * 100 - 54px);
  }

  .shift-header {
    display: flex;
    width: 100%;
    top: 0;
    left: 0;
    max-height: 60px;
    height: 60px;
    align-items: center;
    z-index: 1001;
    background-color: #CAE3DD;
    justify-content: space-between;

    @media (max-width: 480px) {
      position: fixed;
      z-index: 9001;
    }

    .chat-room-title-container {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      font-size: 14px;
      height: 40px;
      text-align: center;
      width: 100%;
      background-color: #CAE3DD;
      cursor: pointer;

      .chat-title-back {
        height: 10px;
        transform: rotate(90deg);
        margin: 0 20px;
        filter: brightness(0) saturate(100%) invert(56%) sepia(33%) saturate(446%) hue-rotate(114deg) brightness(94%) contrast(93%);
        
        @media (max-width: 380px) {
          height: 8px;
          margin: 0 10px;
        }
      }

      .chat-room-title {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
  
        .notification-back {
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          max-width: 60vw;
          color: #55A08D;

          @media (max-width: 380px) {
            font-size: 12px;
          }
        }
      }
    }

    .logo-container {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 20px;
      width: 100%;
      height: 100%;

      .locumly-logo {
        max-height: 40px;
        padding: 5px 0;
        width: auto;
      }

      .locumly-logo-view {
        max-height: 35px;

        @media (max-width: 380px) {
          max-height: 25px;
        }
      }
    }

    .logo-viewing-message {
      justify-content: flex-end;
    }
  }

  .back-arrow-container {
    display: none;
    width: 200px;
    margin-left: 0px;

    @media (max-width: 480px) {
      margin-left: -5px;
    }
  }

  @media (max-width: 600px) {
    .back-arrow-container {
      display: flex;
    }
  }

  .notifications-container {
    width: 100%;
    background-color: #FFFFFF;
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
    box-sizing: border-box;
    display: flex;
    margin: 0 auto;
    height: calc(100vh - 180px);
    height: calc((var(--vh, 1vh) * 100) - 180px);
    border-radius: 10px;

    @media (max-width: 980px) {
      height: calc(100vh - 60px);
      width: 100%;
      border-radius: unset;
      box-shadow: unset;
    }

    @media (max-width: 600px) {
      height: calc(100vh - 50px);
    }

    @media (max-width: 520px) {
      height: calc(100vh - 47px);
    }

    @media (max-width: 500px) {
      height: calc(100vh - 57px);
    }

    @media (max-width: 480px) {
      height: calc(100vh - 104px);
    }
  }

  .sidebar-container {
    width: 100%;
    max-width: 400px;

    @media (max-width: 980px) {
      max-width: unset;
    }
  }

  @media (max-width: 980px) {
    .sidebar-container {
      height: 100%;

      .notifications-and-messages-sidebar {
        transition: all 0.6s;
        visibility: visible;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
      }

      &.hidden {
        .notifications-and-messages-sidebar {
          -webkit-transform: translate3d(-100%, 0, 0);
          transform: translate3d(-100%, 0, 0);
        }
      }
    }
  }
  @media (max-width: 480px) {
    .sidebar-container {
      &.viewing-notif {
        height: calc(100vh - 110px);
        height: calc((var(--vh, 1vh) * 100) - 110px);
      }
    }
  }

  .notification-information {
    width: 100%;
  }
}

.notification-display {
  padding: 20px;
  margin: 20px;
  height: calc(100% - 40px);
  background-color: #e6f3f1;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;

  @media (max-width: 980px) {
    margin: 0;
    height: 100%;
  }

  .avatar-and-date {
    display: flex;
    align-items: center;
    justify-content: center;

    .avatar {
      height: 55px;
      width: 55px;
      border-radius: 50px;
      border: 2px solid #56A08D;
      padding: 2px;
    }

    .date {
      margin: 0 0 0 15px;
      font-size: 18px;
    }
  }

  .notification-message {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 20px;
    text-align: center;

    .name {
      color: #56a08d;
      font-weight: 900;
    }

    .link {
      background-color: transparent;
      color: #000;
      font-size: 16px;
      font-weight: 900;
      padding: 0;
      text-decoration: underline;

      &:hover {
        cursor: pointer;
        filter: brightness(110%);
      }
    }
  }

  .paragraph {
    text-align: center;
    font-size: 16px;
    margin: 15px;
  }
}