.locum-manage-payments-page {
  display: flex;
  flex-direction: column;
  width: calc(100% - 260px);
  margin: 0 10px 35px;
  align-items: center;

  @media (max-width: 980px) {
    width: 100%;
  }

  .locum-manage-payments-title {
    font-size: 22px;
    color: #000000;
    font-weight: 900;
    margin: 10px 0;
  }
  
  .locum-manage-payments-subtitle {
    color: #1A1A1A;
    margin: 5px 0;
    font-size: 16px;
  }

  .manage-payments {
    display: flex;
    flex-direction: column;
    max-width: 600px;

    .skateboarder {
      height: 300px;
      width: 250px;
      margin: 20px auto 10px auto;
    }

    .manage-payments-button {
      display: flex;
      margin: 20px;

      .stripe-button {
        margin: auto;
        width: 280px;
        padding: 15px;
        background-color: #28446a;
        font-size: 15px;
        color: #FFFFFF;
        border-radius: 30px;
        box-shadow: rgb(0, 0, 0, 0.35) 0px 5px 15px;

        &.completed {
          background-color: #56A08D;
        }


        &:hover {
          filter: brightness(125%);
        }
      }
    }
  }
}