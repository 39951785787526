.store-hiring-banner-container {
  height: 80px;

  .store-hiring-banner {
    background-color: transparent;
    align-items: center;
    display: flex;
    flex-flow: column;
    position: relative;
    top: -80px;

    @media (max-width: 480px) {
      top: -70px;
    }

    @media (max-width: 420px) {
      top: -65px;
    }

    .store-hiring-button {
      background: #55A08D;
      border: 2px solid #55A08D;
      border-radius: 20px;
      width: 450px;
      height: 80px;
      display: flex;
      flex-flow: row;
      justify-content: space-evenly;
      align-items: center;

      &.disabled {
        &:hover {
          cursor: auto;
        }
      }

      @media (max-width: 600px) {
        width: 400px;
      }

      @media (max-width: 480px) {
        width: 307px;
        height: 72px;
      }

      @media (max-width: 360px) {
        width: 267px;
        height: 62px;
      }

      .button-text {
        font: 700 20px Inter, Helvetica, Arial, sans-serif;
        color: #FFFFFF;
        text-align: center;

        @media (max-width: 480px) {
          font-size: 16px;
        }
      }

      .roles-available {
        color: #FFFFFF;
        text-align: center;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .number {
          font: 500 30px Inter, Helvetica, Arial, sans-serif;
          margin-right: 10px;

          @media (max-width: 480px) {
            font-size: 25px;
            margin-right: 8px;
          }
        }

        .roles-text {
          width: 50px;
          font: 500 14px Inter, Helvetica, Arial, sans-serif;
          line-height: 18px;

          @media (max-width: 480px) {
            font-size: 11px;
            line-height: 13px;
          }
        }
      }
    }

    .buttons {
      margin-top: 25px;
      display: flex;
      justify-content: space-between;
      width: 420px;

      @media (max-width: 600px) {
        width: 360px;
      }

      @media (max-width: 480px) {
        width: 300px;
      }

      @media (max-width: 360px) {
        width: 280px;
      }

      .store-profile-button {
        height: 40px;
        width: 180px;
        font: 800 16px Inter, Helvetica, Arial, sans-serif;

        .share-icon {
          width: 15px;

          @media (max-width: 480px) {
            width: 14px;
          }
        }

        &.subscribed {
          background-color: #55A08D;
          color: #FFFFFF;

          &:hover {
            cursor: auto;
          }
        }

        @media (max-width: 600px) {
          width: 140px;
          height: 35px;
          font-size: 15px;
        }

        @media (max-width: 480px) {
          width: 130px;
          height: 37px;
          font-size: 15px;
        }

        @media (max-width: 360px) {
          width: 125px;
          height: 32px;
          font-size: 14px;
        }

        .text {
          margin-right: 15px;

          @media (max-width: 480px) {
            margin-right: 10px;
          }
        }
      }
    }
  }
}