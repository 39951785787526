.upcoming-shifts-page {
  display: flex;
  flex-direction: column;
  width: calc(100% - 260px);

  @media (max-width: 980px) {
    width: 100%;
  }

  @media (max-width: 480px) {
    align-items: center;
  }
  
  .upcoming-shifts-title {
    font: 400 24px inter-bold;
    margin: 10px 0;
    color: #1a1a1a;
    
    @media (max-width: 480px) {
      text-align: center;
    }
  }
  
  .upcoming-shifts-subtitle {
    color: #1A1A1A;
    margin: 5px 0;
    font-size: 16px;

    @media (max-width: 480px) {
      text-align: center;
    }
  }

  .back-arrow-container {
    margin-left: 0;
  }

  .shift-viewer-container {
    margin: 42px 30px 0 0;

    @media (max-width: 620px) {
      margin: 42px 0 0 0;
    }

    @media (max-width: 480px) {
      width: calc(100% - 10px);
    }
  }
}

.shift-list-viewer {
  display: flex;
  flex-direction: column;
  background-color: #E3E1D6;
  justify-content: center;
  align-items: center;
  padding: 20px;
  width: 100%;
  height: 420px;

  @media (max-width: 620px) {
    padding: 20px 0;
  }

  .shift-list {
    display: flex;
    width: 100%;
    overflow-y: hidden;
    overflow-x: auto;

    &.no-shifts {
      justify-content: center;
      align-items: center;
      height: 100%;
      text-align: center;
    }

    .no-shifts-text {
      margin: -20px 0 0 0;
      text-align: center;
    }
  }
}

.view-shift-container {
  .button-containers {
    display: flex;
    justify-content: space-between;
    margin: 5px 0;

    .shift-buttons {
      display: flex;
      justify-content: space-between;
      width: 300px;

      @media (max-width: 480px) {
        width: auto;
        align-self: flex-end;
      }

      .edit-shift, .delete-shift {
        background-color: #808080;
        font-size: 14px;
        color: #FFFFFF;
        border-radius: 20px;
        width: 140px;
        height: 35px;

        @media (max-width: 480px) {
          width: 100px;
          margin: 0 5px;
          height: 30px;
        }

        @media (max-width: 420px) {
          width: 75px;
          font-size: 12px;
          margin: 0 3px;
        }
      }
    }
  }
}

.confirmation-modal-container {
  &.edit .confirmation-modal {
    height: 480px;

    @media (max-width: 620px) {
      width: 320px;
    }

    @media (max-width: 480px) {
      width: 280px;
      padding: 20px;
      height: 490px;
    }

    @media (max-width: 360px) {
      width: 260px;
      padding: 20px 15px;
      height: 510px;
    }

    .confirmation-modal-content {
      .reason-dropdown-container {
        margin: 5px 0;
      }

      .buttons {
        display: flex;
        justify-content: space-evenly;

        .cancel, .confirm {
          font-size: 14px;
          padding: 10px 20px;
        }
      }

      .shift-changes {
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        font-size: 16px;

        @media (max-width: 480px) {
          font-size: 14px;
        }

        .change-item {
          margin: 5px 0;
          display: flex;
          height: 28px;

          .change-label {
            min-width: 120px;
            margin: auto 0;
          }

          .change-label, .bold {
            font-family: Arial,Helvetica,sans-serif;
          }

          .bold {
            font-weight: 900;
            width: 100px;
            text-align: center;

            @media (max-width: 480px) {
              font-size: 14px;
            }
          }

          .react-datepicker-wrapper .react-datepicker__input-container input,
          .dropdown-button .data-view-button,
          .rate {
            width: 100px;
            font-size: 16px;
            font-weight: 900;
            border: solid 2px #BFBFBF;
            background-color: #FFFFFF;
            border-radius: 5px;
            padding: 2px 0;
            text-align: center;

            &.disabled {
              filter: brightness(80%);
            }
            
            @media (max-width: 480px) {
              font-size: 14px;
            }
          }

          .dropdown-button {
            &.time, &.yes-no {
              margin: 0;

              .data-view-button {
                width: 104px;

                &.disabled {
                  cursor: auto;
                  filter: brightness(80%);
                }

                @media (max-width: 480px) {
                  font-size: 14px;
                }

                &.open {
                  border-bottom: none;
                  border-radius: 5px 5px 0 0;
                }
              }

              .dropdown-list-container .dropdown-list {
                width: 100px;

                @media (max-width: 480px) {
                  font-size: 14px;
                }
              }
            }
          }
        }
      }
    }
  }
}

.confirmation-modal-container.delete-shift-confirmation {
  .confirmation-modal {
    @media (max-width: 620px) {
      width: 380px;
    }

    @media (max-width: 480px) {
      width: 320px;
    }

    @media (max-width: 420px) {
      width: 280px;
      height: 210px;

      .buttons {
        display: flex;
        align-self: normal;
        justify-content: space-evenly;

        .cancel, .confirm {
          font-size: 14px;
          padding: 10px 20px;
        }
      }
    }

    @media (max-width: 360px) {
      width: 240px;
    }
  }
}

.confirmation-modal {
  .confirmation-modal-content {
    .reason-dropdown-container {
      margin: 20px 0;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 30px;

      .reason-text {
        font-size: 16px;
        font-family: Arial,Helvetica,sans-serif;
        margin-right: 20px;

        @media (max-width: 480px) {
          width: 200px;
          font-size: 14px;
          padding: 5px;
        }
      }

      .reason-dropdown {
        height: 30px;

        .reason-button {
          width: 250px;
          border: solid 2px #808080;
          padding: 5px 20px;
          background-color: #FFFFFF;
          font-size: 16px;
          border-radius: 6px;

          @media (max-width: 480px) {
            width: 200px;
            font-size: 14px;
            padding: 5px;
          }
          
          &.open {
            border-bottom: none;
            border-radius: 6px 6px 0 0;
          }
        }

        .reason-options-container {
          position: absolute;
          height: 0;

          .reason-options {
            position: relative;
            width: 246px;
            z-index: 10;
            border: solid 2px #808080;
            border-top: none;
            align-items: center;
            display: flex;
            flex-direction: column;

            @media (max-width: 480px) {
              width: 196px;
              font-size: 14px;
            }

            .reason-option {
              width: 100%;
              height: 30px;

              .reason-option-button {
                width: 100%;
                background-color: #FFFFFF;
                font-size: 16px;
                padding: 6px 0;

                @media (max-width: 480px) {
                  font-size: 14px;
                  padding: 8px 0;
                }
              }
            }
          }
        }
      }
    }
  }
}