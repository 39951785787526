.locum-page {
  display: flex;
  flex-direction: row;
  margin: 0 35px 35px 35px;
  padding: 30px 0 0 0;

  @media (max-width: 980px) {
    margin: 0 35px;
    padding: 0;
  }

  @media (max-width: 620px) {
    margin: 0 10px;
  }

  @media (max-width: 480px) {
    margin: 0;
    padding: 0;
    min-height: 100vh;
    min-height: calc(var(--vh, 1vh) * 100);
  }
}
