.store-profile-section.words-from-admin {
  margin: 50px 50px;
  max-width: 650px;
  align-self: center;

  @media (max-width: 480px) {
    margin: 40px 20px; 
  }

  .text {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font: 400 18px Inter, Helvetica, Arial, sans-serif;
    line-height: 25px;
    margin-top: 22px;

    @media (max-width: 480px) {
      font-size: 16px;
      line-height: 22px;
    }
  }
}