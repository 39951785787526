.store-profile-modal-wrapper {
  position: fixed;
  z-index: 9999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%; 
  overflow: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000000;
  white-space: normal;

  .store-profile-modal {
    background: #FFFFFF;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    border-radius: 5px;
    padding: 10px;
    display: flex;
    flex-direction: column;

    .close-modal-button-container {
      height: 0;
      align-self: flex-end;
      background: transparent;

      .close-modal-button {
        color: #B5B5B5;
        font: 600 30px Inter, Helvetica, Arial, sans-serif;
        position: relative;
        background: transparent;
        right: 5px;
        top: -5px;
      }
    }

    .modal-content {
      .modal-title {
        color: #1A1A1A;
        font: 700 18px Inter, Helvetica, Arial, sans-serif;
        margin-bottom: 20px;
      }
    }
  }
}