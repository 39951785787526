.store-card-container {
  min-width: 360px;
  margin-right: 30px;

  @media (max-width: 480px) {
    min-width: 300px;
  }

  .store-card {
    display: flex;
    width: 100%;
    height: 250px;
    background-color: #E3E1D6;
    border: solid 1.5px #BFBFBF;
    padding: 10px 0px 10px 10px;

    @media (max-width: 480px) {
      min-width: 220px;
      height: 240px;
    }

    .store-details {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 200px;

      @media (max-width: 480px) {
        width: 140px;
      }

      .store-name {
        font-size: 15px;
        font-weight: 900;
        color: #55A08D;

        @media (max-width: 480px) {
          font-size: 13px;
        }
      }

      .store-address {
        margin: 5px 0 5px 0;
        font-size: 12px;

        @media (max-width: 480px) {
          font-size: 10px;
        }
      }

      .store-avatar {
        width: 160px;
        height: 150px;
        border: solid 2px #55A08D;

        @media (max-width: 480px) {
          width: 130px;
          height: 120px;
        }
      }
    }   

    .store-buttons {
      display: flex;
      flex-direction: column;
      width: 160px;

      @media (max-width: 480px) {
        margin-left: 5px;
      }
    }
  }
}