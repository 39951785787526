
.notifications-and-messages-sidebar {
  background-color: #CAE3DD;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  border-right: 1px solid #ECECEC;

  @media (max-width: 980px) {
    border-top-left-radius: unset;
    border-bottom-left-radius: unset;
    font-size: 16px;
  }

  .notif-number {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 24px;
    width: 24px;
    border-radius: 5px;
    background-color: #F16B58; 
    font-size: 11px;
    color: #FFFFFF;
    font-weight: 900;
  }

  .notifications-and-messages-navigation {
    display: flex;
    border-radius: 10px 10px 0px 0px;
    background-color: #ABD2C8;

    @media (max-width: 980px) {
      border-radius: 30px 30px 0px 0px;
    }

    .nav-button {
      width: 100%;
      font-size: 18px;
      padding: 20px 0px;
      border: none;
      background-color: #ABD2C8;
      font-weight: 500;
      border-radius: 10px 10px 0px 0px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-transform: uppercase;

      &.msg-border {
        border-top-right-radius: 0;
        order: 1;
        @media (max-width: 980px) {
          border-radius: 30px 30px 0px 0px;
          order: 0;
        }
      }

      .msg-notif-unread {
        margin-left: 5px;
      }

      @media (max-width: 1300px) {
        // width: 175px;
        font-size: 16px;
      }

      @media (max-width: 1200px) {
        // width: 150px;
        font-size: 14px;
        padding: 15px 0;
      }

      @media (max-width: 980px) {
        // width: 125px;
        border-radius: 30px 30px 0px 0px;
        padding: 15px 0;
        text-transform: unset;
        font-weight: 900;
      }

      &.selected {
        color: #55a08d;
        background-color: #FFFFFF;
      }

      &:hover {
        cursor: pointer;
        filter: brightness(90%);
      }
    }
  }

  .notification-list {
    background-color: #FFFFFF;
    padding: 0;
    font-size: 14px;
    height: 100%;
    overflow-y: scroll;
    border-bottom-left-radius: 10px;
    width: 100%;

    @media (max-width: 1200px) {
      font-size: 13px;
    }

    @media (max-width: 980px) {
      font-size: 12px;
      border-bottom-left-radius: unset;
    }

    @media(max-width: 480px) {
      padding-bottom: 47px;
    }

    .notification {
      display: flex;
      margin: 0px 0px;
      padding: 18px 15px;
      z-index: 10;
      font-size: 11px;
      border-bottom: 0.2px solid #CECECE;

      &.selected {
        background-color: #F3FFFD;
      }

      @media (max-width: 1300px) {
        padding: 15px 15px;
      }

      @media (max-width: 1200px) {
        padding: 15px 10px;
      }

      @media (max-width: 980px) {
        padding: 20px 10px;
      }

      &:hover {
        cursor: pointer;
        background-color: #F3FFFD;
        filter: brightness(90%);
      }

      .avatar {
        border-radius: 65px;
        height: 65px;
        width: 65px;
        align-self: center;

        @media (max-width: 980px) {
          height: 55px;
          width: 55px;
        }
      }

      .link {
        text-decoration: underline;
        background-color: transparent;
        color: #000000;
        font-weight: 900;
        padding: 0;
        font-size: 11px;

        &:hover {
          cursor: pointer;
          filter: brightness(120%);
        }
      }

      .date-and-notification-message {
        margin: 4px 0px 0px 12px;
        z-index: 10;

        .date {
          color: #aca9a9;
        }

        .notif-type {
          font-size: 13px;
          font-weight: 900;
        }

        .notification-message {
          color: #504f4f;
          margin-top: 8px;

          .name {
            font-weight: 900;
            color: #56A08D;
          }

          b {
            color: #000000;
          }
        }
      }
    }
  }

  .message-list {
    background-color: #FFFFFF;
    font-size: 12px;
    height: calc(100% - 47px);
    overflow-y: scroll;
    width: 100%;
    border-bottom-left-radius: 10px;

    @media(max-width: 980px) {
      border-bottom-left-radius: unset;
    }

    @media(max-width: 480px) {
      padding-bottom: 47px;
    }

    .empty-inbox {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;

      .empty-inbox-text {
        text-align: center;

        .find-link {
          background-color: transparent;
          font-weight: 900;
          text-decoration: underline;
          color: #56A08D;
        }
      }
    }

    .message-item {
      display: flex;
      flex-direction: row;
      margin: 0;
      padding: 20px 10px;
      z-index: 10;
      justify-content: space-between;
      border-bottom: 0.2px solid #CECECE;

      .notif-num-container {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .message-body {
        display: flex;
        align-items: center;

        .avatar {
          border-radius: 50px;
          height: 55px;
          width: 55px;
        }
  
        .name-and-message {
          display: flex;
          flex-direction: column;
          margin: 5px 10px 0px 10px;
          padding: 5px 0px;
  
          .name {
            font-size: 14px;
            margin-bottom: 10px 5px;
            color: #333232;
            
            @media (max-width: 980px) {
              font-size: 12px;
            }
          }
  
          .message-and-timestamp {
            display: flex;
            flex-direction: row;
            color: #aca9a9;
            margin-top: 5px;
  
            .message {
              font-size: 12px;
              max-width: 260px;
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap;
              margin-right: 10px;
  
              @media (max-width: 1200px) {
                max-width: auto;
                margin-right: 0px;
              }
            }
  
            .timestamp {
              color: #aca9a9;
            }
          }
  
          &.new {
            .name {
              color: #000000;
              font-weight: bold;
            }
  
            .message-and-timestamp {
              color: #000000;
              font-weight: bold;
  
              .timestamp {
                color: #aca9a9;
              }
            }
          }
        }
      }

      &.selected {
        background-color: #F3FFFD;
      }

      &:hover {
        background-color: rgb(231, 231, 227);
        cursor: pointer;
        filter: brightness(90%);
      }
    }
  }
}