.help-page {
  padding: 30px 0 0 0;

  .title {
    font: 900 32px Arial;
    margin: 0px 0 0 30px;
    text-align: left;
  }

  .help-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    margin: 10px 0 30px 0;
  }
}

.help-item {
  width: 360px;
  height: auto;
  margin: 20px 10px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

  @media (max-width: 420px) {
    width: 300px;
  }

  @media (max-width: 360px) {
    width: 280px;
  }

  .help-link {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    padding: 0;

    &:hover {
      cursor: pointer;
      filter: brightness(80%);
      box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
    }

    .help-image {
      height: 220px;
      width: 100%;

      @media (max-width: 420px) {
        height: 190px;
      }

      @media (max-width: 360px) {
        height: 160px;
      }
    }

    .text {
      height: 120px;
      padding: 0 20px 20px 20px;
      display: flex;
      flex-direction: column;

      .date {
        color: #808080;
        font-size: 12px;
        align-self: flex-start;
        margin: 10px 0 5px 0;

        @media (max-width: 480px) {
          font-size: 11px;
        }
      }

      .help-title {
        font-size: 18px;
        align-self: flex-start;
        text-align: left;
        margin: 0 0 10px 0;
        height: 40px;

        @media (max-width: 480px) {
          font-size: 16px;
        }
      }

      .subtitle {
        font-size: 14px;
        color: #808080;
        text-align: left;

        @media (max-width: 480px) {
          font-size: 12px;
        }
      }
    }
  }
}