$cloudfront-cdn-url: 'https://dq55dzprwc7f6.cloudfront.net';

.locum-profile-page {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: calc(100% - 260px);

  @media (max-width: 980px) {
    height: calc(100vh - 60px);
    height: calc(var(--vh, 1vh) * 100 - 60px);
    width: 100%;
  }

  .locum-profile-header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    color: #55A08D;
    margin: 20px 0;

    @media (max-width: 980px) {
      width: 90%;
    }

    .profile-subtitle {
      margin-left: 20px;
      color: #000000;
    }

    .locum-profile-header-details {
      .locum-profile-name {
        font-weight: 900;
      }

      .locum-profile-address {
        font-size: 12px;
      }

      .locum-profile-abn-ahpra {
        font-size: 14px;
        display: flex;

        .abn-text {
          margin-right: 10px;
        }

        @media (max-width: 420px) {
          flex-direction: column;
        }

        .red-text {
          color: red;
        }
      }

      

      .manage-account-link {
        border: 2px solid #55A08D;
        border-radius: 20px;
        box-sizing: border-box;
        padding: 4px 12px;
        color: #55A08D;
        margin-top: 10px;
      }
    }

    .locum-avatar-container {
      .locum-avatar {
        border-radius: 200px;
        height: 120px;
        width: 120px;

        @media (max-width: 720px) {
          height: 80px;
          width: 80px;
        }
      }
    }
  }

  .locum-profile-details {
    display: flex;
    flex-direction: column;
    background-color: #FFFFFF;
    border-radius: 30px;
    width: 100%;

    @media (max-width: 980px) {
      height: calc(100vh - 224px);
      height: calc(var(--vh, 1vh) * 100 - 224px);
      background-color: #CAE3DD;
    }

    @media (max-width: 720px) {
      height: calc(100vh - 195px);
      height: calc(var(--vh, 1vh) * 100 - 195px);
    }

    @media (max-width: 420px) {
      height: calc(100vh - 212px);
      height: calc(var(--vh, 1vh) * 100 - 212px);
    }

    &.edit-background {
      @media (max-width: 980px) {
        background-color: #ABD2C8;
      }
    }

    .edit-detail-pane {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      width: 100%;
      position: relative;

      .shift-overlay-button {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        position: absolute;
        height: 55px;

        &.overlay-style {
          position: relative;
        }
        
        .profile-background {
          display: flex;
          background-color: #ABD2C8;
          width: 20%;
          height: 5px;
          border-radius: 30px;

          .profile-shift-overlay {
            width: 50%;
            height: 5px;
            border-radius: 30px;
            background-color: #ABD2C8;
            cursor: pointer;
  
            &.selected {
              background-color: #55A08D;
            }
          }
        }
      }

      .edit-detail-button {
        justify-self: flex-end;
        height: 55px;
        width: 55px;
        background-color: #FFFFFF;
        border-radius: 0px 30px;

        @media (max-width: 980px) {
          background-color: #ABD2C8;
        }

        .edit-detail-img {
          filter: invert(61%) sepia(44%) saturate(364%) hue-rotate(114deg) brightness(86%) contrast(88%);
        }
      }
    }

    .prof-logo-container {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 10px 0;

      .white-logo {
        height: 40px;
      }
    }

    .scroll-container {
      height: 100%;
      padding-top: 1px;

      @media (max-width: 980px) {
        overflow-y: scroll;
      }

      .comp-shift-container {
        display: flex;
        margin: 40px 40px 0 0;
        width: 100%;

        @media (max-width: 980px) {
          margin: 0;
          flex-direction: column;
          padding: 0 30px;
          width: unset;
        }

        .find-button {
          background-color: transparent;
          font-size: 16px;
          color: #55A08D;
          font-weight: 900;
        }

        .no-completed {
          margin: 0px auto;
          @media (max-width: 980px) {
            margin: 60px auto;
          }
        }

        .comp-header {
          display: flex;
          flex-direction: column;
          margin-bottom: 10px;
          width: 25%;

          @media (max-width: 980px) {
            width: unset;
          }

          .comp-text {
            color: #55A08D;
            font-weight: 900;
          }

          .comp-sub {
            font-weight: 100;
            font-size: 11px;
          }
        }

        .monthly-shifts-container {
          display: flex;
          overflow-x: scroll;
          width: 70%;

          @media (max-width: 980px) {
            display: block;
            overflow-x: unset;
            width: unset;
          }

          &.no-shifts-complete {
            overflow-x: unset;
          }

          .month-container {
            display: flex;
            flex-direction: column;
            margin: 0 20px;
            min-width: 140px;
            max-width: 140px;
  
            @media (max-width: 980px) {
              margin: 15px 0 0 0;
              min-width: unset;
            }
  
            .shift-dates {
              color: #55A08D;
              font-weight: 900;
              font-size: 12px;
            }
  
            .shift-name {
              font-size: 12px;
              margin-top: 5px;
            }
          }
        }
      }

      .edit-detail {
        display: flex;
        flex-direction: column;
        font-size: 14px;

        @media (max-width: 980px) {
          font-size: 16px;
        }

        .tag-container {
          display: flex;
          height: fit-content;
          flex-wrap: wrap;

          .tag-item {
            display: flex;
            align-items: center;
            border: 2px solid #FDCA38;
            border-radius: 20px;
            padding: 5px 10px;
            color: #FDCA38;
            font-size: 12px;
            box-sizing: border-box;
            margin-right: 4px;
            margin-bottom: 4px;

            @media (max-width: 980px) {
              color: #FFFFFF;
              border: 2px solid #FFFFFF;
            }

            &.skill-tag {
              border-color: #55A08D;
              color: #55A08D;
            }

            .delete-tag {
              display: flex;
              justify-content: center;
              align-items: center;
              background-color: #FFFFFF;
              color: #CAE3DD;
              border-radius: 10px;
              width: 15px;
              height: 15px;
              margin-left: 4px;
              cursor: pointer;
            }

            .add-tag {
              display: flex;
              justify-content: center;
              align-items: center;
              margin-left: 4px;
              font-size: 14px;
              cursor: pointer;
              filter: invert(68%) sepia(16%) saturate(993%) hue-rotate(114deg) brightness(81%) contrast(84%);

              @media (max-width: 980px) {
                filter: unset;
              }

              &.skill-add {
                filter: invert(58%) sepia(67%) saturate(258%) hue-rotate(114deg) brightness(86%) contrast(85%);
              }
            }
          }
        }
  
        .profile-about-me {
          display: flex;
          margin-bottom: 20px;
  
          .profile-heading {
            display: flex;
            align-items: center;

            @media (max-width: 980px) {
              display: block;
            }

            .profile-tag {
              font-size: 14px;
              padding: 10px 30px;
              border-radius: 50px;
              color: #FFFFFF;
              background-color: #55A08D;
              text-align: center;
              width: 80px;

              @media (max-width: 980px) {
                writing-mode: tb-rl;
                rotate: 180deg;
                padding: 30px 12px;
                height: 85px;
                width: unset;
                margin-left: 20px;
              }
            }
  
            .separator-prof {
              @media (max-width: 980px) {
                height: 100%;
                border-left: 2px dotted #55A08D;
                margin-left: 40px;
                min-height: 15px;
              }
            }
          }
  
          .about-me-text {
            display: flex;
            color: #000000;
            margin: 0 auto;
            width: 70%;
            box-sizing: border-box;
            padding: 10px;
            background-color: transparent;
            border: 1px solid #FFFFFF;
            resize: none;

            &.disabled {
              border: solid 1px transparent;
            }
          }
        }
  
        .profile-exp {
          display: flex;
          margin-bottom: 40px;
          @media (max-width: 980px) {
            margin-bottom: 20px;
          }
  
          .exp-heading {
            display: flex;
            align-items: center;

            @media (max-width: 980px) {
              display: block;
            }
            .exp-tag {
              font-size: 14px;
              border-radius: 50px;
              color: #FFFFFF;
              background-color: #EFCB68;
              text-align: center;
              padding: 10px 30px;
              width: 80px;

              @media (max-width: 980px) {
                writing-mode: tb-rl;
                rotate: 180deg;
                padding: 30px 12px;
                height: 85px;
                width: unset;
                margin-left: 20px;
              }
            }
  
            .separator-exp {
              @media (max-width: 980px) {
                height: 100%;
                border-left: 2px dotted #EFCB68;
                margin-left: 40px;
                min-height: 15px;
              }
            }
          }
  
          .exp-text {
            display: flex;
            align-items: center;
            margin: 0 auto;
            width: 70%;
          }
        }
  
        .profile-skills {
          display: flex;
  
          .skills-heading {
            display: flex;
            align-items: center;

            @media (max-width: 980px) {
              display: block;
            }
            .skills-tag {
              font-size: 14px;
              padding: 10px 30px;
              border-radius: 50px;
              color: #FFFFFF;
              background-color: #ABD2C8;
              text-align: center;
              width: 80px;

              @media (max-width: 980px) {
                writing-mode: tb-rl;
                rotate: 180deg;
                padding: 30px 12px;
                height: 85px;
                width: unset;
                margin-left: 20px;
              }

              &.edit-skill {
                @media (max-width: 980px) {
                  margin-left: 18px;
                }
                border: 2px solid #FFFFFF;
              }
            }
          }
  
          .skills-text {
            display: flex;
            margin: 0 auto;
            align-items: center;
            width: 70%;
          }
        }
      }
    }
  }

  .add-tag-container {
    display: flex;
    position: fixed;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    margin: auto;
    background-color: rgba(0, 0, 0, 0.25);

    .add-tag-modal {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: center;
      background-color: #FFFFFF;
      height: 300px;
      width: 300px;
      border-radius: 30px;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.07);

      &.only-one-skill {
        height: 250px;
      }
  
      .add-tag-modal-heading {
        font-size: 12px;
        color: #55A08D;
      }

      .confirm-tag {
        border: 2px solid #55A08D;
        border-radius: 15.5px;
        padding: 0 10px;
        cursor: pointer;
        filter: invert(61%) sepia(44%) saturate(364%) hue-rotate(114deg) brightness(86%) contrast(88%);
  
        .confirm-icon {
          height: 12px;
        }
      }

      .dropdown-item {
        width: 80%;

        .experience-label {
          display: flex;
          width: 100%;
          font-size: 10px;
          margin-bottom: 5px;
        }
    
        .add-dropdown {
          width: 100%;
          border-color: #CAE3DD;
          font-weight: 900;
          font-size: 12px;
          padding: 3px;
          box-sizing: border-box;
          appearance: none;
          background-image: url($cloudfront-cdn-url + '/dropdown/dropdown-arrow-black.svg');
          background-repeat: no-repeat;
          background-color: #FFFFFF;
          color: #000000;
          background-position-x: 95%;
          background-position-y: 50%;
          background-size: 5%;
        }

        .network-items {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          gap: 5px;

          .network-button {
            padding: 2px;
            font-size: 10px;
            border: 2px solid #55A08D;
            border-radius: 20px;
            color: #55A08D;

            &.selected {
              color: #FFFFFF;
              background-color: #55A08D;
            }
          }
        }
      }
    }
  }
}