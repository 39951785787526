$cloudfront-cdn-url: 'https://dq55dzprwc7f6.cloudfront.net';

.find-page {
  background: url($cloudfront-cdn-url + '/map/shifts-background.svg') no-repeat;
  background-size: 100%;
  min-height: 100vh;

  @media (max-width: 480px) {
    background: none;
    min-height: unset;
  }

  .find {
    background-color: #DCDCDC;
    padding: 0 0 30px 0;
    box-sizing: border-box;

    .search-container {
      margin: 0 30px;
      display: flex;
      flex-direction: column;
      align-items: center;

      .find-page-title {
        padding: 30px 0;
        color: #55A08D;
        align-self: flex-start;

        .title {
          font: 36px Inter, mundo-bold;
        }

        .subtitle {
          font-size: 20px;
        }
      }

      .search-bar {
        width: 95%;
        max-width: 1200px;
        margin: 0;
      }
    }

    &.selected {
      .shift-card {
        border: solid 2px #8F7CFD;
      }
    }
  }
}

.sort-modal {
  position: absolute;
  display: flex;
  flex-direction: column;
  border-radius: 30px;
  background-color: #CAE3DD;
  right: 20px;
  height: 150px;
  width: 250px;
  justify-content: center;
  align-items: center;
  z-index: 1;

  .check-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 80%;
    .check-item {
      display: flex;
      align-items: center;
      width: 90%;
      justify-content: space-between;

      .check-input-container {
        display: flex;
        align-items: center;
        justify-content: center;

        .check-input {
          height: 20px;
          width: 20px;
          cursor: pointer;
        }

        .check-text {
          display: flex;
          justify-content: space-between;
          width: 100%;
          font-size: 14px;
        }
      }

      .check-arrow-dir {
        height: 18px;
        width: 12px;
        transition: all 0.25s ease;
        cursor: pointer;

        &.rotate-arrow {
          transform: rotate(180deg);
        }
      }
    }
  }

  .sort-modal-text {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
    color: #55A08D;
    font-size: 16px;

    .sort-icon-modal {
      height: 20px;
      width: 20px;
      margin-left: 8px;
      filter: invert(58%) sepia(62%) saturate(271%) hue-rotate(114deg) brightness(86%) contrast(87%);
    }
  }

  &.hide {
    display: none;
  }
}

.find-page {
  .map-container {
    position: relative;
    padding-top: 20px;
    
    @media (max-width: 480px) {
      padding-top: 60px;
    }

    .map-iframe {
      height: calc(100vh - 291px);
      height: calc((var(--vh, 1vh) * 100) - 291px);
      width: 100vw;

      @media (max-width: 1440px) {
        height: calc(100vh - 271px);
        height: calc((var(--vh, 1vh) * 100) - 271px);
      }

      @media (max-width: 980px) {
        height: calc(100vh - 261px);
        height: calc((var(--vh, 1vh) * 100) - 261px);
      }

      @media (max-width: 480px) {
        height: calc(100vh - 181px);
        height: calc((var(--vh, 1vh) * 100) - 181px);
      }

      .mapboxgl-ctrl-group {
        background-color: #55A08D;
        border-radius: 20px;
        box-shadow: unset;
        
        .mapboxgl-ctrl-geolocate {
          display: flex;
          align-items: center;
          justify-content: center;
          z-index: 1000;

          &:hover {
            background-color: unset;
          }
  
          .mapboxgl-ctrl-icon {
            background-image: url($cloudfront-cdn-url + '/map/location-arrow.svg');
            height: 18px;
            width: 18px;
          }
        }
      }
    }
  }

  .hide-map {
    display: none;
  }
}

.find-page {
  .find-shifts-container {
    display: flex;
    flex-direction: row;
    max-width: 1440px;
    margin: 50px auto;
    background-color: #FFFFFF;
    padding: 15px;

    @media (max-width: 880px) {
      flex-direction: column;
      margin: 30px 0 0 0;
      padding: 0;
    }

    @media (max-width: 480px) {
      padding: 0;
    }

    .closed-map {
      visibility: hidden;
    }

    .map-iframe, .shift {
      width: 70%;
      height: 746px;
      margin-top: 133px;

      @media (max-width: 980px) {
        width: 67.5%;
        height: 696px;
      }

      @media (max-width: 880px) {
        width: 100%;
      }

      @media (max-width: 700px) {
        height: 650px;
      }

      @media (max-width: 600px) {
        height: 600px;
      }
    }

    @media (max-width: 480px) {
      .shift {
        height: auto;
      }
    }
  }
}

.map-iframe {
  border: none;
  z-index: 0;

  .marker.total-marker {
    background-color: transparent;
    padding: 4px;
    border-radius: 20px;
    background-image: url($cloudfront-cdn-url + '/map/default-icon.png');
    width: 40px;
    height: 40px;
    background-size: 100%;

    &:hover {
      cursor: pointer;
      filter: brightness(120%);
    }

    &.selected {
      background-image: url($cloudfront-cdn-url + '/map/purple-icon.png');

      &:hover {
        filter: brightness(100%);
      }
    }
  }
}

.find-page {
  .find-container {
    padding-top: 30px;

    @media (max-width: 480px) {
      padding-top: 0;
    }

    .quick-filter-container {
      display: flex;
      height: 60px;
      width: 50%;

      @media (max-width: 780px) {
        width: 100%;
      }

      @media (max-width: 480px) {
        top: 60px;
        position: fixed;
        background-color: #CAE3DD;
        margin-top: 0px;
        z-index: 1;
      }

      .quick-filter-border {
        display: flex;
        justify-content: space-around;
        align-items: center;
        flex-wrap: wrap;
        text-align: center;
        width: 100%;
        box-sizing: border-box;
        border-radius: 30px 30px 0px 0px;
        font-size: 10px;

        @media (max-width: 480px) {
          background-color: #FFFFFF;
          font-size: 9px;
        }

        .quick-filter {
          display: flex;
          flex-direction: column;
          height: 100%;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          
          &.selected {
            filter: invert(58%) sepia(25%) saturate(626%) hue-rotate(114deg) brightness(94%) contrast(87%);
          }
          
          .quick-filter-icon {
            height: 22px;
            width: 30px;
          }
        }
      }
    }

    .find-view-selection {
      display: flex;
      height: 60px;
      width: 100%;
      z-index: 1;
      margin-top: 10px;

      @media (max-width: 480px) {
        top: 120px;
        position: fixed;
        background-color: #CAE3DD;
        margin-top: 0px;
      }

      .view-container {
        display: flex;
        justify-content: space-around;
        align-items: center;
        flex-wrap: wrap;
        text-align: center;
        width: 100%;
        box-sizing: border-box;
        // border-radius: 30px 30px 0px 0px;

        @media (max-width: 480px) {
          background-color: #FFFFFF;
        }

        @media (max-width: 385px) {
          font-size: 14px;
        }

        .store-shift-container {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 20px;
          cursor: pointer;

          .store-back-arrow {
            margin-right: 10px;
            height: 16px;
            width: 16px;
          }

          .store-shift-title {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .store-shift-name {
              text-overflow: ellipsis;
              overflow: hidden;
              max-width: 40vw;
              white-space: nowrap;
              font-size: 14px;

              @media (max-width: 440px) {
                font-size: 12px;
              }
            }

            .store-shift-address {
              text-overflow: ellipsis;
              overflow: hidden;
              max-width: 40vw;
              white-space: nowrap;
              font-size: 14px;

              @media (max-width: 440px) {
                font-size: 12px;
              }
            }
          }
        }
    
        .view-type-container {
          display: flex;
          border: 2px solid #55A08D;
          border-radius: 30px;
          position: relative;
          height: 30px;
          width: 160px;

          @media (max-width: 385px) {
            height: 26px;
            width: 150px;
          }

          .shift-view {
            left: 0;
          }

          .store-view {
            right: 0;
          }

          .view-type {
            padding: 9px 18px;
            border-radius: 20px;
            font-weight: 600;
            font-size: 10px;
            position: absolute;
            margin: 0 -1px;
            color: #55A08D;
            cursor: pointer;
  
            // @media (max-width: 480px) {
            //   background-color: #FFFFFF;
            //   border: none;
            //   color: #000000;
            // }
  
            @media (max-width: 385px) {
              padding: 7px 15px;
            }
          }
          
          .selected {
            background-color: #55A08D;
            color: #FFFFFF;
  
            // @media (max-width: 480px) {
            //   background-color: #CAE3DD;
            //   color: #55A08D;
            // }
          }
        }
    
        .avail-shifts {
          display: flex;
          flex-direction: column;
          width: 90px;

          @media (max-width: 385px) {
            width: 70px;
          }
  
          .filter-num {
            width: 100%;
            font-weight: 500;
            font-size: 20px;

            @media (max-width: 385px) {
              font-size: 16px;
            }
          }
  
          .formatted-text {
            font-size: 12px;

            @media (max-width: 385px) {
              font-size: 10px;
            }
          }
        }

        .sort-icon-container {
          display: flex;
          margin-right: 15px;
          position: relative;
          cursor: pointer;
          background-color: transparent;

          .sort-icon {
            @media (max-width: 385px) {
              height: 18px;
              width: 18px;
            }

            &.has-text {
              margin-left: 10px;
            }
          }
        }
      }
    }
    
    .shifts-container {
      display: flex;
      margin-top: 40px;
      
      @media (max-width: 480px) {
        margin-top: 185px;
        background-color: #FFFFFF;
      }

      .shifts-padding {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 30px;
        width: 100%;
        margin-left: 0px;
        margin-right: 0px;
        padding: 0 40px;
        box-sizing: border-box;

        @media (max-width: 1260px) {
          grid-template-columns: repeat(3, 1fr);
          grid-gap: 20px;
        }

        @media (max-width: 820px) {
          grid-template-columns: repeat(2, 1fr);
        }

        @media (max-width: 480px) {
          display: flex;
          align-items: stretch;
          justify-content: flex-start;
          flex-wrap: wrap;
          padding: 0 18px;
          grid-gap: 0;
        }
      }
    }
    
    .map-button-container {
      display: flex; 
      justify-content: center;
      align-items: flex-end;
      position: fixed;
      width: 100%;
      bottom: 70px;
      transition: -ms-transform 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955), 
      -webkit-transform 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955),
      transform 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955), 
      visibility 0.2s !important;
      z-index: 1;
      pointer-events: none;
      max-width: 1920px;

      &.web-view {
        bottom: 40px;
      }
      
      .use-my-location {
        position: fixed;
        right: 36px;

        .size-container {
          height: 40px;
          flex-direction: row;
          background-color: rgb(247, 247, 247);
          background: rgb(255, 255, 255);
          box-shadow: rgb(0 0 0 / 12%) 0px 6px 16px;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          border-radius: 8px;

          &:hover {
            background-color: rgb(247, 247, 247) !important;
          }

          .location-button {
            margin: 0px;
            padding: 0px;
            overflow: visible;
            outline: none;
            background-color: rgb(247, 247, 247);
            width: 40px;
            height: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            background: none;
            color: rgb(34, 34, 34);
            border-radius: 8px;
            border: 2px solid transparent;
            cursor: pointer;

            .location-img {
              display: block;
              height: 18px;
              width: 18px;
              filter: invert(78%) sepia(0%) saturate(18%) hue-rotate(180deg) brightness(88%) contrast(102%);
            }
          }
        }
      }

      .map-button {
        display: flex;
        align-items: center;
        border-radius: 50px;
        background-color: #55A08D;
        color: #FFFFFF;
        padding: 10px 20px;
        box-sizing: border-box;
        transition: transform 0.2s cubic-bezier(0.455,0.03,0.515,0.955);
        cursor: pointer;
        pointer-events: all;

        .map-button-text {
          padding-right: 4px;
          box-sizing: border-box;
          height: 16px;
        }
      }

      .map-icon {
        height: 16px;
        width: 16px;
        filter: brightness(0%) invert(100%) sepia(0%) saturate(7500%) hue-rotate(70deg) brightness(99%) contrast(107%);
      }
    }

    .bottom-map {
      transform: translateY(100%);
      -webkit-transform: translateY(100%);
      -ms-transform: translateY(100%);
    }
  }
}

.prevent-scroll {
  overflow: hidden;
  touch-action: none;
  -ms-touch-action: none;
}

#menu {
  background: #fff;
  position: absolute;
  z-index: 1;
  top: 30px;
  right: 10px;
  border-radius: 30px;
  width: 50px;
  font-family: 'Open Sans', sans-serif;

  @media (max-width: 480px) {
    top: 130px;
  }
}

#menu div {
  font-size: 10px;
  color: #404040;
  border-radius: 30px;
  display: block;
  margin: 0;
  padding: 0;
  padding: 7px;
  text-decoration: none;
  text-align: center;
  cursor: pointer;

  .store-icon {
    width: 20px;
  }
}

#menu div:last-child {
  border: none;
}

#menu div:hover {
  background-color: #f8f8f8;
  color: #404040;
}

#menu div.active {
  background-color: #55A08D;
  color: #ffffff;

  .store-icon {
    width: 20px;
    filter: invert(97%) sepia(97%) saturate(11%) hue-rotate(218deg) brightness(102%) contrast(106%);
  }
}

#menu a.active:hover {
  background: #55A08D;
}
