.loading-page {
  height: 100vh;
  width: 100vw;
  background-color: #E3E1D6;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  svg {
    stroke-dasharray: 269.7405090332031px;
    stroke-dashoffset: 0;
    animation: heartBeat 4s linear reverse infinite;
    transform: rotate(-90deg);
    width: 80px;
    height: 80px;
    overflow: visible;
  }
  
  @keyframes heartBeat {
    50% {
      stroke-dashoffset: 269.7405090332031px;
    }
    50.01% {
      stroke-dashoffset: -269.7405090332031px;
    }
  }
}