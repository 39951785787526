.back-arrow-container:hover {
  background-color: #ddd;
}

.back-arrow-container {
  display: flex;
  align-items: center;
  background-color: #FFFFFF;
  font-size: 18px;
  font-weight: 100;
  margin-left: 20px;
  padding: 3px 20px 4px 10px;
  border-radius: 0.5rem;
  box-sizing: border-box;
  width: fit-content;

  @media (max-width: 480px) {
    font-size: 14px;
  }

  .back-text {
    font-size: 18px;
    margin-left: 4px;

    @media (max-width: 480px) {
      font-size: 14px;
    }
  }

  .back-arrow-icon {
    width: 18px;
    height: 18px;
    background-color: transparent;
    filter: invert(55%) sepia(58%) saturate(280%) hue-rotate(114deg) brightness(92%) contrast(89%);

    @media (max-width: 480px) {
      width: 16px;
      height: 16px;
    }

    &.reverse {
      -webkit-transform: scaleX(-1);
      transform: scaleX(-1);
    }
  }

  &:hover {
    cursor: pointer;
  }
}