.shifts-page {
  display: flex;
  flex-direction: column;
  width: calc(100% - 260px); 

  @media (max-width: 980px) {
    width: 100%;
  }

  .shifts-counts-container {
    display: flex;
    width: 100%;
    margin: 20px 0;
    padding: 20px;
    box-sizing: border-box;
    .shift-count {
      margin-right: 20px;
      .count {
        font-weight: 700;
        color: #55A08D;
      }
    }
  }

  .shift-viewer-container {
    margin: 32px 30px 0 0;

    .shift-list-view {
      align-items: center;
      display: flex;
      flex-direction: column;
      height: 320px;
      justify-content: center;
      width: 100%;

      .shift-list {
        display: flex;
        overflow-x: auto;
        overflow-y: hidden;
        width: 100%;

        .shift-details {
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          background: #FFFFFF;
          box-shadow: inset 0px 1px 10px rgba(0, 0, 0, 0.07);
          border-radius: 15px;

          font-size: 11px;
          height: 240px;
          justify-content: space-between;
          line-height: 18px;
          margin: 0 15px;
          max-width: 220px;
          min-width: 220px;
          padding: 30px 20px;
          text-overflow: ellipsis;
          white-space: nowrap;
          position: relative;
          cursor: pointer;

          .shift-title {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
            height: 40px;
            margin-top: 5px;

            .shift-name {
              font-size: 15px;
              white-space: normal;
              text-align: center
            }
            .shift-address {
              font-size: 12px;
              color: #55A08D;
            }
          }

          .shift-datetime {
            display: flex;
            flex-direction: column;
          }

          .shift-rate {
            display: flex;
            flex-direction: column;
          }

          .shift-status {
            border-radius: 20px;
            padding: 7px 20px;
            color: #FFFFFF;

            &.requested {
              color: #55A08D;
              background-color: #FFFFFF;
              border: 1px solid #55A08D;
            }

            &.confirmed {
              background-color: #FDCA38;
            }

            &.not-paid {
              background-color: #FDCA38;
            }

            &.paid {
              background-color: #55A08D;
            }
          }

          .edit-icon {
            top: 15px;
            right: 15px;
            height: 15px;
            width: 15px;
            padding: 5px;
            position: absolute;
            cursor: pointer;
            filter: invert(56%) sepia(23%) saturate(621%) hue-rotate(114deg) brightness(95%) contrast(96%);
          }

          .manage-shift-modal {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            position: absolute;
            top: 40px;
            right: -95px;
            z-index: 1;

            .manage-shift-container {
              display: flex;
              justify-content: space-around;
              align-items: flex-start;
              flex-direction: column;
              border-radius: 6px;
              background-color: #FFFFFF;
              padding: 10px;
              box-shadow: 0px 6.50602px 7.80723px rgba(0, 0, 0, 0.12);

              .withdraw-button {
                font-size: 12px;
                margin: 5px;
              }

              .complete-button {
                font-size: 12px;
                margin: 5px;
              }

              .enter-number-button {
                font-size: 12px;
                margin: 5px;
              }

              .report-issue-button {
                font-size: 12px;
                margin: 5px;
              }

              .download-invoice-button {
                font-size: 12px;
                margin: 5px;
              }

              .message-store {
                font-size: 12px;
                margin: 5px;
              }
            }
          }
        }
      }
    }
  }

  .view-buttons {
    display: flex;
    margin: 30px 0 0;
    padding: 20px;
    width: 100%;
    box-sizing: border-box;

    .change-view-button {
      border: 1px solid #55A08D;
      border-radius: 20px;
      padding: 7px 25px;
      background-color: #FFFFFF;
      color: #55A08D;
      cursor: pointer;
      margin-right: 20px;

      &.selected {
        background-color: #55A08D;
        color: #FFFFFF;
      }
    }
  }
}