.store-profile-modal-wrapper.share {
  .store-profile-modal {
    width: 720px;

    @media (max-width: 800px) {
      width: 660px;
    }

    @media (max-width: 720px) {
      width: 560px;
    }

    @media (max-width: 600px) {
      width: 450px;
    }

    @media (max-width: 480px) {
      width: 400px;
    }

    @media (max-width: 420px) {
      width: 300px;
    }

    @media (max-width: 360px) {
      width: 280px;
    }

    .modal-content {
      padding: 25px 35px 35px 35px;

      @media (max-width: 480px) {
        padding: 15px 20px;
      }
    }
  }
}