.store-profile-dropdown-button {
  background-color: transparent;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-height: 19px;

  .button-text {
    font: 600 16px Inter, Helvetica, Arial, sans-serif;
    text-overflow: ellipsis;
    padding-left: 5px;

    @media (max-width: 480px) {
      font-size: 13px;
    }

    @media (max-width: 420px) {
      font-size: 12px;
    }

    @media (max-width: 360px) {
      font-size: 11px;
    }
  }

  .dropdown-arrow-container {
    height: 19px;
    display: flex;

    .dropdown-arrow {
      margin-right: 5px;
      width: 35px;
      height: 30px;
      position: relative;
      top: -5px;

      @media (max-width: 480px) {
        width: 30px;
        height: 25px;
        top: -3px;
      }

      &.open {
        transform: rotate(180deg);
      }
    }
  }
}