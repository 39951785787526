.review-store-section {
  .inputs {
    height: 350px;

    .go-to-buttons {
      height: 0;
      display: flex;
      width: 100%;

      .go-to-button {
        align-self: flex-end;
        position: absolute;
        right: 25px;
        bottom: 30px;
        background: transparent;

        @media (max-width: 600px) {
          bottom: 40px;
        }

        @media (max-width: 480px) {
          bottom: 50px;
        }

      }
    }
  }
}