$cloudfront-cdn-url: 'https://dq55dzprwc7f6.cloudfront.net';

.staffing-assistant-page {
  .banner-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin: 50px auto;
    color: #313131;

    .header-title {
      font: 900 36px avenir;
      line-height: 57px;
      width: 540px;
    }

    .header-text {
      font: 100 22px avenir;
      max-width: 800px;
      margin-top: 10px;
    }
  }

  .card-content {
    padding: 50px 0;

    .card-row {
      display: flex;
      align-items: center;
      justify-content: center;

      .main-card {
        background: #FFFFFF;
        border: 0.5px solid #CBCBCB;
        box-shadow: 0px 0px 20px 10px rgba(85, 160, 141, 0.2);
        border-radius: 20px;
        width: 260px;
        padding: 25px;
        height: 450px;
        margin: 0 60px;
        display: flex;
        flex-direction: column;
        align-items: center;

        @media (max-width: 1060px) {
          margin: 0 40px;
          padding: 20px;
        }

        .header-text {
          font: 400 22px mundo;
          line-height: 26px;
          margin: 20px 20px 20px 0;
        }

        p {
          font: 100 13px mundo;
          margin-top: 10px;
        }
      }

      .small-card {
        background: #FFFFFF;
        border: 0.5px solid #CBCBCB;
        box-shadow: -4px 5px 14px rgba(0, 0, 0, 0.05);
        border-radius: 20px;
        width: 240px;
        height: 360px;
        padding: 25px;
        display: flex;
        flex-direction: column;
        align-items: center;
        min-width: 220px;

        @media (max-width: 1060px) {
          padding: 20px;
          min-width: 180px;
        }

        .header-text {
          font: 400 22px mundo;
          line-height: 26px;
          margin: 20px 20px 20px 0;
        }

        p {
          font: 100 13px mundo;
          margin-top: 10px;
        }
      }
    }
  }

  .sign-up-block {
    display: flex;
    align-items: center;
    justify-content: center;

    .free-trial {
      width: 390px;
      text-align: center;


      .header-text {
        font: 900 36px avenir;
        color: #313131;
        margin: 20px 0;
      }

      .end-content {
        font: 400 22px avenir;
        color: #313131;
        line-height: 30px;
        text-align: center;
        margin: 20px 0;
      }

      .start-trial-button {
        font: 900 15px avenir;
        text-align: center;
        color: #FFFFFF;
        background-color: #55A08D;
        border-radius: 50px;
        width: 232px;
        padding: 10px 0;
        margin-top: 10px;
      }
    }

    .tiny-card {
      display: flex;
      flex-direction: column;
      background: #FFFFFF;
      border: 0.5px solid #CBCBCB;
      box-shadow: -4px 5px 14px rgba(0, 0, 0, 0.05);
      border-radius: 20px;
      box-sizing: border-box;
      width: 280px;
      height: 310px;
      padding: 25px;

      .header-text {
        font: 800 22px avenir;
        text-decoration: line-through;
        padding: 30px 0 10px 0;
      }

      .header-subtitle {
        font: 800 16px avenir;
        color: #55A08D;
        padding: 0 0 20px 0;
      }

      .pitch-list {
        list-style-image: url($cloudfront-cdn-url + '/icons/tickmark-icon.svg');
        margin-left: 15px;

        .pitch-item {
          font: 100 13px avenir;
          margin: 8px 0;
        }
      }
    }
  }

  .want-to-know-more-block {
    background: #313131;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 280px;

    .header-text {
      color: #FFFFFF;
      font: 700 32px mundo;
      margin: 20px 0;
      text-align: center;
    }

    .subtitle {
      color: #FFFFFF;
      font: 100 18px avenir;
      line-height: 25px;
      margin: 0 10px 30px 10px;
      text-align: center;
    }

    .request-demo-button {
      width: 186px;
      background-color: #55A08D;
      color: #FFFFFF;
      border-radius: 50px;
      padding: 12px 0;
      font: 900 15px avenir;
      margin-bottom: 30px;
    }
  }

  .advertising-block {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 100px 0;

    .vertical-line {
      height: 1540px;
      border-left: solid 1.5px #55A08D;
      position: absolute;
    }

    .step-column {
      display: flex;
      flex-direction: column;
      margin: 0 60px;
    }

    .step-counters {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;

      .step-number-container {
        margin: 150px 0 130px 0;
      }
    }

    .step-number-container {
      width: 61px;
      height: 61px;
      z-index: 2;
      

      .step-number {
        font: 900 23px avenir;
        border-radius: 200px;
        background-color: #55A08D;
        color: #FFFFFF;
        width: 61px;
        height: 61px;
        display: block;
        line-height: 61px;
        text-align: center;
      }
    }

    .step-text, .step-image {
      height: 300px;
      display: flex;
      flex-direction: column;
      margin: 20px 0;
    }

    .step-image {
      align-items: center;

      img {
        width: 262px;
        height: 262px;
      }
    }

    .step-text {
      width: 400px;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .step-header {
        font: 900 22px avenir;
        color: #313131;
        margin-bottom: 30px;
      }
    }
  }

  &.web { 
    .start-content {
      display: flex;
      flex-direction: row;

      .side-text {
        align-items: flex-start;
        margin-right: 40px;
        width: 480px;
      }
    }

    .sign-up-block {
      flex-direction: row;
      margin: 60px 0 100px 0;

      .free-trial {
        margin: 0 80px 0 0;
      }
    }

    .advertising-block {
      .step-text {
        &.two, &.four {
          .step-header {
            text-align: right;
            align-self: flex-end;
            width: 280px;
          }

          .step-paragraph {
            text-align: right;
            align-self: flex-end;
          }
        }
      }
    }
  }

  &.mobile {
    .start-content {
      display: flex;
      flex-direction: column;
      padding: 50px 0 20px 0;

      .side-text {
        text-align: center;
        align-items: center;
        width: 420px; 

        img {
          width: 300px;
          height: 300px;
        }

        @media (max-width: 480px) {
          width: 360px;
          margin: 0 10px;

          img {
            width: 280px;
            height: 280px;
          }
        }

        @media (max-width: 420px) {
          width: 280px;
          margin: 0 10px;
          font: 900 32px avenir;

          img {
            width: 260px;
            height: 260px;
          }
        }

        .free-trial-button {
          align-self: center;
          margin-top: 40px;
        }
      }
    }

    .sign-up-block {
      .free-trial {
        .end-content {
          @media (max-width: 420px) {
            margin: 20px 60px;
            font-size: 18px;
          }
        }
      }
    }

    .banner-content {
      @media (max-width: 800px) {
        .header-title {
          font: 900 32px avenir;
          width: auto;
          margin: 0 10px;
        }

        .header-text {
          font: 100 20px avenir;
          max-width: 720px;
          width: auto;
          margin: 20px 10px;
        }
      }
    }

    .card-content {
      padding: 0 0 20px 0;

      .card-row {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }

      .small-card {
        margin: 10px 0 30px 0;
      }

      .main-card {
        margin: 30px 0 10px 0;
        width: 250px;
      }
    }

    .sign-up-block {
      flex-direction: column;
      margin: 0 0 60px 0;

      .tiny-card {
        margin: 30px 0;
      }

      .free-trial {
        margin: 0 0 30px 0;
      }
    }

    .advertising-block {
      padding: 0;

      .step-column {
        align-items: center;

        .step-number-container {
          margin: 30px 0;
        }

        .step-image {
          height: auto;
        }
      }

      .step-text {
        width: auto;
        height: auto;
        max-width: 320px;
        text-align: center;
        margin-bottom: 60px;
      }
    }
  }

  .start-content {
    color: #10161E;
    margin: 0 auto;
    width: auto;
    justify-content: center;
    align-items: center;
    padding: 60px 0;

    .side-text {
      display: flex;
      flex-direction: column;

      .side-title {
        font: 900 36px avenir;
      }

      .side-content {
        font: 100 18px avenir;
        margin: 20px 0;
      }

      .free-trial-button {
        font: 900 15px avenir;
        text-align: center;
        color: #FFFFFF;
        background-color: #55A08D;
        width: 232px;
        border-radius: 50px;
        padding: 12px 0;
        margin-top: 30px;
      }
    }
  }
}