$cloudfront-cdn-url: 'https://dq55dzprwc7f6.cloudfront.net';

.partners-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 100px;
  padding: 10px 0 250px 0;
  background: url($cloudfront-cdn-url + '/home/footer-map.svg') no-repeat;
  width: 100%;
  height: auto;
  text-align: center;

  .title {
    font: 900 35px avenir;
    margin: 20px 0 10px 0;

    @media (max-width: 480px) {
      font: 900 28px avenir;
      margin: 20px 15px 10px 15px;
    }
  }

  .subtitle {
    color: #55A08D;
    font: 900 22px avenir;
    margin: 10px 0;

    @media (max-width: 480px) {
      font: 900 16px avenir;
      margin: 15px 20px;
    }
  }

  .partners-list-container {
    margin-top: 60px;
  }

  .book-next-shift-section {
    margin-top: 150px;
    display: flex;
    text-align: center;
    flex-direction: column;
    margin: 150px 20px 0 20px;

    .subtitle {
      font: 400 18px avenir;
    }

    .green-button {
      width: 200px;
      align-self: center;
      font: 400 20px avenir;
      padding: 12px 15px;
      margin: 15px 0;
    }
  }
}

.partners-list-container {
  overflow-x: scroll;
  width: 100%;
  padding: 20px 0;

  .partners-list {
    display: flex;
  }
}

