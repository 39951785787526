.store-profile-section.review {
  display: flex;
  flex-flow: column;
  align-items: center;

  .store-reviews {
    display: flex;
    padding: 0 8px;
    overflow: auto;
    width: 100%;
    margin-bottom: 50px;

    .store-review-item {
      margin: 0 15px;
      padding: 10px 0;

      &:first-child {
        margin-left: auto;
      }
  
      &:last-child {
        margin-right: auto;
      }
    }
  }

  .add-review-button {
    margin: 10px 0 20px 0;
  }

  .store-review-buttons {
    display: flex;
    flex-flow: column;
    align-items: center;
    margin-top: 15px;

    .store-profile-button {
      background: #FFFFFF;
      color: #55A08D;
      border: 4px solid #55A08D;
      border-radius: 50px;
      width: 360px;
      height: 50px;
      font: 600 16px Inter, Helvetica, Arial, sans-serif;
      text-align: center;
      margin: 15px 0;

      @media (max-width: 480px) {
        font-size: 15px;
        width: 300px;
        border-width: 3px;
        height: 45px;
      }

      @media (max-width: 420px) {
        width: 280px;
        font-size: 14px;
        border-width: 2px;
        height: 40px;
      }

      @media (max-width: 360px) {
        width: 250px;
        font-size: 13px;
        height: 35px;
      }
    }
  }
}