.locum-sidebar {
  min-width: 270px;
  display: flex;
  flex-direction: column;

  @media (max-width: 980px) {
    display: none;
  }

  .locum-image-and-name {
    display: flex;
    flex-direction: column;
    margin: 5px 20px 0 20px;

    .locum-image {
      width: 100px;
      height: 100px;
      border-radius: 200px;
      padding: 3px;
      margin: auto;
    }

    .locum-sidebar-name {
      font-weight: 900;
      font-size: 24px;
      margin: 15px 0 0;
      color: #55a08d;
    }

    .locum-sidebar-address {
      font-size: 12px;
      color: #55a08d;
    }

    .loading {
      pointer-events: none;
    }

    .edit-abn-container {
      display: flex;
      .locum-sidebar-abn-ahpra {
        display: flex;
        flex-direction: column;
        font-size: 14px;
        margin: 10px 0;
        color: #55a08d;

        .abn-container {
          display: flex;

          .abn-text {
            width: 55px;
          }
        }
  
        .ahpra-container {
          display: flex;

          .abn-text {
            width: 55px;
          }
        }

        .edit-text {
          padding: 0px 4px 5px 4px;
          font-weight: 600;
          border: none;
          border-bottom: 0.2px solid #CECECE;
          color: #000000;
      
          @media (max-width: 480px) {
            // width: 170px;
          }
      
          &.disabled {
            border-bottom: solid 0.2px transparent;
            background-color: transparent;
            color: #55a08d;
          }
        }
      }
  
      .edit-abn {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
  
        .edit-button {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          cursor: pointer;
  
          .edit-icon {
            filter: invert(60%) sepia(16%) saturate(982%) hue-rotate(114deg) brightness(91%) contrast(84%);
          }
        }
  
        .cancel-button {
          cursor: pointer;
          font-style: italic;
        }
  
        .save-button {
          cursor: pointer;
          font-style: italic;
          color: #55a08d;
        }
      }
    }
  }

  .locum-navigation-list {
    margin: 5px 0 0 0;
  }
}

.divider {
  border-bottom: 1px solid #ECECEC;
  width: 224px;
  margin-bottom: 60px;
}

.locum-navigation-item {
  width: 224px;

  .locum-navigation {
    width: 100%;
    text-align: left;
    padding: 15px 20px;
    background-color: #FFFFFF;
    font-size: 18px;
    border-radius: 10px;

    &.active {
      background-color: #CAE3DD;
      color: #000000;

      &:hover {
        cursor: auto;
        filter: brightness(100%);
      }
    }
  }
}